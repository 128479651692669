import React from "react";

const Step4 = ({
  handleChange,
  values,
  handleBlur,
  nextStep,
  prevStep,
  touched,
  errors,
  hidePassword,
  setHidePassword,
}) => {
  const handleNext = () => {
    const fieldsToCheck = ["password", "confirmPassword"];

    // Check if all of the specified fields don't exist in the errors object
    if (fieldsToCheck.every((field) => !errors[field])) {
      nextStep(5);
    } else {
      return false;
    }
  };
  return (
    <div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Password*
        </label>
        <div className="input-group " id="show_hide_password">
          <input
            required
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            name="password"
            type={hidePassword.pass}
            class="form-control"
            placeholder="Password"
          />
          <div
            className="input-group-addon mt-2 "
            style={{
              position: "absolute",
              zIndex: "100",
              right: "0",
              marginRight: "5px",
            }}
          >
            <span></span>
            <span
              style={{
                cursor: "pointer",
              }}
              className="text-center"
              onClick={(e) => {
                e.preventDefault();
                hidePassword.pass == "password"
                  ? setHidePassword({
                      ...hidePassword,
                      pass: "text",
                    })
                  : setHidePassword({
                      ...hidePassword,
                      pass: "password",
                    });
              }}
            >
              <i
                className={
                  hidePassword.pass == "text" ? "fa fa-eye" : "fa fa-eye-slash"
                }
                aria-hidden="true"
              />
            </span>
          </div>
        </div>

        {errors.password && touched.password ? (
          <p className="text-danger">{errors.password}</p>
        ) : null}
      </div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Confirm Password*
        </label>
        <div className="input-group " id="show_hide_password">
          <input
            required
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            name="confirmPassword"
            type={hidePassword.Cpass}
            class="form-control"
            placeholder="Confirm Password"
          />
          <div
            className="input-group-addon mt-2 "
            style={{
              position: "absolute",
              zIndex: "100",
              right: "0",
              marginRight: "5px",
            }}
          >
            <span></span>
            <span
              className="text-center "
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                hidePassword.Cpass == "password"
                  ? setHidePassword({
                      ...hidePassword,
                      Cpass: "text",
                    })
                  : setHidePassword({
                      ...hidePassword,
                      Cpass: "password",
                    });
              }}
            >
              <i
                // className="fa fa-eye-slash"
                className={
                  hidePassword.Cpass == "text" ? "fa fa-eye" : "fa fa-eye-slash"
                }
                aria-hidden="true"
              />
            </span>
          </div>
        </div>

        {errors.confirmPassword && touched.confirmPassword ? (
          <p className="text-danger">{errors.confirmPassword}</p>
        ) : null}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        {/* <button
          onClick={() => prevStep(3)}
          className="btn btn-light w-50 waves-effect waves-light"
        >
          Prev
        </button> */}
        <button
          type="button"
          onClick={handleNext}
          className="btn btn-primary w-100 waves-effect waves-light"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step4;
