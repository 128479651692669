import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import AdSidebar from "./Sidebar";
import AdNavBar from "./NavBar";
import $ from "jquery";
import reload from "../../assets/images/icons/refresh.png"
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import * as moment from "moment";
// import viewIcon from "../../assets/images/icons/view-icon.png";
import axios from "../../API/axios";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import Quoation from "../Agent/Quotation";
import Pagination from "./pagination"
window.jQuery = window.$ = $;
require("jquery-nice-select");
function ListPo() {
  const limit = 10;
  const navigate = useNavigate();
  const [TotalCount, setTotalCount] = useState(10); 
  const [currentPage, setCurrentPage] = useState(1);
  const [pageShow , setPageShow] = useState(true)
  const [search, setSearch] = useState("")
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  const [allQuotations, setAllQuotations] = useState();
  // http://143.244.129.163:9000/purchaseOrder?page=1&limit=3
  const getAllQuotations = async () => {
    const { data } = await axios.get(`/purchaseOrder?page=${currentPage}&limit=10`);
    console.log(data.data, "pp");
    setAllQuotations(data.data);
  };
  useEffect(() => {
    getAllQuotations();
  }, []);
  console.log(allQuotations, "qq");

  const navigateEdit = (purchaseOrder) => {
    navigate("/admin/poDetail", { state: { purchaseOrder } });
  };

  const [allAgents, setAllAgents] = useState();
  const getAllAproved = async () => {
    const { data } = await axios.get("/agent/All");
    console.log(data.data, "qooo");
    setAllAgents(data.data);
  };
  useEffect(() => {
    getAllAproved();
  }, []);
  console.log(allAgents, "all");

  const searchQuotation = async (e) => {
    // console.log(e, "zzzzzzzzzzz");
    // console.log(e.target.value == "", "vaaallllllll");
   



        try {
            const { data } = await axios.get(
                `/search?search=${search}&collection=Estimate&typeOfEstimate=PO&multiFieldSearch=agentName,customerName,customerPhone,PO_Id`
            );


            console.log(data.result, "DAAAAAAAAAA");
            setPageShow(false)
            setAllQuotations(data.result);
        } catch (error) {
            console.error(error);
            setAllQuotations([]);
            setPageShow(true)
        }
   

};

  const getAgentName = (agentId) => {
    console.log(agentId, "cvc");
    console.log(allAgents, "aa");
    const agent = allAgents?.find((element) => {
      return element._id === agentId;
    });
    console.log(agent, "cr");
    return agent.firstName + " " + agent.lastName;
  };
  
    return (
      <>
        <article id="root">
          <div className="wrapper">
            <div className="sidebar">
              <AdSidebar />
            </div>
            <div className="content-page">
              <div className="content">
                <div className="MainNavRow">
                  <AdNavBar />
                </div>
                <div className="container-fluid ">
                  <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                    <div className="row align-items-center justify-content-center pb-20 hdngRowBlk">
                      <div className="col">
                        <div className="MainHdng">
                          <h4>List Purchase Order</h4>
                        </div>
                      </div>

                      <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"  onChange={(e)=>setSearch(e.target.value)}
                        />
                      </div>
                      <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            searchQuotation(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                         
                         
                          onClick={(e) => {
                            getAllQuotations() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div>
                      {/* <div className="col-auto">
                        <div className="prdctsortCol">
                          <div className="form-group">
                            <select ref={selectRef3} className="wide">
                              <option value="Featured">Sort By</option>
                              <option value="10">Agent Name</option>
                              <option value="25">Customer Name</option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                      
                    </div>
                    <div className="hrStyle pb-5">
                      <hr />
                    </div>
                    <div className="prdctListTble common-table text-center table-responsive">
                      <Table classname="table">
                        <thead>
                          <tr>
                            
                            <th>Date</th>
                            <th>Purchase order No.</th>
                            <th>Agent Name</th>
                            <th>Customer Name</th>
                            <th>Customer Number</th>
                            <th>Download PO</th>
                            <th align="center">
                              View
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allQuotations?.length > 0 ? (
                            <>
                              {allQuotations.map((Quotation) => {
                                return (
                                  <tr className="whitebgRow">
                                    
                                    <td>
                                      <div className="pdrctCat">
                                        {moment(Quotation.createdAt).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                    {Quotation.PO_Id}
                                    </td>
                                    <td>
                                      <div className="prodctTitle">
                                      {Quotation.agentName}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="prodctTitle">
                                        {Quotation.customerName}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="prodctTitle">
                                      {Quotation.customerPhone}
                                      </div>
                                    </td>
                                    <td className="status">
                                    <a
                              className="btnCommon"
                                        href={Quotation.PurchaseInvoice}
                                        target="_blank"
                                      >
                               
                                  Download PO
                               
                                </a>
                                    </td>
                                    <td className="actions">
                                      <div class="tbl-actn text-center">
                                        <ul>
                                          <li
                                            class="edit-btn"
                                            onClick={() => {
                                              navigateEdit(Quotation);
                                            }}
                                          >
                                            <div class="">
                                              <a>
                                                <img
                                                  src={viewIcon}
                                                  alt=""
                                                  height="18"
                                                />
                                                <span>
                                                  <img
                                                    src={viewWhite}
                                                    alt=""
                                                    height="18"
                                                  />
                                                </span>
                                              </a>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <>Please Create Some PO To View.</>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    {pageShow?
                    <div class="pgntnOuter text-center pt-3 ">
                    <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={TotalCount}
                                            pageSize={limit}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                    </div>:<></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </>
    );
  
}
export default ListPo;
