import React from "react";

const Step2 = ({
  handleChange,
  values,
  handleBlur,
  nextStep,
  prevStep,
  touched,
  errors,
  setPan,
  uploadFile,
  setcmpnypan,
  cmpnypan,
  psnfile,
  setAgentPan,
  setpsnfile,
}) => {
  const handleNext = () => {
    nextStep(3);
    // const fieldsToCheck = ["firstName", "lastName", "email", "phone"];

    // // Check if all of the specified fields don't exist in the errors object
    // if (fieldsToCheck.every((field) => !errors[field]) ) {
    //   nextStep(2);
    // } else {
    //   return false;
    // }
  };
  return (
    <div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Company Name
        </label>
        <input
          value={values.Company_Name}
          onChange={handleChange}
          onBlur={handleBlur}
          name="Company_Name"
          type="email"
          class="form-control"
          placeholder="Company Name"
        />
        {/* {errors.Company_Name && touched.Company_Name ? (
                            <p className="text-danger">{errors.Company_Name}</p>
                          ) : null} */}
      </div>

      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          GST Number
        </label>
        <input
          value={values.GST_Number}
          onChange={handleChange}
          onBlur={handleBlur}
          name="GST_Number"
          type="text"
          class="form-control"
          placeholder="GST Number"
        />

        {errors.GST_Number && touched.GST_Number ? (
          <p className="text-danger">{errors.GST_Number}</p>
        ) : null}
      </div>

      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          PAN of Company*
        </label>
        <input
          value={values.PAN_Company}
          onChange={(e) => {
            setPan(e.target.value);
            handleChange(e);
          }}
          onBlur={handleBlur}
          name="PAN_Company"
          type="text"
          class="form-control"
          placeholder="Company PAN Number"
          required
        />
        {errors.PAN_Company && touched.PAN_Company ? (
          <p className="text-danger">{errors.PAN_Company}</p>
        ) : null}
      </div>

      {values.PAN_Company && (
        <>
          <div className="d-flex p-4">
            <input
              type={"file"}
              required
              onChange={(e) => {
                uploadFile(e.target.files, "company");
                setcmpnypan(true);
              }}
            />
          </div>
          <div>
            {!cmpnypan && !psnfile ? (
              <p className="text-danger">{"Please upload the required file!"}</p>
            ) : null}
          </div>
        </>
      )}

      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          PAN of Agent/ Dealer*
        </label>
        <input
          value={values.PAN_Agent}
          onChange={(e) => {
            setAgentPan(e.target.value);
            handleChange(e);
          }}
          onBlur={handleBlur}
          name="PAN_Agent"
          type="text"
          class="form-control"
          placeholder="Pan Agent"
          required
        />
        {errors.PAN_Company && touched.PAN_Company ? (
          <p className="text-danger">{errors.PAN_Company}</p>
        ) : null}
      </div>

      {values.PAN_Agent && (
        <>
          <div className="d-flex p-4">
            <input
              type={"file"}
              onChange={(e) => {
                console.log(e.target.files, "SDDDDD");
                uploadFile(e.target.files, "agent");
                setpsnfile(true);
              }}
            />
          </div>
          <div>
            {!psnfile && !cmpnypan ? (
              <p className="text-danger">{"Please upload the required file!"}</p>
            ) : null}
          </div>
        </>
      )}

      <div className="d-flex justify-content-between align-items-center">
        {/* <button
          onClick={() => prevStep(1)}
          className="btn btn-light w-50 waves-effect waves-light"
        >
          Prev
        </button> */}
        <button
          type="button"
          disabled={values?.PAN_Company===""}
          onClick={handleNext}
          className="btn btn-primary w-100 waves-effect waves-light"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step2;
