import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Form} from "react-bootstrap";
import upload from "../../assets/images/uploadIcon.svg";
import { Table } from "react-bootstrap";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import axios, { Rest } from "../../API/axios";
import Swal from "sweetalert2";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function Addgallery() {


const [title, setTitle] = useState("")
const [content, setContent] = useState("")

const handleSubmit = async(e) => {
e.preventDefault();
const data = {
    Title:title,
    Content:content
}
try {
    
    const result = await axios.post("/admin/addToGallary", data)
    console.log(result)
    if (result.data.status === 201) {
        
        Swal.fire("", `Gallery Created Successfully!`, "success");
    }
} catch (error) {
    console.log(error)
    Swal.fire("", `Please Fill All Fields!`, "error");
}
}




  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow p-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Add Gallery</h3>
                    </div>
                    <div class="hrStyle pb-4">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="hrStyle pb-4">
                          <hr />
                        </div>
                        <div class="row  d-flex align-items-center ">
                          <div class="col">
                            <div className="varintRow d-none d-lg-block">
                              <div className="col-md-12 py-3">
                                <Form.Label>Add Title</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Title"
                                  name="Title"
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </div>
                              <div class="col-md-12">
                                <Form.Label>Content</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Video URL"
                                  name="content"
                                  onChange={(e) => setContent(e.target.value)}
                                />
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div class="col">
                            <button
                              class="btnCommon"
                              type="button"
                                onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Addgallery;
