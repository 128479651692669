import { Form } from "react-bootstrap";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../API/axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function AdminChangePassword() {
  const initialValues = {
    resetPassword: "",
    password: "",
    confirmPassword: "",
  };
  const resetSceme = Yup.object().shape({
    resetPassword: Yup.string().required("This field is required"),
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("password")], "Password must match"),
  });
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: resetSceme,
      onSubmit: (values) => {
        resetPassword(values);
      },
    });
  const [hidePassword, setHidePassword] = useState({
    old: "password",
    new: "password",
    Cps: "password",
  });
  const navigate = useNavigate();
  const resetPassword = async (values) => {
    const user = localStorage.getItem("BnAdJwtToken")
      ? jwtDecode(localStorage.getItem("BnAdJwtToken"))
      : "";
    console.log(user);
    if (user) {
      try {
        const body = {
          password: values.resetPassword,
          confirmPassword: values.confirmPassword,
          resetPassword: values.password,
          id: user.id,
        };
        const { data } = await axios.post(`/login/resetPasswordAdmin`, body);
        alert(data.message);
        navigate('/admin/dashboard')
        console.log(data);
      } catch (error) {
        console.log(error);
        if (error.response.data.message) {
          alert(error.response.data.message);
        }
      }
    } else {
    //   alert("please Login First");
      navigate("/");
    }
  };
  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Reset Password</h3>
                    </div>
                    <div class="hrStyle pb-4">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row  d-flex align-items-center mb-3 ">
                          <div className="col-md-12">
                            <Form.Label>Old Password</Form.Label>
                            <div
                              className="input-group "
                              id="show_hide_password"
                            >
                              <Form.Control
                                value={values.resetPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="resetPassword"
                                type={hidePassword.old}
                                placeholder="Enter old Password"
                              />
                              <div
                                className="input-group-addon mt-2 "
                                style={{
                                  position: "absolute",
                                  zIndex: "100",
                                  right: "0",
                                  marginRight: "5px",
                                }}
                              >
                                <span></span>
                                <button
                                  className="text-center"
                                  // href="#"
                                  style={{border:"none",background:"none"}}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    hidePassword.old == "password"
                                      ? setHidePassword({
                                          ...hidePassword,
                                          old: "text",
                                        })
                                      : setHidePassword({
                                          ...hidePassword,
                                          old: "password",
                                        });
                                  }}
                                >
                                  <i
                                    // className="fa fa-eye-slash"
                                    className={
                                      hidePassword.old == "text"
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>

                            {errors.resetPassword && touched.resetPassword ? (
                              <p style={{ color: "red" }}>
                                {errors.resetPassword}
                              </p>
                            ) : null}
                          </div>
                          <div class="col-md-12">
                            <Form.Label>New Password</Form.Label>
                            <div
                              className="input-group "
                              id="show_hide_password"
                            >
                              <Form.Control
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="password"
                                type={hidePassword.new}
                                placeholder="Enter New password"
                              />
                              <div
                                className="input-group-addon mt-2 "
                                style={{
                                  position: "absolute",
                                  zIndex: "100",
                                  right: "0",
                                  marginRight: "5px",
                                }}
                              >
                                <span></span>
                                <button
                                  className="text-center"
                                  style={{border:"none",background:"none"}}
                                  // href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    hidePassword.new == "password"
                                      ? setHidePassword({
                                          ...hidePassword,
                                          new: "text",
                                        })
                                      : setHidePassword({
                                          ...hidePassword,
                                          new: "password",
                                        });
                                  }}
                                >
                                  <i
                                    // className="fa fa-eye-slash"
                                    className={
                                      hidePassword.new == "text"
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>

                            {errors.password && touched.password ? (
                              <p style={{ color: "red" }}>{errors.password}</p>
                            ) : null}
                          </div>
                          <div class="col-md-12">
                            <Form.Label>Confirm New Password</Form.Label>
                            <div
                              className="input-group "
                              id="show_hide_password"
                            >
                              <Form.Control
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="confirmPassword"
                                type={hidePassword.Cps}
                                placeholder="confirm Your Password"
                              />
                              <div
                                className="input-group-addon mt-2 "
                                style={{
                                  position: "absolute",
                                  zIndex: "100",
                                  right: "0",
                                  marginRight: "5px",
                                }}
                              >
                                <span></span>
                                <button
                                  className="text-center"
                                  // href="#"
                                  style={{border:"none",background:"none"}}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    hidePassword.Cps == "password"
                                      ? setHidePassword({
                                          ...hidePassword,
                                          Cps: "text",
                                        })
                                      : setHidePassword({
                                          ...hidePassword,
                                          Cps: "password",
                                        });
                                  }}
                                >
                                  <i
                                    // className="fa fa-eye-slash"
                                    className={
                                      hidePassword.Cps == "text"
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>

                            {errors.confirmPassword &&
                            touched.confirmPassword ? (
                              <p style={{ color: "red" }}>
                                {errors.confirmPassword}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="MainHdng mt-5">
                                                <h3>Add Attributes</h3>
                                            </div> */}
                        <div class="hrStyle pb-4">
                          <hr />
                        </div>

                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div class="col">
                            <button
                              class="btnCommon"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AdminChangePassword;
