import { Form } from "react-bootstrap";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../API/axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function ChangePassword() {
  const initialValues = {
    resetPassword: "",
    password: "",
    confirmPassword: "",
  };
  const resetSceme = Yup.object().shape({
    resetPassword: Yup.string().required("This field is required"),
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("password")], "Password must match"),
  });
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: resetSceme,
      onSubmit: (values) => {
        resetPassword(values);
      },
    });
  const navigate = useNavigate();
  const resetPassword = async (values) => {
    console.log(values);
    const user = jwtDecode(localStorage.getItem("BnJwtToken"));
    console.log(user);
    try {
      const body = {
        password: values.password,
        confirmPassword: values.confirmPassword,
        resetPassword: values.resetPassword,
        id: user.id,
      };
      const { data } = await axios.post(`/login/resetPassword`, body);
      // alert(data.message);
      Swal.fire("", `${data.message}`, "success")
      navigate("/admin/dashboard");
      console.log(data);
    } catch (error) {
      console.log(error);
      if(error.response.data.message){
        // alert(error.response.data.message)
        Swal.fire("", `${error.response.data.message}`, "success")
      }
     
    }
  };
  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Reset Password</h3>
                    </div>
                    <div class="hrStyle pb-4">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row  d-flex align-items-center mb-3 ">
                          <div className="col-md-12">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control
                              value={values.resetPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="resetPassword"
                              type="password"
                              placeholder="Enter old Password"
                            />
                            {errors.resetPassword && touched.resetPassword ? (
                              <p style={{ color: "red" }}>
                                {errors.resetPassword}
                              </p>
                            ) : null}
                          </div>
                          <div class="col-md-12">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="password"
                              type="password"
                              placeholder="Enter New password"
                            />
                            {errors.password && touched.password ? (
                              <p style={{ color: "red" }}>{errors.password}</p>
                            ) : null}
                          </div>
                          <div class="col-md-12">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="confirmPassword"
                              type="password"
                              placeholder="confirm Your Password"
                            />
                            {errors.confirmPassword &&
                            touched.confirmPassword ? (
                              <p style={{ color: "red" }}>
                                {errors.confirmPassword}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="MainHdng mt-5">
                                                <h3>Add Attributes</h3>
                                            </div> */}
                        <div class="hrStyle pb-4">
                          <hr />
                        </div>

                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div class="col">
                            <button
                              class="btnCommon"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default ChangePassword;
