import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import plus from "../../assets/images/plus.svg";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
function ListCrop() {
  const [data, setData] = useState([]);
  const [crop, setCrop] = useState("");
  const getAllCrops = () => {
    axios.get(`/product/getAllCrops`).then((res) => {
      console.log(res.data.data);
      setData(res.data.data);
    });
  };
  useEffect(() => {
    getAllCrops();
    setData([
      {
        id: "1",
        name: "Onion",
      },
      {
        id: "2",
        name: "Orange Family",
      },
      {
        id: "3",
        name: "Onion",
      },
    ]);
  }, []);
  const editCrop=(val,id)=>{
    const body={
        id:id,
        cropName:val
    }
    axios.patch(`/product/findCropByIdAndUpdate`,body).then((res)=>{
        console.log(res.data);
    })
  }
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>

              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow p-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Manage Crops</h3>
                    </div>
                    <div className="hrStyle pb-5">
                      <hr />
                    </div>

                    <div className="prdctListTble common-table table-responsive">
                      <Table classname="table ">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th className="center" style={{ width: "80%" }}>
                              Crop Name
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((a, i) => {
                            return (
                              <tr>
                                <td className="whitebgRow">{i + 1}</td>
                                <td className="center" style={{ width: "80%" }}>
                                  <input
                                    onChange={(e) => {
                                        editCrop(e.target.value,a._id)
                                      setData(
                                        data.map((d) => {
                                          if (d._id == a._id) {
                                            d.crop = e.target.value;
                                          }
                                          return d;
                                        })
                                      );
                                    }}
                                    type={"text"}
                                    style={{
                                      border: "none",
                                    }}
                                    value={a.crop}
                                  />
                                </td>
                                <td>
                                  {/* {i + 1 == data.length && (
                                    <div className="addPrdctBtn">
                                      <Link
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault();
                                          let lastValue =
                                            data[data.length - 1];
                                          if (lastValue.crop) {
                                            setData([
                                                ...data,
                                                { crop: "", _id:""},
                                              ]);
                                              if(!lastValue._id){
                                                axios.post(`/product/createCrop`,{crop:data[data.length-1].crop}).then((res)=>{
                                                    console.log(res.data.data);
                                                    setData([...data.map((d)=>{
                                                        if(d._id==""){
                                                            d=res.data.data
                                                        }
                                                        return d 
                                                    })],{ crop: "", _id:""})
                                                    
                                                })

                                              }
                                           
                                            
                                          }
                                        }}
                                        className="btnCommon"
                                      >
                                        <span>
                                          <img src={plus} alt="" height="12" />
                                        </span>
                                        Add
                                      </Link>
                                    </div>
                                  )} */}
                                  {/* <div className="addPrdctBtn">
        <Link to="" className="btnCommon">
          <span>
            <img src={plus} alt="" height="12" />
          </span>
          Add
        </Link>
      </div> */}
                                </td>
                              </tr>
                            );
                          })}

                          {/* <tr>
                            <td className="whitebgRow">1</td>
                            <td className="center" style={{ width: "80%" }}>
                              Onion
                            </td>
                            <td>
                             
                            </td>
                          </tr>
                          <tr>
                            <td className="whitebgRow">1</td>
                            <td className="center" style={{ width: "80%" }}>
                              Onion
                            </td>
                            <td>
                              <div className="addPrdctBtn">
                                <Link to="" className="btnCommon">
                                  <span>
                                    <img src={plus} alt="" height="12" />
                                  </span>
                                  Add
                                </Link>
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </Table>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group d-flex">
                                <input
                                  className="form-control"
                                  placeholder="Enter Crop Name"
                                  value={crop}
                                  onChange={(e) => {
                                    setCrop(e.target.value);
                                  }}
                                  type={"text"}
                                />
                                <Link
                                  to=""
                                  className="ms-2 btnCommon"
                                  onClick={(e) => {
                                    if (!crop) {
                                      return;
                                    }
                                    axios
                                      .post(`/product/createCrop`, {
                                        crop: crop,
                                      })
                                      .then((res) => {
                                        setData([...data, res.data.data]);
                                        setCrop("");
                                      });
                                  }}
                                >
                                  <span>
                                    <img src={plus} alt="" height="12" />
                                  </span>
                                  Add
                                </Link>
                              </div>
                            </div>
                            <div className="">
                              <button className="btnCommon mt-3">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div class="row  d-flex align-items-center ">
                      <div class="col">
                        <div className="varintRow d-none d-lg-block">
                          <div class=" mb-3">
                            <div className="row">
                              <div className="col-sm-2">
                                <Form.Label>Sr.no</Form.Label>
                              </div>
                              <div className="col-sm-8">
                                <Form.Label>Model</Form.Label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="varintRow">
                      {" "}
                      <div class=" mb-3">
                        <div className="row">
                          <div className="col-lg-2">
                            <Form.Control placeholder="1" />
                          </div>
                          <div className="col-lg-8">
                            <Form.Control placeholder="Crop name" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default ListCrop;
