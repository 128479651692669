import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Row, Col, Form, label, Table } from "react-bootstrap";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import $, { isEmptyObject } from "jquery";
import plus from "../../assets/images/plus.svg";
import axios from "../../API/axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Loader from "../../assets/images/icons/load.gif"
window.jQuery = window.$ = $;
require("jquery-nice-select");
function Createestimate() {
  const selectRef1 = useRef();

  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect()
    // $(selectRef2.current).addClass(" text-truncate ")
    // $(selectRef2.current).css("max-width","50px")
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  window.onload=function(){//from ww  w . j  a  va2s. c  o  m
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByName("setTodaysDate")[0].setAttribute('min', today);
        }
  $(selectRef2.current).css("overflow","auto")
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;
  const [noOfRows, setNoOfRows] = useState(1);
  const [allCrops, setAllCrops] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [estimateDate, setEstimateDate] = useState();
  const [allMachines, setAllMachines] = useState([]);
  const [selectedMid, setSelectedMid] = useState("");
  const [loader, setloader] = useState(false)
  const [errors, setErrors] = useState({
    mId: "",
    crop: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const agentId = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const getAllCrops = async () => {
    const { data } = await axios.get("/product/getAllCrops");
    console.log(data.data, "dd");
    setAllCrops(data.data);
  };
  // const customerId = "63ce1f48e4bbfdadf0ff4c67";
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };
  const getAllProducts = () => {
    if (selectedCrop) {
      axios
        .get(`/product/getAllProductsForSelectCropName?name=${selectedCrop}`)
        .then((res) => {
          console.log(res.data.data);
          const pro = res?.data?.data.map((prod) => {
            prod.selected = false;
            prod.qty = 1;
            return prod;
          });
          setProducts(pro);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(`/product/getAllProducts`)
        .then((res) => {
          console.log(res?.data?.data);
          const pro = res?.data?.data.map((prod) => {
            prod.selected = false;
            prod.qty = 1;
            return prod;
          });
          setProducts(pro);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  $(selectRef1.current).on("change", (e) => {
    // setSelectedMid("")
    setSelectedCrop(`${e?.target?.value}`);
    console.log(e.target.value);
    // $(selectRef2.current).val(selectedMid)
    // setSelectedCrop(`${e?.target?.value}`)
  });
  $(selectRef2.current).on("change", (e) => {
    console.log(e.target.value);

    setSelectedMid(`${e?.target?.value}`);

    // getProducts(e.target.value);
    // setSelectedCrop(`${e?.target?.value}`)
  });
  const getProducts = (p) => {
    setErrors({
      crop: "",
      mId: "",
    });
    // if (!selectedCrop) {
    //   return setErrors({
    //     ...errors,
    //     crop: "Please select crop",
    //   });
    // }
    // if (!p) {
    //   return setErrors({
    //     ...errors,
    //     mId: "Please select Product",
    //   });
    // }

    axios
      .get(`/product/productDetailForASelectMachine?machineId=${p}&status=ACTIVE`)
      .then((res) => {
        console.log(res.data.data);
        const pro = res?.data?.data.map((prod) => {
          prod.selected = false;
          prod.qty = 1;
          return prod;
        });
        setProducts(pro);
      });
  };
  const checkOutHandler = () => {
    console.log("dd");

    let prevProducts = localStorage.getItem("estProducts");
    console.log(prevProducts);
    if (!prevProducts) {
      localStorage.setItem("estProducts", JSON.stringify({ products: [] }));
    }
    prevProducts = localStorage.getItem("estProducts");

    prevProducts = JSON.parse(prevProducts).products;
    console.log(prevProducts);
    const selectedProductds = products.filter((pro) => pro.selected == true);
    setSelectedCrop("");
    setSelectedMid("");
    $(selectRef1.current).val("");
    $(selectRef2.current).val("");
    // console.log(selectedProductds);
    const estimateItem = selectedProductds.map((prod) => {
      const obj = {
        ProductId: prod?._id,
        quantity: prod?.qty,
        ProductEstimatedPrice: prod?.Price,
        ProductName: prod?.Model,
        // ProductIDToShow:prod.ProductID
      };
      return obj;
    });
    let newData;
    if (prevProducts[0]) {
      newData = estimateItem.concat(prevProducts);
    } else {
      newData = estimateItem;
    }
    localStorage.setItem("estProducts", JSON.stringify({ products: newData }));
    // console.log(estimateItem);
    // if (!selectedProductds[0]) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: `Please Select Product`,
    //   });
    //   return;
    // }
    console.log(newData);
  };
  const addToestimate = () => {
    setloader(true)
    try {
      
    
    
    if (!agentId) {
    
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please Login`,
      });
     
      navigate("/agent/login");
      return;
    }
    if (!state) {
      
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please create Customer`,
      });
      
      navigate("/agent/customerdetail");
      return;
    }
  
    const selectedProductds = products.filter((pro) => pro.selected == true);
    // if (!selectedProductds[0]) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: `Please Select Product`,
    //   });
    //   return;
    // }
    if (!estimateDate) {
      Swal.fire({
        icon: "error",
        title: "",
        text: `Please select the estimated date of purchase`,
      });
      
      return;
    }

    // navigate('/agent/estimate-page')
    let estimateItem;
    let newData;
    estimateItem = selectedProductds.map((prod) => {
      const obj = {
        ProductId: prod._id,
        quantity: prod.qty,
        ProductEstimatedPrice: prod.Price,
        ProductName: prod?.Model,
        // ProductIDToShow:prod.ProductID
      };
      
      return obj;
    });
    let prevProducts = localStorage.getItem("estProducts");
    console.log(isEmptyObject(prevProducts));
    if (!isEmptyObject(prevProducts)) {
      prevProducts = JSON?.parse(prevProducts)?.products;
      if (prevProducts[0]) {
        newData = prevProducts.concat(estimateItem);
        
      } else {
        // if (!selectedProductds[0]) {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: `Please Select Product`,
        //   });
        //   return;
        // }
        newData = estimateItem;
       
      }
     
    } else {
      // if (!selectedProductds[0]) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: `Please Select Product`,
      //   });
      //   return;
      // }
      
      newData = estimateItem;
    }
    console.log(newData);

    let newArray = [];
    let uniqueObject = {};
    let objTitle;

    for (let i in newData) {
      objTitle = newData[i]["ProductId"];

      uniqueObject[objTitle] = newData[i];
    }
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    
if(!newArray){
   if (!selectedProductds[0]) {
        Swal.fire({
          icon: "error",
          title: "",
          text: `Please Select Product`,
        });
        
        return ;
      }
  return 
}
    console.log(selectedProductds);
    console.log(estimateItem);

    const body = {
      agentId: agentId.id,
      customerId: state,
      Products: newArray,
      EstimateDateOfPurchase: estimateDate,
    };
    console.log(body);
    axios
      .post(`/estimate/create`, body)
      .then((res) => {
        console.log(res.data.data._id);
        // Swal.fire("Good job!", `${res.data.Message}!`, "success");
        console.log(res);
        if(res.data.status === 201){
        setloader(false)
        localStorage.removeItem("estProducts");
        navigate("/agent/estimate-page", { state: res?.data?.data?._id });}else{
          setloader(false)
          Swal.fire("","This Number is Already Registered as Agent","error")
        }
      })
      .catch((err) => {
        setloader(false)
        // Swal.fire("The Internet?", "That thing is still around?", "question");
        console.log(err);
      });
    } catch (error) {
      setloader(false)
    }
  };
  const getAllMachines = () => {
    if (selectedCrop) {
      const cropId = allCrops.find((c) => c.crop == selectedCrop);

      axios
        .get(`/product/machinesForASelectCrop?cropId=${cropId._id}`)
        .then((res) => {
          setAllMachines(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const searchProducts = () => {
    setErrors({
      crop: "",
      mId: "",
    });

    if (!selectedCrop) {
      return;
      // setErrors({
      //   ...errors,
      //   crop: "Please select crop",
      // });
    }
    if (selectedCrop) {
      setErrors({
        ...errors,
        crop: "",
      });
    }
    if (!selectedMid) {
      return;
      // setErrors({
      //   ...errors,
      //   mId: "Please select Product",
      // });
    }
    axios
      .get(`/product/productDetailForASelectMachine?machineId=${selectedMid}&status=ACTIVE`)
      .then((res) => {
        console.log(res.data.data);
        const pro = res?.data?.data.map((prod) => {
          prod.selected = false;
          prod.qty = 1;
          return prod;
        });
        setProducts(pro);
      });
  };

  useEffect(() => {
    if (!state) {
      navigate("/agent/customerdetail");
    }
    getAllCrops();
  }, []);
  useEffect(() => {
    setProducts([]);
    getAllMachines();
  }, [selectedCrop]);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(products.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(products.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, products]);
  useEffect(() => {
    searchProducts();
  }, [selectedMid]);

  console.log(allMachines);
  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-3">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng mb-4">
                      <h3>Create Estimate</h3>
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div className="row mt-3">
                          <div class="col-md-4 col-sm-12">
                            <Form.Label>Select Crop</Form.Label>

                            <select
                              ref={selectRef1}
                              className="wide"
                              disabled={!allCrops}
                              name="crop"
                            >
                              <option value="">Select Crop </option>
                              {allCrops &&
                                (() => {
                                  window.setTimeout(() => {
                                    $(selectRef1.current).niceSelect("update");
                                  }, 0);
                                  return allCrops.map((crop) => (
                                    <option value={crop.crop}>
                                      {crop.crop}
                                    </option>
                                  ));
                                })()}
                            </select>
                            {errors.crop && (
                              <p className="text-danger">{errors.crop}</p>
                            )}
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <Form.Label>Select Product</Form.Label>
                            <select ref={selectRef2}  className="wide" name="m">
                              <option value={""}>Select Product</option>
                              {allMachines &&
                                (() => {
                                  window.setTimeout(() => {
                                    $(selectRef2.current).niceSelect("update");
                                  }, 0);
                                  return allMachines?.map((m) => (
                                    <option className="" value={m?._id}>
                                      {`${m.Machine_name}/${m.Product_name}`}
                                    </option>
                                  ));
                                })()}
                            </select>
                            {/* <select  className="wide form-control" name="m">
                              <option value={""}>Select Product</option>
                              {allMachines &&
                                (() => {
                                  // window.setTimeout(() => {
                                  //   $(selectRef2.current).niceSelect("update");
                                  // }, 0);
                                  return allMachines?.map((m) => (
                                    <option value={m?._id}>
                                      {`${m.Machine_name}/${m.Product_name}`}
                                    </option>
                                  ));
                                })()}
                            </select> */}
                            {errors.mId && (
                              <p className="text-danger">{errors.mId}</p>
                            )}
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <Form.Label>Estimate Date of Purchase</Form.Label>

                            <Form.Control
                              type="date"
                              name="setTodaysDate"
                              onChange={(e) => {
                                setEstimateDate(e.target.value);
                              }}
                              placeholder="Search Product"
                            />
                          </div>
                          {/* <div class="col-auto">
                            <Form.Label>&nbsp;</Form.Label>
                            <div className="">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  searchProducts();
                                }}
                                class="btnCommon m-0"
                                type="submit"
                              >
                                Search
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </form>
                    </div>
                    <div className="row align-items-center justify-content-center mt-4 pb-20 hdngRowBlk">
                      <div className="col"></div>
                      {/* <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                        />
                      </div> */}
                      {/* <div className="col-auto">
                        <div className="prdctsortCol">
                          <div className="form-group">
                            <select ref={selectRef2} className="wide">
                              <option value="Featured">Sort By</option>
                              <option value="10">ASC-DSC</option>
                              <option value="25">DSC-ASC</option>
                            </select>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-auto">
                        <div className="addPrdctBtn">
                          <span
                           
                            className="btnCommon "
                            onClick={(e) => {
                              e.preventDefault();
                              const swalWithBootstrapButtons = Swal.mixin({
                                customClass: {
                                  confirmButton: "btnCommon ",
                                  cancelButton: "btnCommon ",
                                },
                                buttonsStyling: false,
                              });

                              swalWithBootstrapButtons
                                .fire({
                                  title: "Choose Your Option",
                                  // text: "You won't be able to revert this!",
                                  // icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonText: "Continue to add product ",
                                  // cancelButtonText: "Continue to add product",
                                  reverseButtons: true,
                                })
                                .then((result) => {
                                  if (result.isConfirmed) {
                                    checkOutHandler();
                                  } else if (
                                    result.dismiss === Swal.DismissReason.cancel
                                  ) {
                                    checkOutHandler();
                                  }
                                });
                            }}
                          >
                            <span>
                              <img src={plus} alt="" height="12" />
                            </span>
                            Add Product to Estimate
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="hrStyle pb-5">
                      <hr />
                    </div>
                    {currentItems[0] ? (
                      <div className="prdctListTble common-table table-responsive">
                        <Table classname="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={selectAll}
                                  onClick={(e) => {
                                    setSelectAll(!selectAll);
                                    if (!selectAll) {
                                      setProducts(
                                        products.map((p) => {
                                          p.selected = true;
                                          return p;
                                        })
                                      );
                                    } else {
                                      setProducts(
                                        products.map((p) => {
                                          p.selected = false;
                                          return p;
                                        })
                                      );
                                    }
                                  }}
                                />
                              </th>
                              {/* <th>Product Name</th> */}
                              <th>Product</th>
                              <th>Qty.</th>
                              
                              {/* <th>Rate/unit (Rs.)</th>
                              <th>Amount (RS.)</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((pro, index) => {
                              return (
                                <>
                                  <tr className="whitebgRow">
                                    <td>{index + 1 + itemOffset}</td>
                                    <td>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={pro.selected}
                                        onChange={(e) => {
                                          setProducts(
                                            products.map((p) => {
                                              if (p._id == pro._id) {
                                                p.selected = !pro.selected;
                                              }
                                              return p;
                                            })
                                          );
                                        }}
                                      />
                                    </td>
                                    {/* <td className="left strong productId">
                                      <div>{pro.Model}</div>
                                    </td> */}
                                    <td className="status">
                                      <div className="pdrctCat">
                                        {pro.crop}{" "}{pro.Machine_name}{" "}
                                        {pro.Model}{" "}{pro.Capacity}
                                      </div>
                                    </td>
                                    <td className="qnty center">
                                      {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("ff");
                    setProducts(
                      products.map((p) => {
                        if (p._id == pro._id) {
                          if (p.qty != 1) {
                            p.qty = p.qty - 1;
                          }
                        }

                        return p;
                      })
                    );
                  }}
                >
                  -
                </button> */}

                                      <div className="row">
                                        <div className="col-sm-2">
                                          <div className="">
                                            <Form.Control
                                              type="number"
                                              placeholder="Qty"
                                              value={pro.qty}
                                              onChange={(e) => {
                                                setProducts(
                                                  products.map((p) => {
                                                    if (p._id == pro._id) {
                                                      p.qty = e.target.value;
                                                    }
                                                    return p;
                                                  })
                                                );
                                              }}
                                              style={{maxWidth:'50px'}}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("ff");
                    setProducts(
                      products.map((p) => {
                        if (p._id == pro._id) {
                          p.qty = p.qty + 1;
                        }

                        return p;
                      })
                    );
                  }}
                >
                  +
                </button> */}
                                    </td>
                                    
                                    {/* <td className="status">
                                      <div className="btnCommon">
                                        {" "}
                                        {pro.Price.toFixed(2)}
                                      </div>
                                    </td> */}
                                    {/* <td className="status">
                                      <div className="btnCommon btnDark">
                                        {isNaN(pro.Price * parseInt(pro.qty))
                                          ? "0.00"
                                          : (
                                              pro.Price * parseInt(pro.qty)
                                            ).toFixed(2)}
                                      </div>
                                    </td> */}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <div> </div>
                    )}

                    <div class="pgntnOuter text-center pt-3">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={". . ."}
                        containerClassName="pagination"
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName="active"
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        renderOnZeroPageCount={null}
                      />
                      {/* <ul class="pagination">
                        <li class="page-item">
                          <a
                            class="page-link"
                            role="button"
                            tabindex="0"
                            href="#"
                          >
                            <span aria-hidden="true">‹</span>
                            <span class="visually-hidden">Previous</span>
                          </a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            role="button"
                            tabindex="0"
                            href="#"
                          >
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            role="button"
                            tabindex="0"
                            href="#"
                          >
                            <span aria-hidden="true">2</span>
                          </a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            role="button"
                            tabindex="0"
                            href="#"
                          >
                            3
                          </a>
                        </li>
                        <li class="page-item active">
                          <span class="page-link">
                            4<span class="visually-hidden">(current)</span>
                          </span>
                        </li>

                        <li class="page-item">
                          <a
                            class="page-link"
                            role="button"
                            tabindex="0"
                            href="#"
                          >
                            <span aria-hidden="true">…</span>
                            <span class="visually-hidden">More</span>
                          </a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            role="button"
                            tabindex="0"
                            href="#"
                          >
                            22
                          </a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            role="button"
                            tabindex="0"
                            href="#"
                          >
                            <span aria-hidden="true">›</span>
                            <span class="visually-hidden">Next</span>
                          </a>
                        </li>
                      </ul> */}
                    </div>
                    <div className="addFormStyle text-center mt-3">
                      <Link
                        to="/agent/productlist"
                        onClick={(e) => {
                          e.preventDefault();
                          addToestimate();
                        }}
                        class="btnCommon"
                        type="submit"
                      >
                        Check out
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Createestimate;