import React from "react";
import ReactDOM from "react-dom";
import  "./assets/css/style.css"
import { responsive } from "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Home,
  AdForgotPassword,
  AdResetPassword,
  AdDashboard,
  AdSidebar,
  AdminAddAgent,
  AdminEditAgent,
  ApprovedAgent,
  AdminAddProduct,
  ProductList,
  BulkPrice,
  ListEstimate,
  ListQuotations,  
  SignUp,
  ChangePassword,
  AdminChangePassword,
  Reports,
  AdminLogin,
  EstimateDetailPage,
  EditProduct,
  AgentPi,
  EstimatePage,
  AgentQuatation,
  QuotationPage,
  AgentReport,
  PurchaseOrderPage,
  POlist,
  QuatationPage,
  AdChangePassword,
  AddAdminUser,
  AdminUserList,
  EditAdminUser,
  POdetail,
  CropList
} from "./components";
// import ListPO from "./components/Admin/ListPO"
// Agent Dashboard pages
import {
  Login,
  Register,
  ForgotPassword,
  AgentResetPassword,
  Dashboard,
  AgentChangePassword,
  EstimateProductList,
  Createestimate,
  Customerdetail,
  Quotation,
  Report,
} from "./components";
import ListPo from "./components/Admin/ListPO";
import Addgallery from "./components/Admin/AddGallery";
import Listgallery from "./components/Admin/Listgallery";
import Gallery from "./components/Agent/Gallery";
ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />

      {/* Main Admin Screens */}
      {/* <Route path="admin/login" element={<AdminLogin />} /> */}
      <Route path="admin/dashboard" element={<AdDashboard />} />
      <Route path="admin/forgot-password" element={<AdForgotPassword />} />
      <Route path="admin/reset-password" element={<AdResetPassword />} />
      <Route path="admin/change-password" element={<AdChangePassword />} />
      <Route path="admin/sidebar" element={<AdSidebar />} />
      <Route path="admin/AddAgent" element={<AdminAddAgent />} />
      <Route path="admin/EditAgent" element={<AdminEditAgent />} />
      <Route path="admin/ApprovedAgent" element={<ApprovedAgent />} />
      <Route path="admin/AddProduct" element={<AdminAddProduct />} />
      <Route path="admin/ProductList" element={<ProductList />} />
      <Route path="admin/BulkPrice" element={<BulkPrice />} />
      <Route path="admin/Addgallery" element={<Addgallery />} />
      <Route path="admin/ListEstimate" element={<ListEstimate />} />
      <Route path="admin/Listgallery" element={<Listgallery />} />
      <Route path="admin/ListQuotations" element={<ListQuotations />} />
      <Route path="admin/ListPurchaseOrder" element={<ListPo />} />
      <Route path="admin/ChangePassword" element={<AdminChangePassword />} />
      <Route path="admin/Reports" element={<Reports />} />
      <Route path="admin/AgentReport" element={<AgentReport />} />
      <Route path="admin/editquotation" element={<QuatationPage />} />
      <Route path="admin/estimateDetail" element={<EstimateDetailPage />} />
      <Route path="admin/poDetail" element={<POdetail />} />
      <Route path="admin/editProduct" element={<EditProduct />} />
      <Route path="admin/add-admin-user" element={<AddAdminUser/>} />
      <Route path="admin/list-admin-user" element={<AdminUserList/>} />
      <Route path="admin/edit-admin-user" element={<EditAdminUser/>} />
      <Route path="admin/list-crop" element={<CropList/>} />

      {/* agent Screens Design*/}
      <Route path="agent/login" element={<Login />} />
      <Route path="agent/register" element={<Register />} />
      <Route path="agent/forgot-password" element={<ForgotPassword />} />
      <Route path="agent/reset-password" element={<AgentResetPassword />} />
      <Route path="agent/Dashboard" element={<Dashboard />} />
      <Route path="agent/Gallery" element={<Gallery />} />
      <Route path="agent/changepassword" element={<AgentChangePassword />} />
      <Route path="agent/productlist" element={<EstimateProductList />} />
      <Route path="agent/customerdetail" element={<Customerdetail />} />
      <Route path="agent/createestimate" element={<Createestimate />} />
      <Route path="agent/reset-password" element={<AgentChangePassword />} />
      <Route path="agent/Quatation" element={<AgentQuatation />} />
      <Route path="agent/purchase-invoice" element={<AgentPi />} />
      <Route path="agent/estimate-page" element={<EstimatePage />} />
      <Route path="agent/quotation" element={<Quotation />} />
      <Route path="agent/qutation-page" element={<QuotationPage />} />
      <Route path="agent/Report" element={<Report />} />
      <Route path="agent/purchase-order-page" element={<PurchaseOrderPage />} />
      <Route path="agent/product-report" element={<POlist />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);
