import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
} from "react-bootstrap";

import reload from "../../assets/images/icons/refresh.png"
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import moment from "moment";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import faker from "faker";
import axios from "../../API/axios";
import jwtDecode from "jwt-decode";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

// export const options = {
//   indexAxis: 'y',
//   elements: {
//     bar: {
//       borderWidth: 2,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'right',
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Horizontal Bar Chart',
//     },
//   },
// };
function Dashboard() {
  const agentId = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const [totels, setTotels] = useState({
    Estimates: 0,
    Quotation: 0,
    PurchaseInvoice: 0,
  });
  const [countData, setCountData] = useState([]);
  const [dateStart, setdateStart] = useState()
  const [dateEnd, setdateEnd] = useState()
  const navigate = useNavigate();
  const [quotes, setQuotes] = useState([]);

  const data = {
    // labels: ["Estimates", "Quotation", "Purchase Invoice"],
    labels: countData.map((c) => c.Name),
    datasets: [
      {
        label: "# of Votes",
        data: countData.map((c) => c.count),
        // data: [30, 60, 20],
        backgroundColor: ["#6259ca", "#6b7a8e", "#fd6074"],
        borderWidth: 0,
      },
    ],
  };
  const [estimate, setEstimate] = useState([]);
  const [recentPO, setRecentPO] = useState([]);
  const getTotalCount = (e) => {
    // &startDate=${}&endDate=${}
    let end = e?.target?.value
    console.log(end , ">>>>>>>>>>>>>>>>>>>>>>>")
     let sDate = moment(dateStart).format("YYYY-MM-DD")
      let eDate = moment(dateEnd).format("YYYY-MM-DD")
    axios.get(`/dashboard/totalCountOfAgent?id=${agentId.id}&startDate=${dateStart}&endDate=${dateEnd}`).then((res) => {
      // console.log(res.data);
      setCountData(res.data);
    });
  };
  const getreload =async()=>{
    axios.get(`/dashboard/totalCountOfAgent?id=${agentId.id}`).then((res) => {
      // console.log(res.data);
      setCountData(res.data);
    });
  }
  const getRecentEstimates = () => {
    axios
      .get(`/dashboard/getRecentEstimatesOfSingleAgent?id=${agentId.id}`)
      .then((res) => {
        // console.log(res.data.data);
        setEstimate(res.data.data);
      });
  };
  const getRecentQuotes = () => {
    axios
      .get(`/dashboard/getRecentQuotesOfSingleAgent?id=${agentId.id}`)
      .then((res) => {
        // console.log(res.data.data);
        setQuotes(res.data.data);
      });
  };
  const getRecentPO = () => {
    axios
      .get(`/dashboard/getRecentPOOfSingleAgent?id=${agentId.id}`)
      .then((res) => {
        // console.log(res.data.data);
        setRecentPO(res.data.data);
      });
  };

  // const labels =countData.map((d)=>d.Name);
  // const dataBar={
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Price',
  //       data: countData.map((d)=>d.price),
  //       borderColor: 'rgb(255, 99, 132)',
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Count',
  //       data:countData.map((d)=>-d.count),
  //       borderColor: 'rgb(53, 162, 235)',
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
   
    plugins: {
      title: {
        display: false,
        // text: "Chart.js Horizontal Bar Chart",
      },
      legend: {
        display: false,
      },
    },
  };

  const labels = countData.map((d) => d.Name);

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Price",
        data: countData.map((d) => d.price),
        backgroundColor: ["#6259ca", "#6b7a8e", "#fd6074"],
      },
      // {
      //   label: "Count",
      //   data: countData.map((d)=>d.count),
      //   backgroundColor: "#fd6074",
      // },
    ],
  };

  useEffect(() => {
    axios.get(`/dashboard/totalCountOfAgent?id=${agentId.id}`).then((res) => {
      // console.log(res.data);
      setCountData(res.data);
    });
  }, [])
  
  
  useEffect(() => {
    getTotalCount();
    getRecentEstimates();
    getRecentQuotes();
    getRecentPO();
  }, []);

  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-3">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="dashBrCardRow">
                    <Row>
                      <Col md={4}>
                        <div className="">
                          <h5 className="mb-3"></h5>
                    
                          <Pie data={data} />
                        </div>
                      </Col>
                      <Col md={8}>
                      <div className="" style={{justifyContent:"space-around"}}>
                        <h5 className="mb-3 mt-2">Select Date </h5>
                        <div className="row mb-3">
                        <div className="col-md-auto mt-1">
                        <input type="date" style={{height:"30px",width:"100%"}} onChange={(e)=>setdateStart(e.target.value)}/>
                        </div>
                        <div className="col-md-auto mt-1">
                        <input type="date" style={{height:"30px",width:"100%"}} onChange={(e)=>{setdateEnd(e.target.value)}}/>
                        </div>
                        <div class="col-md-auto" style={{display:"flex" , flexDirection:"row" ,justifyContent:"space-between"}}>
                        <div className="col-md-auto m-1 ">
                        <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            getTotalCount(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                        </div>
                        <div class="col-md-auto mt-1">
                      <div className="addPrdctBtn " style={{padding:"0px 0px"}}>
                        <button
                          class="btnCommon m-0"
                         
                         
                          onClick={(e) => {
                            getreload() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div></div>
                        </div>
                        </div>
                        <Bar options={options} data={dataBar} />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md={4}>
                        <div className="prdctListTble common-table mt-2 dashBoardTable table-responsive ">
                          <Table classname=" table-bordered " sx={{width:"100%"}}>
                            <thead>
                              <tr>
                                  <th  colspan="4" className="MainHdng mb-3">
                                    <h5>Recent Estimates</h5>
                                  </th>
                              </tr>
                              <tr>
                                <th>#</th>
                                <th style={{minWidth:'140px'}}>Cutomer Name </th>
                                <th>Amount</th>
                                <th align="center" width="70">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {estimate.length > 0 ? (
                                estimate.map((e, index) => {
                                  return (
                                    <>
                                      <tr className="whitebgRow">
                                        <td>{index + 1}.</td>
                                        <td>
                                          <div>{e.customerName} </div>
                                        </td>
                                        <td className="status">
                                          <div className="btnCommon btnDark">
                                            {e.TotalCost}
                                          </div>
                                        </td>
                                        <td className="actions">
                                          <div class="tbl-actn">
                                            <ul>
                                              <li class="delete-btn">
                                                <div class="">
                                                  <a
                                                    href="/"
                                                    onClick={(ee) => {
                                                      ee.preventDefault();
                                                      navigate(
                                                        "/agent/estimate-page",
                                                        { state: e._id }
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={viewIcon}
                                                      alt=""
                                                      height="18"
                                                    />
                                                    <span>
                                                      <img
                                                        src={viewWhite}
                                                        alt=""
                                                        height="18"
                                                      />
                                                    </span>
                                                  </a>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              ) : (
                                <div className="p-1">Data not Available</div>
                              )}

                              {/* <tr className="whitebgRow">
                                <td>2.</td>
                                <td>
                                  <div>Customer B </div>
                                </td>
                                <td className="status">
                                  <div className="btnCommon btnDark">
                                    113240.00
                                  </div>
                                </td>
                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      <li class="delete-btn">
                                        <div class="">
                                          <a href="/">
                                            <img
                                              src={viewIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={viewWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr className="whitebgRow">
                                <td>3.</td>
                                <td>
                                  <div>Customer C </div>
                                </td>
                                <td className="status">
                                  <div className="btnCommon btnDark">
                                    113240.00
                                  </div>
                                </td>
                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      <li class="delete-btn">
                                        <div class="">
                                          <a href="/">
                                            <img
                                              src={viewIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={viewWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="prdctListTble common-table mt-2 dashBoardTable table-responsive">
                          <Table classname=" table-bordered">
                            <thead>
                              <tr>
                                  <th  colspan="4" className="MainHdng mb-3">
                                    <h5>Recent Quotes</h5>
                                  </th>
                              </tr>
                              <tr>
                                <th>#</th>
                                <th style={{minWidth:'140px'}}>Customer Name </th>
                                <th>Amount</th>
                                <th align="center" width="70">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {quotes.length > 0 ? (
                                quotes.map((q, i) => {
                                  return (
                                    <>
                                      <tr className="whitebgRow">
                                        <td>{i + 1}.</td>
                                        <td>
                                          <div>{q.customerName} </div>
                                        </td>
                                        <td className="status">
                                          <div className="btnCommon btnDark">
                                            {q.TotalCost}
                                          </div>
                                        </td>
                                        <td className="actions">
                                          <div class="tbl-actn">
                                            <ul>
                                              <li class="delete-btn">
                                                <div class="">
                                                  <a
                                                    href="/"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      navigate(
                                                        "/agent/qutation-page",
                                                        { state: q._id }
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={viewIcon}
                                                      alt=""
                                                      height="18"
                                                    />
                                                    <span>
                                                      <img
                                                        src={viewWhite}
                                                        alt=""
                                                        height="18"
                                                      />
                                                    </span>
                                                  </a>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              ) : (
                                <div className="p-1">Data not Available</div>
                              )}

                              {/* <tr className="whitebgRow">
                                <td>2.</td>
                                <td>
                                  <div>Customer B </div>
                                </td>
                                <td className="status">
                                  <div className="btnCommon btnDark">
                                    113240.00
                                  </div>
                                </td>
                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      <li class="delete-btn">
                                        <div class="">
                                          <a href="/">
                                            <img
                                              src={viewIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={viewWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr className="whitebgRow">
                                <td>3.</td>
                                <td>
                                  <div>Customer C </div>
                                </td>
                                <td className="status">
                                  <div className="btnCommon btnDark">
                                    113240.00
                                  </div>
                                </td>
                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      <li class="delete-btn">
                                        <div class="">
                                          <a href="/">
                                            <img
                                              src={viewIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={viewWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="prdctListTble common-table mt-2  dashBoardTable table-responsive">
                          <Table classname=" table-bordered">
                            <thead>
                              <tr>
                                  <th  colspan="4" className="MainHdng mb-3">
                                    <h5>Recent  Proforma Invoice</h5>
                                  </th>
                                
                              </tr>
                              <tr>
                                <th>#</th>
                                <th style={{minWidth:'140px'}}>Customer Name </th>
                                <th>Amount</th>
                                <th align="center" width="70">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {recentPO.length > 0 ? (
                                recentPO.map((p, i) => {
                                  return (
                                    <>
                                      <tr className="whitebgRow">
                                        <td>{i + 1}.</td>
                                        <td>
                                          <div>{p.customerName} </div>
                                        </td>
                                        <td className="status">
                                          <div className="btnCommon btnDark">
                                            {p.TotalCost}
                                          </div>
                                        </td>
                                        <td className="actions">
                                          <div class="tbl-actn">
                                            <ul>
                                              <li class="delete-btn">
                                                <div class="">
                                                  <a
                                                    href="/"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      navigate(
                                                        "/agent/purchase-order-page",
                                                        { state: p._id }
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={viewIcon}
                                                      alt=""
                                                      height="18"
                                                    />
                                                    <span>
                                                      <img
                                                        src={viewWhite}
                                                        alt=""
                                                        height="18"
                                                      />
                                                    </span>
                                                  </a>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              ) : (
                                <tr >
                                  <td colspan="4" className="px-2 w-100">Data not Available </td>
                                </tr>
                              )}

                              {/* <tr className="whitebgRow">
                                <td>2.</td>
                                <td>
                                  <div>Customer B </div>
                                </td>
                                <td className="status">
                                  <div className="btnCommon btnDark">
                                    113240.00
                                  </div>
                                </td>
                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      <li class="delete-btn">
                                        <div class="">
                                          <a href="/">
                                            <img
                                              src={viewIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={viewWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr className="whitebgRow">
                                <td>3.</td>
                                <td>
                                  <div>Customer C </div>
                                </td>
                                <td className="status">
                                  <div className="btnCommon btnDark">
                                    113240.00
                                  </div>
                                </td>
                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      <li class="delete-btn">
                                        <div class="">
                                          <a href="/">
                                            <img
                                              src={viewIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={viewWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Dashboard;
