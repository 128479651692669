import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import $ from "jquery";
import reload from "../../assets/images/icons/refresh.png"
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import userImg from "../../assets/images/user1.jpg";
import Pagination from "../../container/pagination/pagination";
import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
import axios from "../../API/axios";
import jwtDecode from "jwt-decode";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function Reports() {
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  const agent = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const limit = 10;
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [date, setDate] = useState({
    from: "",
    to: "",
  });
  const [search, setSearch] = useState("")
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(0);
  const loadData = () => {
    axios
      .get(
        `/estimate/getProductReport?id=${agent.id}&limit=${limit}&page=${currentPage}`
      )
      .then((res) => {
        console.log(res.data.data);
        setTotalCount(res?.data?.totalCount);
        const newData = res.data.data.map((r) => {
          const newObj = {
            productId: r.ProductID,
            disc: r.ProductName,
            est: r.Estimate,
            name : r.MachineName,
            quot: r.Quotation,
            po: r.PurchaseOrder,
            amt: r.ProductOrderPrice ? r.ProductOrderPrice : "0",
          };
          return newObj;
        });
        setAllData(newData.filter((r) => r.productId != ""));
        setData(newData.filter((r) => r.productId != ""));
      });
  };
  const dateFilter = () => {
    console.log(date);

    axios
      .get(
        `/estimate/getProductReport?id=${agent.id}&&limit=${limit}&page=${currentPage}&startDate=${date.from}&endDate=${date.to}&search=${search}`
      )
      .then((res) => {
        console.log(res);
        setTotalCount(res?.data?.totalCount);
        const newData = res.data.data.map((r) => {
          const newObj = {
            productId: r.ProductID,
            disc: r.ProductName,
            name : r.MachineName,
            est: r.Estimate,
            quot: r.Quotation,
            po: r.PurchaseOrder,
            amt: r.ProductOrderPrice ? r.ProductOrderPrice : "0",
          };
          return newObj;
        });
        setData(newData.filter((r) => r.productId != ""));
      });
  };
  // const searchHandler = () => {
  //   if (!searchVal) {
  //     setData(allData);
  //     return;
  //   }
  //   const newData = allData.filter((r) =>
  //     r.productId.toLowerCase().includes(searchVal.toLowerCase())
  //   );
  //   setData(newData);
  // };
  // useEffect(() => {
  //   searchHandler();
  // }, [searchVal]);
  const searchQuotation = async (e) => {
    // console.log(e, "zzzzzzzzzzz");
    // console.log(e.target.value == "", "vaaallllllll");
    if (e.target.value != "") {



        try {
            const { data } = await axios.get(
                `/search?search=${e.target.value}&collection=Estimate&multiFieldSearch=customerName,customerPhone,PO_Id`
            );


            console.log(data.result, "DAAAAAAAAAA");
            // setQuotations(data.result);
        } catch (error) {
            console.error(error);
        }
    }
    else {
      // getAllEstimate()
    }

};
  useEffect(() => {
    
    if (date.from !== undefined && date.from !== ""){
      dateFilter();
    }else{
      loadData();
    }
  }, [currentPage]);
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-2 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>Products Reports</h4>
                      </div>
                    </div>
                    {/* <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => searchQuotation(e)}
                      />
                    </div> */}
                    <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group text-center">Start Date</div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                        
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <input
                            className="form-control"
                            onChange={(e) =>
                              setDate({ ...date, from: e.target.value })
                            }
                            type={"date"}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef1} className="wide">
                            <option value="Featured">Monthly</option>
                            <option value="1">Janaury</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group text-center">End Date</div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <input
                            className="form-control"
                            onChange={(e) =>
                              setDate({ ...date, to: e.target.value })
                            }
                            type={"date"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"  onChange={(e)=>setSearch(e.target.value)}
                        />
                      </div>
                      <div class="col-md-auto" style={{display:"flex" , flexDirection:"row" ,justifyContent:"space-between"}}>
                    <div class="col-md-auto  m-1">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            dateFilter();
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-auto mt-1">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                         
                         
                          onClick={(e) => {
                            loadData() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div></div>
                    {/* <div class="col-auto">
                      <div className="addPrdctBtn">
                        <button class="btnCommon m-0" type="submit">
                          PDF
                        </button>
                      </div>
                    </div> */}
                    {/* <div class="col-auto">
                      <div className="addPrdctBtn">
                        <button class="btnCommon m-0 btnDark1" type="submit">
                          <i class="fa fa-file-excel" aria-hidden="true"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive">
                    <Table classname="table">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Product ID</th>
                          <th className="text-center">Name of Machine</th>
                          <th className="text-center">No of Estimates</th>
                          <th className="text-center">No of Quotations</th>
                          <th className="text-center">No of PO</th>
                          <th className="text-center">Amount of Purchase Invoices</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((p, index) => {
                          return (
                            <tr className="whitebgRow">
                              <td  className="text-center">{((currentPage-1)*10)+(index + 1)}.</td>
                              <td  className="text-center">
                                <div className="SubCat">{p.productId}</div>
                              </td>
                              <td  className="text-center">
                                <div>{p.name}</div>
                              </td>
                              <td className="status text-center">
                                <div className="pdrctCat btnNew">{p.est}</div>
                              </td>
                              <td className="status text-center">
                                <div className="pdrctCat btnActive">
                                  {p.quot}
                                </div>
                              </td>
                              <td className="status text-center">
                                <div className="pdrctCat">{p.po}</div>
                              </td>
                              <td className="status text-center">
                                <div className="btnCommon btnDark">{p.amt}</div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div class="pgntnOuter text-center pt-3 ">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={TotalCount}
                      pageSize={limit}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                    {/* <ul class="pagination">
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">‹</span>
                          <span class="visually-hidden">Previous</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">2</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          3
                        </a>
                      </li>
                      <li class="page-item active">
                        <span class="page-link">
                          4<span class="visually-hidden">(current)</span>
                        </span>
                      </li>

                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">…</span>
                          <span class="visually-hidden">More</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          22
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">›</span>
                          <span class="visually-hidden">Next</span>
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Reports;
