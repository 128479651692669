import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import $ from "jquery";
import plus from "../../assets/images/plus.svg";
import reload from "../../assets/images/icons/refresh.png"
import Pagination from "../../container/pagination/pagination";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import axios from "../../API/axios";
import Moment from "react-moment";
import jwtDecode from "jwt-decode";
import ReactPaginate from "react-paginate";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function AgentQuatation() {
  const jwt = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  const agentId = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageShow, setPageShow] = useState(true);
  const itemsPerPage = 5;
  const [quotations, setQuotations] = useState([]);
  const navigate = useNavigate();
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % quotations.length;
    setItemOffset(newOffset);
  };
  const loadData = () => {
    axios
      .get(`/quotation/getByAgentId?id=${agentId.id}`)
      .then((res) => {
        console.log(res.data.data);

        setQuotations(res?.data?.data?.filter((d) => d.agentId == agentId.id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewHandler = (q) => {
    console.log(q);
    if (q.approvalFromAdminAsQuotes) {
      navigate("/agent/qutation-page", { state: q._id });
    } else {
      navigate("/agent/estimate-page", { state: q._id });
    }
  };
  const getQuotation = () => {
    axios
      .get(
        `/quotation?agentId=${agentId.id}&page=${currentPage}&limit=${limit}`
      )
      .then((res) => {
        console.log(res.data.data);
        setQuotations(res?.data?.data);
        setTotalCount(res?.data?.totalCount);
      });
  };
  useEffect(() => {
    getQuotation();
  }, [currentPage]);
  const getTotelPrice = (products) => {
    const prices = products.map((p) => {
      return (p.quantity * p.ProductEstimatedPrice*p.Gst)/100 + (p.quantity * p.ProductEstimatedPrice);
    });
    let totelPrice = prices.reduce((partialSum, a) => partialSum + a, 0);

    return totelPrice;
  };
  function leftFillNum(num, targetLength) {
    return num.toString().padStart(targetLength, "0");
  }
  // const searchHandler = () => {
  //   if (!searchVal) {
  //     getQuotation();
  //     return;
  //   }
  //   axios
  //     .get(
  //       `/search?search=${searchVal}&collection=Estimate&multiFieldSearch=customerName,customerPhone,QuotationId`
  //     )
  //     .then((res) => {
  //       console.log(res.data.result);
  //       setQuotations(res.data.result);
  //     });
  // };
  const searchQuotation = async (e) => {
    // console.log(e, "zzzzzzzzzzz");
    // console.log(e.target.value == "", "vaaallllllll");
   



        try {
            const { data } = await axios.get(
                `/search?search=${searchVal}&collection=Estimate&typeOfEstimate=Quotation&agentId=${jwt?.id}&multiFieldSearch=customerName,customerPhone,QuotationId`
            );


            console.log(data.result, "DAAAAAAAAAA");
            setQuotations(data.result);
            setPageShow(false)
        } catch (error) {
            console.error(error);
            setQuotations([])
            setPageShow(true)
        }
   

};
  const getProductIds = () => {};
  useEffect(() => {
    if (!agentId) {
      navigate("/agent/login");
    }
    // loadData();
    getQuotation();
  }, []);
  // useEffect(() => {
  //   searchHandler();
  // }, [searchVal, searchBy]);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(quotations.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(quotations.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, quotations]);
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-2 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>Quotations</h4>
                      </div>
                    </div>

                    <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e)=>setSearchVal(e.target.value)}
                      />
                    </div>
                    <div class="col-md-auto" style={{display:"flex" , flexDirection:"row" ,justifyContent:"space-between"}}>
                    <div class="col-md-auto m-1">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            searchQuotation(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-auto mt-1">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                         
                         
                          onClick={(e) => {
                            getQuotation() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div></div>
                    {/* <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select onChange={(e)=>setSearchBy(e.target.value)} className="wide form-control">
                            <option value="">Search By</option>
                            <option value="QuotationId">Quotation ID</option>
                            <option value="customerName">Customer Name</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef2} className="wide">
                            <option value="Featured">Type</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-auto">
                      <div className="addPrdctBtn">
                        <Link to="/admin/addagent" className="btnCommon">
                          <span>
                            <img src={plus} alt="" height="12" />
                          </span>
                          Add
                        </Link>
                      </div>
                    </div> */}
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive">
                    <Table classname="table">
                      <thead>
                        <tr>
                          {/* <th>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </th> */}
                          <th className="text-center">Estimate  Date</th>
                          <th className="text-center">Quotation ID</th>
                          <th className="text-center">Customer Name</th>
                          <th className="text-center">Customer Number</th>
                          <th className="text-center">Total Cost</th>
                          <th align="center" width="150" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!quotations[0] && (
                          <p>Please Create Some Quotation To View</p>
                        )}
                        {quotations.map((q) => {
                          // axios.get(`/getDetailedEstimateById?id=${q._id}`).then((res)=>{
                          //   console.log(res.data);
                          // })

                          return (
                            <tr className="whitebgRow">
                              {/* <td>
                                <input
                                  className="form-check-input"
                                  // onClick={() => getTotelPrice(q.Products)}
                                  type="checkbox"
                                />
                              </td> */}
                              <td className="text-center">
                                <div
                                  style={{
                                    minWidth: "100px",
                                  }}
                                  className="pdrctCat"
                                >
                                  <Moment format="DD MMM YYYY">
                                    {q.EstimateDateOfPurchase}
                                  </Moment>
                                </div>
                                {/* <div className="pdrctCat">
                                  <Moment format="DD-MMM-YYYY">
                                    {q.EstimateDateOfPurchase}
                                  </Moment>
                                </div> */}
                              </td>
                              {/* <td>Q-{leftFillNum(q.EstimateNo, 3)}</td> */}
                              <td className="text-center">{q.QuotationId}</td>
                              <td className="text-center">
                                <div className="prodctTitle">
                                  {q.customerName}
                                </div>
                              </td>
                              <td className="text-center">
                                <div
                                  style={{
                                    width: "250px",
                                  }}
                                  className="  SubCat text-wrap"
                                >
                                  {q.customerPhone}
                                </div>
                              </td>
                              {/* <td className="status">
                                <Link to="/" className="btnCommon">
                                  Approve
                                </Link>
                              </td> */}
                              <td className="status text-center">
                                <p>
                                  {isNaN(getTotelPrice(q.Products))
                                    ? 0.0
                                    : getTotelPrice(q.Products)}
                                </p>
                              </td>
                              <td className="actions">
                                <div class="tbl-actn text-center">
                                  <ul>
                                    <li class="edit-btn">
                                      <div class=""></div>
                                    </li>
                                    <li class="delete-btn">
                                      <div class="">
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            viewHandler(q);
                                          }}
                                        >
                                          <img
                                            src={viewIcon}
                                            alt=""
                                            height="18"
                                          />
                                          <span>
                                            <img
                                              src={viewWhite}
                                              alt=""
                                              height="18"
                                            />
                                          </span>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })}

                        {/* <tr className="whitebgRow">
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div className="pdrctCat">30-09-2022</div>
                          </td>
                          <td>
                            <div className="prodctTitle">
                              <Link to="/">Jasmeet Kaur</Link>
                            </div>
                          </td>
                          <td>
                            <div className="SubCat">Duple It solution</div>
                          </td>
                          <td className="status">
                            <Link to="/" className="btnCommon btnDark">
                              Suspend
                            </Link>
                          </td>
                          <td className="actions">
                            <div class="tbl-actn">
                              <ul>
                                <li class="edit-btn">
                                  <div class="">
                                    <a href="/admin/editproduct">
                                      <img src={editIcon} alt="" height="18" />
                                      <span>
                                        <img
                                          src={editWhite}
                                          alt=""
                                          height="18"
                                        />
                                      </span>
                                    </a>
                                  </div>
                                </li>
                                <li class="delete-btn">
                                  <div class="">
                                    <a href="/">
                                      <img src={viewIcon} alt="" height="18" />
                                      <span>
                                        <img
                                          src={viewWhite}
                                          alt=""
                                          height="18"
                                        />
                                      </span>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                  {pageShow?
                  <div class="pgntnOuter text-center pt-3 ">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={TotalCount}
                      pageSize={limit}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                   
                  </div>:<></>}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AgentQuatation;