import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
} from "react-bootstrap";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import faker from "faker";
import * as moment from "moment";
import jwtDecode from "jwt-decode";
import axios from "../../API/axios";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

function Dashboard() {
  const [CountData, setCountData] = useState();
  // const [estimatesCount, setEstimatesCount] = useState(0);
  const getCountDetails = async () => {
    axios.get(`/dashboard/getTotalCount`).then((res) => {
      console.log(res.data, "rr");
      res.data[0].count = res.data[0].count-(res.data[1].count+res.data[2].count)
      setCountData(res.data);
      // setEstimatesCount(res.data[0].count-res.data[1].count-res.data[1].count)
    });
  };
  useEffect(() => {
    getCountDetails();
  }, []);
  const [allReports, setAllReports] = useState();
  const getAllAgentReports = async () => {
    const { data } = await axios.get("/estimate/getAgentReport");
    console.log(data.data, "dd");
    const res = data.data;
    let sortedAgents = res.sort(
      (p1, p2) => p2.ProductOrderPrice - p1.ProductOrderPrice
    );
    console.log(sortedAgents, "000000000000000000");
    let final = sortedAgents.slice(0, 5);
    console.log(final, "finnnnnnnnnn");
    setAllReports(final);
  };
  useEffect(() => {
    getAllAgentReports();
  }, []);
  console.log(allReports, "all");
  console.log(CountData, "cccc");
  const data = {
    labels: CountData?.map((data) => data.Name),
    datasets: [
      {
        label: "# of Votes",
        data: CountData?.map((data) => 
          // if (data.Name == "Estimates") {
          //   estimatesCount;
          // } else {
            data.count
          // }
        ),
        backgroundColor: ["#6259ca", "#6b7a8e", "#fd6074"],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };
  let allEst = [];
  allReports?.forEach((element) => {
    allEst.push(element.EstimatePrice);
  });
  let allQuot = [];
  allReports?.forEach((element) => {
    allQuot.push(element.QuotationPrice);
  });
  let allPoPrice = [];
  allReports?.forEach((element) => {
    allPoPrice.push(element.ProductOrderPrice);
  });
  let labels = [];
  allReports?.forEach((element) => {
    labels.push(element.Agent_Name);
  });
  console.log(labels, "llll");

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Estimates",
        data: allEst,
        backgroundColor: "#6259ca",
      },
      {
        label: "Quotation",
        data: allQuot,
        backgroundColor: "#6b7a8e",
      },
      {
        label: "Purchase Orders",
        data: allPoPrice,
        backgroundColor: "#fd6074",
      },
    ],
  };

  const navigate = useNavigate();
  useEffect(() => {
    const jwt = localStorage.getItem("BnAdJwtToken")
      ? jwtDecode(localStorage.getItem("BnAdJwtToken"))
      : "";
    console.log(jwt);
    if (!jwt) {
      navigate("/");
    }
  }, [localStorage]);

  const [allAgents, setAllAgents] = useState();
  const getAllAproved = async () => {
    const { data } = await axios.get("/agent/All");
    console.log(data.data, "dd");
    setAllAgents(data.data);
  };
  useEffect(() => {
    getAllAproved();
  }, []);
  const [RecentEstimates, setRecentEstimates] = useState();
  const getAllRecentEstimates = async () => {
    axios.get(`/dashboard/getRecentEstimates`).then((res) => {
      setRecentEstimates(res?.data?.data);
    });
  };
  useEffect(() => {
    getAllRecentEstimates();
  }, []);

  const getAgentName = (agentId) => {
    console.log(agentId, "cvc");
    console.log(allAgents, "aa");
    const agent = allAgents?.find((element) => {
      return element._id === agentId;
    });
    console.log(agent, "cr");
    return agent?.firstName + " " + agent?.lastName;
  };

  const [RecentQuotations, setRecentQuotations] = useState();
  const getAllRecentQuotations = async () => {
    axios.get(`/dashboard/getRecentQuotes`).then((res) => {
      setRecentQuotations(res?.data?.data);
    });
  };
  useEffect(() => {
    getAllRecentQuotations();
  }, []);

  const [RecentPO, setRecentPO] = useState();
  const getAllRecentPO = async () => {
    axios.get(`/dashboard/getRecentPO`).then((res) => {
      setRecentPO(res?.data?.data);
    });
  };
  useEffect(() => {
    getAllRecentPO();
  }, []);

  const navigateEdit = (Estimate) => {
    navigate("/admin/estimateDetail", { state: { Estimate } });
  };
  const navigateToQuote = (Quotation) => {
    navigate("/admin/editquotation", { state: { Quotation } });
  };
  const navigateToPO = (purchaseOrder) => {
    console.log(purchaseOrder, "ppppp");
    navigate("/admin/poDetail", { state: { purchaseOrder } });
  };

  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="dashBrCardRow">
                    <Row>
                      <Col md={4}>
                        <div className="">
                          <h5 className="mb-3"></h5>
                          <Pie data={data} />
                        </div>
                      </Col>
                      <Col md={8}>
                        <h5 className="mb-3"></h5>
                        <Bar options={options} data={dataBar} />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md={4}>
                        <div className="prdctListTble common-table dashBoardTable table-responsive">
                          <div className="MainHdng mb-3">
                            <h5>Recent Estimates</h5>
                          </div>
                          <Table classname=" table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Agent Name </th>
                                <th>Amount</th>
                                <th align="center" width="70">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {RecentEstimates && RecentEstimates.length > 0 ? (
                                RecentEstimates?.map((estimate, index) => {
                                  return (
                                    <tr className="whitebgRow">
                                      <td>{index + 1}</td>
                                      <td>
                                        <div>
                                          {allAgents &&
                                            getAgentName(estimate?.agentId)}
                                        </div>
                                      </td>
                                      <td className="status">
                                        <div className="btnCommon btnDark">
                                          {estimate.TotalCost}
                                        </div>
                                      </td>
                                      <td className="actions">
                                        <div class="tbl-actn">
                                          <ul>
                                            <li class="delete-btn">
                                              <div class="">
                                                <a
                                                  onClick={() => {
                                                    navigateEdit(estimate);
                                                  }}
                                                >
                                                  <img
                                                    src={viewIcon}
                                                    alt=""
                                                    height="18"
                                                  />
                                                  <span>
                                                    <img
                                                      src={viewWhite}
                                                      alt=""
                                                      height="18"
                                                    />
                                                  </span>
                                                </a>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <>Data not Available</>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="prdctListTble common-table dashBoardTable table-responsive ">
                          <div className="MainHdng mb-3">
                            <h5>Recent Quotes</h5>
                          </div>
                          <Table classname=" table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Agent Name </th>
                                <th>Amount</th>
                                <th align="center" width="70">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {RecentQuotations &&
                              RecentQuotations.length > 0 ? (
                                RecentQuotations?.map((quote, index) => {
                                  return (
                                    <tr className="whitebgRow">
                                      <td>{index + 1}</td>
                                      <td>
                                        <div>
                                          {allAgents &&
                                            getAgentName(quote?.agentId)}
                                        </div>
                                      </td>
                                      <td className="status">
                                        <div className="btnCommon btnDark">
                                          {quote.TotalCost}
                                        </div>
                                      </td>
                                      <td className="actions">
                                        <div class="tbl-actn">
                                          <ul>
                                            <li class="delete-btn">
                                              <div class="">
                                                <a
                                                  onClick={() => {
                                                    navigateToQuote(quote);
                                                  }}
                                                >
                                                  <img
                                                    src={viewIcon}
                                                    alt=""
                                                    height="18"
                                                  />
                                                  <span>
                                                    <img
                                                      src={viewWhite}
                                                      alt=""
                                                      height="18"
                                                    />
                                                  </span>
                                                </a>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <>Data not Available</>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="prdctListTble common-table  dashBoardTable table-responsive">
                          <div className="MainHdng mb-3">
                            <h5>Recent  Proforma Invoice</h5>
                          </div>
                          <Table classname=" table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Agent Name </th>
                                <th>Amount</th>
                                <th align="center" width="70">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {RecentPO && RecentPO.length > 0 ? (
                                RecentPO?.map((po, index) => {
                                  return (
                                    <tr className="whitebgRow">
                                      <td>{index + 1}</td>
                                      <td>
                                        <div>
                                          {allAgents &&
                                            getAgentName(po?.agentId)}
                                        </div>
                                      </td>
                                      <td className="status">
                                        <div className="btnCommon btnDark">
                                          {po.TotalCost.toFixed(2)}
                                        </div>
                                      </td>
                                      <td className="actions">
                                        <div class="tbl-actn">
                                          <ul>
                                            <li class="delete-btn">
                                              <div class="">
                                                <a
                                                  onClick={() => {
                                                    navigateToPO(po);
                                                  }}
                                                >
                                                  <img
                                                    src={viewIcon}
                                                    alt=""
                                                    height="18"
                                                  />
                                                  <span>
                                                    <img
                                                      src={viewWhite}
                                                      alt=""
                                                      height="18"
                                                    />
                                                  </span>
                                                </a>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <>Data not Available</>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Dashboard;
