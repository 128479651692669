import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Row, Col, Container, Form, label } from "react-bootstrap";

import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
window.jQuery = window.$ = $;
require("jquery-nice-select");

const InitialFormData = {
  agentName: "",
  companyName: "",
  gstNumber: "",
  panCompany: "",
  panAgent: "",
  contactNumber: "",
  emailId: "",
  address: "",
};

function AdminAddAgent() {
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const [noOfRows, setNoOfRows] = useState(1);
  const [formData, setFormData] = useState((x) => {
    return { ...InitialFormData };
  });

  const handleChange = ({ nativeEvent }) => {
    let _formData = formData;
    _formData[nativeEvent.target.name] = nativeEvent.target.value;
    setFormData({ ..._formData });
  };

  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng mb-4">
                      <h3> Add Agent</h3>
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row">
                          <div className="col">
                            <div class=" mb-3">
                              <div className="row">
                                <div className="col">
                                  <Form.Label>Agent/Dealer Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Agent/Dealer Name"
                                  />
                                </div>
                                <div className="col">
                                  <Form.Label>Company Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Company Name"
                                  />
                                </div>
                                <div className="col">
                                  <Form.Label>GST No.</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="GST No."
                                  />
                                </div>
                                {/* <div className="col">
                                                                        <Form.Label>Enter Brand Name</Form.Label>
                                                                        <select ref={selectRef3} className="wide">
                                                                                <option value="Featured">Enter Brand Name </option>
                                                                                <option value="10">Brand Name Two</option>
                                                                                <option value="25">Brand Name Three</option>
                                                                            </select>
                                                                    </div>                                                                    */}
                              </div>
                              <div className="row my-3">
                                <div className="col">
                                  <Form.Label>PAN of Company</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="PAN of Company"
                                  />
                                </div>
                                <div className="col">
                                  <Form.Label>PAN of Agent/Dealer</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="PAN of Agent/Dealer"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <Form.Label>Contact No.</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Contact No."
                                  />
                                </div>
                                <div className="col">
                                  <Form.Label>Email ID</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Email ID"
                                  />
                                </div>
                              </div>
                              <Row className="mt-3">
                                <Col>
                                  <Form.Label>Address</Form.Label>
                                  <textarea className="form-control"></textarea>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <button class="btnCommon  mt-3 " type="submit">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AdminAddAgent;
