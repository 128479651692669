import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { Table } from "react-bootstrap";
import AdSidebar from "./Sidebar";
import AdNavBar from "./NavBar";
import $ from "jquery";
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import reload from "../../assets/images/icons/refresh.png"
import userImg from "../../assets/images/user1.jpg";
import axios from "../../API/axios";
import * as moment from "moment";
import Moment from "react-moment";
import Swal from "sweetalert2";
import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
import Pagination from "./pagination";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function ApprovedAgent() {
  const navigate = useNavigate();
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(10);
  const [allAgents, setAllAgents] = useState();
  const [pageShow , setPageShow] = useState(true)
  const [search, setSearch] = useState("")
  const getAllAproved = async () => {
    const { data } = await axios.get(`/agent/All?page=${currentPage}&limit=10`);
    console.log(data.data, "dd");
    setTotalCount(data.totalCount);
    setAllAgents(data.data);
  };
  //   useEffect(() => {
  //     getAllAproved();
  //   }, []);
  useEffect(() => {
    getAllAproved();
  }, [currentPage]);
  console.log(allAgents, "all");

  const editAgentAction = (agent) => {
    navigate("/admin/EditAgent", { state: { agent } });
  };

  const DeleteAgentAction = async (id) => {
    console.log(id, "iiiii");
   
    // const result = Swal.fire("","Do you want to delete this Agent", "question");
    // if (result) {
    //   const response = await axios.delete(`/agent/deleteAgentById?id=${id}`);
    //   if (response.data) {
    //     getAllAproved();
    //     Swal.fire("Agent is deleted succesfully", "success");
        
    //   }
    // }else{
    //   getAllAproved();
    // }
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this Agent",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response = await axios.delete(`/agent/deleteAgentById?id=${id}`);
        if (response.data) {
              
              Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
        getAllAproved();
            }
          }else{
            getAllAproved();
          }
       
      })
    
  };
  const searchQuotation = async (e) => {
    console.log(e, "zzzzzzzzzzz");
    console.log(e.target.value == "", "vaaallllllll");
   
      try {
        const { data } = await axios.get(
          `/search?search=${search}&collection=Agent&type=agent&multiFieldSearch=firstName,lastName,fullName,gst,pan,phone,email,Company_Name`
        );

        console.log(data, "DAAAAAAAAAA");
        setAllAgents(data.result);
        setPageShow(false)
      } catch (error) {
        console.log(error);
        setAllAgents([]);
        setPageShow(false)
      }
   
  };
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <AdSidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <AdNavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>Approved Agent List</h4>
                      </div>
                    </div>

                    <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            searchQuotation(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                         
                          onClick={(e) => {
                            getAllAproved() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-auto">
                                            <div className="prdctsortCol">
                                                <div className="form-group">
                                                    <select ref={selectRef3} className="wide">
                                                        <option value="Featured">Sort By</option>
                                                        <option value="10">ASC-DSC</option>
                                                        <option value="25">DSC-ASC</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                    {/* <div className="col-md-auto">
                                            <div className="prdctsortCol">
                                                <div className="form-group">
                                                    <select ref={selectRef2} className="wide">
                                                        <option value="Featured">Status</option>
                                                        <option value="10">Active</option>
                                                        <option value="25">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive text-center">
                    <Table classname="table">
                      <thead>
                        <tr>
                        <th>Agent code</th>
                          <th>Agent name</th>
                          <th>Company Name</th>
                          <th>GST/ PAN</th>
                          <th width="140">Phone/ Email</th>
                          {/* <th >Email</th> */}
                          <th>Registered Date</th>
                          <th>Status</th>
                          <th align="center" width="80">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allAgents?.length > 0 ? (
                          <>
                            {allAgents.map((agent) => {
                              return (
                                <tr className="whitebgRow">
                                  <td>{agent.AgentID}</td>
                                  <td className="">
                                    {/* <div class="prdctImg">
                                                 <img src={userImg} alt="" height="50" />
                                                </div> */}
                                    <div class="data ps-2">
                                      <p className="m-0">
                                        {agent.firstName} {agent.lastName}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="SubCat">
                                      {agent.Company_Name}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="">
                                      <div className="brdbtm">
                                        {agent.GST_Number}
                                      </div>
                                      <div> {agent.PAN_Agent}</div>
                                    </div>
                                  </td>
                                  <td className="lightbg">
                                    <div className="brdbtm">{agent.phone}</div>
                                    <div className="prodctTitle">
                                      {agent.email}
                                    </div>
                                  </td>

                                  {/* <td>
                                                
                                            </td> */}
                                  <td>
                                    <div className="pdrctCat ">
                                      <Moment format="DD MMM YYYY">
                                        {agent.createdAt}
                                      </Moment>
                                    </div>
                                  </td>

                                  <td className="status">
                                    <div
                                      className={
                                        agent.status == "PENDING"
                                          ? "btn btn-warning"
                                          : agent.status == "SUSPENDED"
                                          ? "btn btn-danger"
                                          : "btn btn-success"
                                      }
                                    >
                                      {agent.status}
                                    </div>
                                  </td>
                                  <td className="actions">
                                    <div class="tbl-actn text-center">
                                      <ul>
                                        <li class="edit-btn">
                                          <div
                                            class=""
                                            onClick={() => {
                                              editAgentAction(agent);
                                            }}
                                          >
                                            <a>
                                              <img
                                                src={editIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={editWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                        {/* <li class="delete-btn">
                                          <div class="">
                                            <a
                                              onClick={() => {
                                                DeleteAgentAction(agent._id);
                                              }}
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={deleteWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li> */}
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <>No Approved Agents to show</>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {pageShow ?
                  <div class="pgntnOuter text-center pt-3 ">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={TotalCount}
                      pageSize={limit}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>:<></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default ApprovedAgent;
