import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Row, Col, Container, Form, label } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import axios from "../../API/axios";
import Swal from "sweetalert2";
window.jQuery = window.$ = $;
require("jquery-nice-select");

const InitialFormData = {
  agentName: "",
  companyName: "",
  gstNumber: "",
  panCompany: "",
  panAgent: "",
  contactNumber: "",
  emailId: "",
  address: "",
};

function AddAdminUser() {
  const States = [
    { value: "AN", label: "Andaman and Nicobar Islands" },
    { value: "AP", label: "Andhra Pradesh" },
    { value: "AR", label: "Arunachal Pradesh" },
    { value: "AS", label: "Assam" },
    { value: "BR", label: "Bihar" },
    { value: "CH", label: "Chandigarh" },
    { value: "CT", label: "Chhattisgarh" },
    { value: "DN", label: "Dadra and Nagar Haveli" },
    { value: "DD", label: "Daman and Diu" },
    { value: "DL", label: "Delhi" },
    { value: "GA", label: "Goa" },
    { value: "GJ", label: "Gujarat" },
    { value: "HR", label: "Haryana" },
    { value: "HP", label: "Himachal Pradesh" },
    { value: "JK", label: "Jammu and Kashmir" },
    { value: "JH", label: "Jharkhand" },
    { value: "KA", label: "Karnataka" },
    { value: "KL", label: "Kerala" },
    { value: "LA", label: "Ladakh" },
    { value: "LD", label: "Lakshadweep" },
    { value: "MP", label: "Madhya Pradesh" },
    { value: "MH", label: "Maharashtra" },
    { value: "MN", label: "Manipur" },

    { value: "ML", label: "Meghalaya" },

    { value: "MZ", label: "Mizoram" },

    { value: "NL", label: "Nagaland" },

    { value: "OR", label: "Odisha" },
    { value: "PY", label: "Puducherry" },

    { value: "PB", label: "Punjab" },

    { value: "RJ", label: "Rajasthan" },

    { value: "SK", label: "Sikkim" },

    { value: "TN", label: "Tamil Nadu" },

    { value: "TG", label: "Telangana" },

    { value: "TR", label: "Tripura" },

    { value: "UP", label: "Uttar Pradesh" },

    { value: "UT", label: "Uttarakhand" },

    { value: "WB", label: "West Bengal" },
  ];
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    // state: "",
    address: "",
    pass: "",
    CfmPass: "",
  };
  const numberReg = /^\d+$/;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string().required("This field is required").email("Enter Valid Email"),
    phone: Yup.string()
      .required("This field is required")
      .matches(numberReg, "Enter Valid Phone Number")
      .min(10, "please Enter Valid Phone Number")
      .max(10, "please Enter Valid Phone Number"),
    // state: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    pass: Yup.string().required("This field is required"),
    CfmPass: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("pass")], "Password must match"),
  });
  const navigate = useNavigate();
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (value) => {
        createAdminUser();
      },
    });
  // const handleChange = ({ nativeEvent }) => {
  //   let _formData = formData;
  //   _formData[nativeEvent.target.name] = nativeEvent.target.value;
  //   setFormData({ ..._formData });
  // };
  const createAdminUser = async () => {
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      password: values.pass,
      confirmPassword: values.CfmPass,
      Address: {
        state: "n/a",
        mainAddressText: values.address,
      },
    };
    console.log(body);
    try {
      const { data } = await axios.post(`/signUp/admin`, body);
      console.log(data);
      if(data.status === 201)
      Swal.fire("", `Admin Successfully Created!`, "success").then(() => {
        navigate("/admin/list-admin-user");
      });
    } catch (error) {
      console.log(error);
      Swal.fire("", `Something went wrong!`, "error");
    }
  };
  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid px-0  mt-md-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng mb-4">
                      <h3> Add Admin User</h3>
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row">
                          <div className="col-md">
                            <div class=" mb-3">
                              <div className="row">
                                {/* <div className="col-md">
                                  <Form.Label> Username/Admin ID</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Username"
                                  />
                                </div> */}
                                <div className="col-md">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter First Name"
                                  />
                                  {errors.firstName && touched.firstName ? (
                                    <p className="text-danger">
                                      {errors.firstName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md">
                                  <Form.Label>Last Name.</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Last Name."
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.lastName && touched.lastName ? (
                                    <p className="text-danger">
                                      {errors.lastName}
                                    </p>
                                  ) : null}
                                </div>
                                {/* <div className="col">
                                                                        <Form.Label>Enter Brand Name</Form.Label>
                                                                        <select ref={selectRef3} className="wide">
                                                                                <option value="Featured">Enter Brand Name </option>
                                                                                <option value="10">Brand Name Two</option>
                                                                                <option value="25">Brand Name Three</option>
                                                                            </select>
                                                                    </div>                                                                    */}
                              </div>
                              {/* <div className="row my-3">
                                <div className="col">
                                  <Form.Label>PAN of Company</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="PAN of Company"
                                  />
                                </div>
                                <div className="col">
                                  <Form.Label>PAN of Admin User</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="PAN of Admin User"
                                  />
                                </div>
                              </div> */}
                              <div className="row">
                                <div className="col-md">
                                  <Form.Label>Contact No.</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Contact No."
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.phone && touched.phone ? (
                                    <p className="text-danger">
                                      {errors.phone}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md">
                                  <Form.Label>Email ID</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Email ID"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.email && touched.email ? (
                                    <p className="text-danger">
                                      {errors.email}
                                    </p>
                                  ) : null}
                                </div>
                                {/* <div className="col-md">
                                  <Form.Label>Status</Form.Label>
                                  <select
                                    ref={selectRef2}
                                    className="wide"
                                    name="m"
                                  >
                                    <option>ACTIVE</option>
                                    <option>INACTIVE</option>
                                    <option>SUSPENDED</option>
                                  </select>
                                </div> */}
                                {/* <div className="col">
                                  <Form.Label>State</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="state"
                                    className="form-control"
                                    value={values.state}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option>Select State</option>
                                    {States.map((state) => {
                                      return (
                                        <option
                                          key={state.value}
                                          value={`${state.value}-${state.label}`}
                                        >
                                          {state.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                  {errors.state && touched.state ? (
                                    <p className="text-danger">
                                      {errors.state}
                                    </p>
                                  ) : null}
                                </div> */}
                              </div>
                              <Row className="mt-3">
                                <Col>
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Address"
                                  />
                                  {errors.address && touched.address ? (
                                    <p className="text-danger">
                                      {errors.address}
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col>
                                  <Form.Label>Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="pass"
                                    value={values.pass}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Password"
                                  />
                                  {errors.pass && touched.pass ? (
                                    <p className="text-danger">{errors.pass}</p>
                                  ) : null}
                                </Col>
                                <Col>
                                  <Form.Label>Confirm Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="CfmPass"
                                    value={values.CfmPass}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Confirm Password"
                                  />
                                  {errors.CfmPass && touched.CfmPass ? (
                                    <p className="text-danger">
                                      {errors.CfmPass}
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <button
                            class="btnCommon  mt-3 "
                            onClick={handleSubmit}
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AddAdminUser;