import axios from "axios"
// import { Rest } from "../rest";
// get token from the http only cookie in production
// Token will change each time login happen in production
// const token ="Bearer " + window.localStorage.getItem("JWT");
export default axios.create({
   
    // baseURL : "http://143.244.129.163:9000",
    // baseURL : "http://localhost:9000",
    baseURL : "https://app.blacknutagri.com:9000", // Production Sever
    // baseURL : "http://164.90.221.132:9000", // Production Sever
})
// export const Rest="http://143.244.129.163:9000"
// export const Rest="http://localhost:9000"
export const Rest="https://app.blacknutagri.com:9000" // Production Sever
// export const Rest="http://164.90.221.132:9000" // Live Sever