import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import $ from "jquery";
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import userImg from "../../assets/images/user1.jpg";

import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function Report() {
  const selectRef1 = useRef();
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
    $(selectRef2.current).niceSelect();
    $(selectRef3.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk">
                    <div className="col">
                      <div className="MainHdng">
                        <h4>Products Reports</h4>
                      </div>
                    </div>

                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef3} className="wide">
                            <option value="Featured">Weekly</option>
                            <option value="10">This Week</option>
                            <option value="25">Last Week</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef1} className="wide">
                            <option value="Featured">Monthly</option>
                            <option value="1">Janaury</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef2} className="wide">
                            <option value="Featured">Yearly</option>
                            <option value="1">2023</option>
                            <option value="2">2022</option>
                            <option value="3">2021</option>
                            <option value="4">2020</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div className="addPrdctBtn">
                        <button class="btnCommon m-0" type="submit">
                          PDF
                        </button>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div className="addPrdctBtn">
                        <button class="btnCommon m-0 btnDark1" type="submit">
                          <i class="fa fa-file-excel" aria-hidden="true"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table">
                    <Table classname="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product ID</th>
                          <th>Description of Goods</th>
                          <th>No of Estimates</th>
                          <th>No of Quotations</th>
                          <th>No of PI</th>
                          <th>Amount of Purchase Invoices</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="whitebgRow">
                          <td>1.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>2.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>3.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>4.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>5.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>6.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>7.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>8.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>9.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>10.</td>
                          <td>
                            <div className="SubCat">OnF-1</div>
                          </td>
                          <td>
                            <div>Retail Packing (Raw Onion)</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnNew">586</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat btnActive">397</div>
                          </td>
                          <td className="status">
                            <div className="pdrctCat">258</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">1365950.00</div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div class="pgntnOuter text-center pt-3 ">
                    <ul class="pagination">
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">‹</span>
                          <span class="visually-hidden">Previous</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">2</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          3
                        </a>
                      </li>
                      <li class="page-item active">
                        <span class="page-link">
                          4<span class="visually-hidden">(current)</span>
                        </span>
                      </li>

                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">…</span>
                          <span class="visually-hidden">More</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          22
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">›</span>
                          <span class="visually-hidden">Next</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Report;
