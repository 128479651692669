import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { Table } from "react-bootstrap";
import AdSidebar from "./Sidebar";
import AdNavBar from "./NavBar";
import $ from "jquery";
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import userImg from "../../assets/images/user1.jpg";
import axios from "../../API/axios";
import * as moment from "moment";
import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
import Moment from "react-moment";
import Swal from "sweetalert2";
import Pagination from "../../container/pagination/pagination";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function ListAdminUser() {
  const navigate = useNavigate();
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  const allAgents = [
    {
      firstName: "Mubashir",
      Company_Name: "Muhammed Mubashir",
      GST_Number: "4545",
      PAN_Agent: "2334",
      logout: "01-Feb-2023",
      phone: "965646643",
      email: "mubashir@gmail.com",
      status: "ACTIVE",
    },
    {
      firstName: "saksham",
      Company_Name: "saksham varma",
      GST_Number: "4545",
      PAN_Agent: "2334",
      logout: "31-Jan-2023",
      phone: "9876543210",
      email: "saksham@gmail.com",
      status: "INACTIVE",
    },
    {
      firstName: "abhed",
      Company_Name: "Abhed Anil",
      GST_Number: "4545",
      PAN_Agent: "2334",
      logout: "15-Jan-2023",
      phone: "8838383838",
      email: "abhed@gmail.com",
      status: "ACTIVE",
    },
    {
      firstName: "Username",
      Company_Name: "Test user",
      GST_Number: "4545",
      PAN_Agent: "2334",
      phone: "8838383838",
      logout: "31-Jan-2023",
      email: "user@gmail.com",
      status: "ACTIVE",
    },
    {
      firstName: "febin",
      Company_Name: "febin KK",
      GST_Number: "4545",
      PAN_Agent: "2334",
      logout: "30-Jan-2023",
      phone: "9876543210",
      email: "febin@gmail.com",
      status: "INACTIVE",
    },
  ];
  const limit = 10;
  const [adminList, setAdminList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(0);
  const getAdmins = () => {
    axios.get(`/admin?page=${currentPage}&limit=${limit}`).then((res) => {
      console.log(res.data.data);
      setAdminList(res.data.data);
      setTotalCount(res?.data?.totalCount);
    });
  };
  const deleteAdminUser = (id) => {
    console.log(id);
    axios.delete(`/admin/deleteAdmin?id=${id}`).then((res) => {
      console.log(res.data);
      if(res.data.status === 200 ){
      Swal.fire("", `Admin Deleted Successfully!!`, "error");
      getAdmins();}
    });
  };
  useEffect(() => {
    getAdmins();
  }, [currentPage]);
  useEffect(() => {
    getAdmins();
  }, []);
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <AdSidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <AdNavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center g-3 pb-20 hdngRowBlk">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>Admin User List</h4>
                      </div>
                    </div>

                    {/* <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div> */}
                    {/* <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef3} className="wide">
                            <option value="Featured">Sort By</option>
                            <option value="10">ASC-DSC</option>
                            <option value="25">DSC-ASC</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef2} className="wide">
                            <option value="Featured">Status</option>
                            <option value="10">Active</option>
                            <option value="25">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive text-center">
                    <Table classname="table">
                      <thead>
                        <tr>
                          {/* <th> Admin ID</th> */}
                          <th>Full Name</th>
                          {/* <th>GST/ PAN</th> */}
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Last Logout</th>
                          {/* <th>Status</th> */}
                          <th align="center" width="150">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminList?.length > 0 ? (
                          <>
                            {adminList.map((agent) => {
                              return (
                                <tr className="whitebgRow">
                                  {/* <td className="">
                                  
                                    <div class="data ps-2">
                                      <p className="m-0">{agent._id}</p>
                                    </div>
                                  </td> */}
                                  <td>
                                    <div className="SubCat">
                                      {`${agent.firstName} ${agent.lastName}`}
                                    </div>
                                  </td>
                                  {/* <td>
                                    <div className="SubCat">
                                      <div>{agent.GST_Number}/</div>
                                      <div> {agent.PAN_Agent}</div>
                                    </div>
                                  </td> */}
                                  <td>{agent.phone}</td>

                                  <td>
                                    <div className="prodctTitle">
                                      {agent.email}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="pdrctCat ">
                                      <Moment format="DD MMM YYYY">
                                        {agent.logoutTime[0]}
                                      </Moment>
                                    </div>
                                  </td>

                                  {/* <td className="status">
                                    <div 
                                    // className=" btn btn-success "
                                    className={agent.status=="ACTIVE"?"btn btn-success":"btn btn-danger"}
                                    >
                                      {agent.status}
                                    </div>
                                  </td> */}
                                  <td className="actions">
                                    <div class="tbl-actn  text-center">
                                      <ul>
                                        <li class="edit-btn">
                                          <div
                                            class=""
                                            // onClick={() => {
                                            //   editAgentAction(agent);
                                            // }}
                                          >
                                            <a
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                navigate(
                                                  "/admin/edit-admin-user",
                                                  { state: agent }
                                                );
                                              }}
                                            >
                                              <img
                                                src={editIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={editWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="delete-btn">
                                          <div class="">
                                            <a
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                deleteAdminUser(agent._id);
                                              }}
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={deleteWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <>No Admin User to show</>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <div class="pgntnOuter text-center pt-3 ">
                  <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={TotalCount}
                      pageSize={limit}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  {/* <div class="pgntnOuter text-center pt-3 ">
                    <ul class="pagination">
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">‹</span>
                          <span class="visually-hidden">Previous</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">2</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          3
                        </a>
                      </li>
                      <li class="page-item active">
                        <span class="page-link">
                          4<span class="visually-hidden">(current)</span>
                        </span>
                      </li>

                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">…</span>
                          <span class="visually-hidden">More</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          22
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">›</span>
                          <span class="visually-hidden">Next</span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default ListAdminUser;