import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Row, Col, Container, Form, label } from "react-bootstrap";
import upload from "../../assets/images/uploadIcon.svg";
import { Table } from "react-bootstrap";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import axios, { Rest } from "../../API/axios";
import Swal from "sweetalert2";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function BulkPrice() {
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const [machines, setMachines] = useState([]);
  const [excel, setExcel] = useState();
 
  const loadData = () => {
    axios
      .get(`/product/getAllMachines`)
      .then((res) => {
        console.log(res.data.data);
        setMachines(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadBulkPrice = () => {
    if (!excel) {
      return;
    }
    const formData = new FormData();
    formData.append("file", excel);
    axios
      .put(`/product/bulkProductPriceUpdateOfOneMachine`, formData)
      .then((res) => {
        console.log(res.data);
        if(res.data.message === "Partial Success"){
        Swal.fire("",`File Uploaded Successfully!`,"success")
      }else if(res.data.message === "Nothing was updated"){
        Swal.fire("",`File Already Exists!`,"error")
      }else{
        Swal.fire("",`Something Went Wrong!`,"error")
      }
      });
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng mb-2" style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}>
                      <h3> Bulk Price Update For Products</h3>
                      <div className="text-center">
                        Download All Products For Bulk Price<br/>
                        <a
                                    href={`${Rest}/product/generateCsvOfOneMachine`}
                                    className="btnCommon"
                                  >
                                    Download
                                  </a>
                      </div>
                    </div>
                    {/* <div>
                      <Link
                        to={""}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Click here
                      </Link>{" "}
                      to download all products
                    </div> */}
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row">
                          <div className="col">
                            <div class=" mb-3">
                              <div className="mb-15 mt-4">
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label>
                                        Upload the file to Bulk price update for
                                        product
                                      </label>
                                      <div>
                                        <input
                                          type="file"
                                          //   multiple
                                          onChange={(e) => {
                                            setExcel(e.target.files[0]);
                                          }}
                                          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                          className="form-control fileUpload  form-control-lg"
                                          style={{height:"41px"}}
                                        />
                                        <div class="uploadBlkInr" style={{padding:"15px 50px"}}>
                                            
                                          <div class="uplogImg">
                                            <img
                                              src={upload}
                                              alt=""
                                              height="50"
                                            />
                                          </div>
                                          <div class="uploadFileCnt">
                                          <p>
                                            Upload the file to Bulk price update for
                                        product</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                                          
                        <div className="">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              uploadBulkPrice();
                            }}
                            class="btnCommon  mt-3 "
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div>
                    {/* <div className="prdctListTble common-table">
                      <Table classname="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Machine_name</th>
                          
                            <th>Product_name</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {machines.map((p, index) => {
                            console.log("p", p);
                            return (
                              <tr className="whitebgRow">
                                <td>{index + 1}.</td>
                                <td>
                                  <div className="SubCat">{p.Machine_name}</div>
                                </td>
                                
                                <td className="status">
                                  <div className="pdrctCat btnNew">
                                    {p.Product_name}
                                  </div>
                                </td>
                                <td className="status">
                                  <a
                                    href={`${Rest}/product/generateCsvOfOneMachine?id=${p._id}`}
                                    className="btnCommon"
                                  >
                                    Download
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default BulkPrice;
