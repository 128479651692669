import React from "react";
import { Form } from "react-bootstrap";
import { States } from "../../helper/util";

const Step3 = ({
  handleChange,
  values,
  handleBlur,
  nextStep,
  prevStep,
  touched,
  errors,
}) => {
  const handleNext = () => {
  
    const fieldsToCheck = ["state", "city", "mainAddressText", "pincode"];

    // Check if all of the specified fields don't exist in the errors object
    if (fieldsToCheck.every((field) => !errors[field]) && values.state!=="") {
      nextStep(4);
    } else {
      return false;
    }
  };
  return (
    <div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          State*
        </label>

        <Form.Select
          required
          aria-label="Default select example"
          placeholder="Select state"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          name="state"
          class="form-control"
        >
          <option>Select State</option>
          {States.map((state) => {
            return (
              <option key={state.value} value={`${state.value}-${state.label}`}>
                {state.label}
              </option>
            );
          })}
        </Form.Select>
        {errors.state && touched.state ? (
          <p className="text-danger">{errors.state}</p>
        ) : null}
      </div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          District*
        </label>
        <input
          required
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          name="city"
          type="text"
          class="form-control"
          placeholder="Enter district"
        />
        {errors.city && touched.city ? (
          <p className="text-danger">{errors.city}</p>
        ) : null}
      </div>

      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Address*
        </label>
        <input
          required
          value={values.mainAddressText}
          onChange={handleChange}
          onBlur={handleBlur}
          name="mainAddressText"
          type="text"
          class="form-control"
          placeholder="Enter address"
        />
        {errors.mainAddressText && touched.mainAddressText ? (
          <p className="text-danger">{errors.mainAddressText}</p>
        ) : null}
      </div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Pincode*
        </label>
        <input
          required
          value={values.pincode}
          onChange={handleChange}
          onBlur={handleBlur}
          name="pincode"
          type="text"
          class="form-control"
          placeholder="Enter pincode"
        />
        {errors.pincode && touched.pincode ? (
          <p className="text-danger">{errors.pincode}</p>
        ) : null}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        {/* <button
          onClick={() => prevStep(2)}
          className="btn btn-light w-50 waves-effect waves-light"
        >
          Prev
        </button> */}
        <button
      
          onClick={handleNext}
          className="btn btn-primary w-100 waves-effect waves-light"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step3;
