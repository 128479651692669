import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Modal, Table, Button } from "react-bootstrap";
import AdSidebar from "./Sidebar";
import AdNavBar from "./NavBar";
import $ from "jquery";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import * as moment from "moment";
import axios from "../../API/axios";
import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
import Moment from "react-moment";
import Pagination from "./pagination"
window.jQuery = window.$ = $;
require("jquery-nice-select");
function Listgallery() {
  const limit = 10;
  const [gallery, setGallery] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(10);
  const [videourl, setVideourl] = useState()
  const [pageShow, setPageShow] = useState(true)
  function getYouTubeVideoId(url) {
    const pattern = /^https?:\/\/(?:www\.)?(?:youtu\.be\/([\w-]+)|youtube\.com\/watch\?v=([\w-]+)(?:&t=\d+(s)?)?)$/;
    const matches = url.match(pattern);
    return matches ? matches[1] || matches[2] : null;
  }
  const handlemodalShow = (data) => {
    console.log(data, ">>>>>>>>>>>>>>")
    setModalShow(true);
    const url = new URL(data);
    // const videoId = url.searchParams.get('v');
    console.log(url.search,'klkl')
    // const videoId = getYouTubeVideoId(url)
    if(url.search == "" ){
       
      const videoId = url.pathname
    console.log(videoId,'videoId')
    setVideourl(videoId)

    }else{
    const videoId = new URL(url).searchParams.get('v');

    console.log(videoId,'videoId')
    setVideourl(videoId)
    }
  };
  const getGallery = async () => {
    try {
      const { data } = await axios.get(`/admin/getGallary?page=${currentPage}&limit=10`)
      console.log(data)
      setTotalCount(data.totalCount)
      setGallery(data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getGallery()
  }, [currentPage])
  
  const DeleteAgentAction = async (id) => {

    // const result = window.confirm(`Do you want to delete this Agent `)
    //  Swal.fire(
    //     'Do you want to delete this Agent',
    //     'question'
    //   )
    //   console.log(result.isConfirmed,">>>>>>>>>>")
    // if (result.isConfirmed) {
    //     const response = await axios.delete(`/admin/deleteGal?id=${id}`)
    //     if (response.data) {

    //       getGallery()
    //         Swal.fire(
    //             'Agent is deleted succesfully',
    //             'success'
    //           )

    //     }
    // }
    Swal.fire({
      title: 'Do you want to delete this Content?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const response = await axios.delete(`/admin/deleteGal?id=${id}`)
        console.log(response, "response")
        if (response.data) {

          getGallery()
          Swal.fire(
            'Content is deleted succesfully',
            'success'
          )

        }
      } else if (result.isDenied) {
        Swal.fire('Content is not deleted', '', 'error')
      }
    })

  }
  // const getVideo=(video)=>{
  //   console.log(video,">>>>>>>>>>>>>>")
  //   const url = new URL(video);
  // const videoId = url.searchParams.get('v');
  //   console.log(videoId,">>>>>>>>>>>>>>")
  //   return videoId
  // }


  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <AdSidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <AdNavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>List Gallery</h4>
                      </div>
                    </div>



                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive text-center">
                    <Table classname="table">
                      <thead>
                        <tr>
                          {/* <th>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </th> */}
                          <th>#</th>
                          <th>Title</th>

                          <th align="center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {gallery?.length > 0 ? (
                          <>
                            {gallery.map((item, index) => {
                              return (
                                <tr className="whitebgRow">

                                  <td>
                                    <div className="pdrctCat">

                                      {index + 1}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="SubCat ">
                                      {item.Title}
                                    </div>
                                  </td>
                                  <td className="actions">
                                    <div class="tbl-actn text-center">
                                      <ul>
                                        <li class="edit-btn">
                                          <div
                                            class=""
                                            onClick={() => {
                                              handlemodalShow(item.Content);
                                            }}
                                          >
                                            <a>
                                              <img
                                                src={editIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={editWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="delete-btn">
                                          <div class="">
                                            <a
                                              onClick={() => {
                                                DeleteAgentAction(item._id);
                                              }}
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={deleteWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <>Please Add Some Gallery To View</>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <div class="pgntnOuter text-center pt-3 ">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={TotalCount}
                      pageSize={limit}
                      onPageChange={(page) => {
                        setCurrentPage(page)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe src={`https://www.youtube.com/embed/` + videourl} width={"100%"} height={"300px"} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)} style={{ width: "80px" }}>Close</Button>
          </Modal.Footer>
        </Modal>
      </article>
    </>
  );
}
export default Listgallery;
