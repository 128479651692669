import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import AdSidebar from "./Sidebar";
import AdNavBar from "./NavBar";
import reload from "../../assets/images/icons/refresh.png"
import $ from "jquery";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import * as moment from "moment";
import axios from "../../API/axios";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import Quoation from "../Agent/Quotation";
import Moment from "react-moment";
import Pagination from "./pagination"
window.jQuery = window.$ = $;
require("jquery-nice-select");
function ListQuotations() {
  const navigate = useNavigate();
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
   const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(10); 
  const [allQuotations, setAllQuotations] = useState();
  const [pageShow , setPageShow] = useState(true)
  const [search, setSearch] = useState("")
  const getAllQuotations = async () => {

        const result = await axios.get(`/quotation?page=${currentPage}&limit=10`)
        // console.log(data.data, "pp");
        console.log(result, "ddd");
        console.log(result.data.totalCount, "dddpooooooooo");
        setTotalCount(result.data.totalCount)
        setAllQuotations(result?.data?.data)
    }
    useEffect(() => {
        getAllQuotations()
    }, [])
    console.log(allQuotations, "qq");
    useEffect(() => {
        getAllQuotations()
    }, [currentPage])
  console.log(allQuotations, "qq");

  const navigateEdit = (Quotation) => {
    navigate("/admin/editquotation", { state: { Quotation } });
  };

const searchQuotation = async (e) => {
        // console.log(e, "zzzzzzzzzzz");
        // console.log(e.target.value == "", "vaaallllllll");
       



            try {
                const { data } = await axios.get(
                    `/search?search=${search}&collection=Estimate&typeOfEstimate=Quotation&multiFieldSearch=agentName,customerName,customerPhone,QuotationId`
                );


                console.log(data, "DAAAAAAAAAA");
                setPageShow(false)
                setAllQuotations(data.result);
             
            } catch (error) {
                console.log(error);
                setAllQuotations([])
                setPageShow(true)
            }
        

    };
  const [allAgents, setAllAgents] = useState();
  const getAllAproved = async () => {
    const { data } = await axios.get("/agent/All");
    console.log(data.data, "qooo");
    setAllAgents(data.data);
  };
  useEffect(() => {
    getAllAproved();
  }, []);
  console.log(allAgents, "all");

  const getAgentName = (agentId) => {
    console.log(agentId, "cvc");
    console.log(allAgents, "aa");
    const agent = allAgents?.find((element) => {
      return element._id === agentId;
    });
    console.log(agent, "cr");
    return agent?.firstName + " " + agent?.lastName;
  };
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <AdSidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <AdNavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>List Quotations</h4>
                      </div>
                    </div>

                    <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search" onChange={(e)=>setSearch(e.target.value)}
                      />
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            searchQuotation(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                         
                         
                          onClick={(e) => {
                            getAllQuotations() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef3} className="wide">
                            <option value="Featured">Sort By</option>
                            <option value="10">Agent Name</option>
                            <option value="25">Customer Name</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-md-auto">
                      <div className="form-group">
                        <select ref={selectRef2} className="wide">
                          <option value="Featured">Status</option>
                          <option value="ACTIVE">Approved</option>
                          <option value="PENDING">Pending</option>
                          <option value="INACTIVE">Rejected</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive text-center">
                    <Table classname="table">
                      <thead>
                        <tr>
                          {/* <th>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </th> */}
                          <th>Date</th>
                          <th>Quotation No.</th>
                          <th>Agent Name</th>
                          <th>Customer Name</th>
                          <th>Customer Number</th>
                          <th>Status</th>
                          <th align="center" width="150">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allQuotations?.length > 0 ? (
                          <>
                            {allQuotations.map((Quotation) => {
                              return (
                                <tr className="whitebgRow">
                                  {/* <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                    />
                                  </td> */}
                                  <td>
                                    <div className="pdrctCat">
                                        <Moment format="DD-MMM-YYYY" >
                                            {Quotation.createdAt}
                                        </Moment>
                                      {/* {moment(Quotation.createdAt).format(
                                        "DD MMMM YYYY"
                                      )} */}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="prodctTitle">
                                    {Quotation.QuotationId}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="prodctTitle">
                                    {Quotation.agentName}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="prodctTitle">
                                      {Quotation.customerName}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="prodctTitle">
                                    {Quotation.customerPhone}
                                    </div>
                                  </td>
                                  <td className="status">
                                    <div 
                                    // className="btnCommon"
                                    className={Quotation.Status? Quotation.Status == "INACTIVE"?"btn btn-danger":Quotation.Status == "PENDING"?"btn btn-warning":"btn btn-success":"btnCommon"}
                                    >
                                      {Quotation.Status? Quotation.Status == "INACTIVE"
                                        ? "Rejected"
                                        : Quotation.Status == "PENDING"?"Pending":"Approved": "PENDING"}
                                    </div>
                                  </td>
                                  <td className="actions">
                                    <div class="tbl-actn text-center">
                                      <ul>
                                        <li
                                          class="edit-btn"
                                          onClick={() => {
                                            navigateEdit(Quotation);
                                          }}
                                        >
                                          <div class="">
                                            <a>
                                              <img
                                                src={editIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={editWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <>Please Create Some Quotation To View</>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {pageShow ?
                  <div class="pgntnOuter text-center pt-3 ">
                     <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={TotalCount}
                                            pageSize={limit}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                  </div>:<></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default ListQuotations;
