import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Button, Table, Tab, Tabs, Row, Col, Alert, Container, Form, label, Accordion } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Sidebar from './Sidebar';
import NavBar from './NavBar';
import $ from "jquery";
import upload from "../../assets/images/uploadIcon.svg";
import noimage from "../../assets/images/noImage.jpg";
import plus from "../../assets/images/plus.svg";
import axios from "../../API/axios"
window.jQuery = window.$ = $;
require("jquery-nice-select");
const InitialFormData = {
  crop: undefined,
  productName: "",
  machineName: "",

};
const initialModelFormData = {
  Capacity: "",
  Model: "",
  productId: "",
  Price: "",
  Status: ""
}



function EditProduct() {
  const [models, setModels] = useState([{
    Capacity: "",
    Model: "",
    ProductID: "",
    Price: 0,
    Status: "",
    Gst: 0,
    pdfFile: ""
  }])
  const [modelFormData, setModelFormData] = useState((x) => {
    return { ...initialModelFormData };
  });
  const [temperoryModel, SetTempModel] = useState([])
  const [productIdData, setProductIdData] = useState()
  const [CROPID, setCropId] = useState()
  const [proId, setProId] = useState()
  const navigate = useNavigate()
  const [products, setProducts] = useState([]);
  const { state } = useLocation();
  const machine = state.machine
  const [pro, setPro] = useState()
  const [checkid, setCheckid] = useState('')
  const [modelErrors, setModelErrors] = useState([{
    CapacityError: false,
    Model: false,
    productError: false,
    priceError: false,
    statusError: false,
    gstError: false,
    pdfError: false

  }]);
  console.log(machine, "mmO");

  const [c, setC] = useState()

  useEffect(() => {
    setProductIdData(machine.productId)
  }, [])
  const loadData = () => {

    var config = {
      method: 'get',
      url: `/product/productDetailForASelectMachine?machineId=${machine._id}`,

    };

    axios(config)
      .then(function (response) {
        console.log((response.data.data), "maaachiineee");
        const result = response.data.data
        console.log(result, 'reeeeeeeeeeeessssssssssssssssssssssssssssssssssssssssssssssssssssssssss');

        const newArr = result.map(({ UnitId
          , createdAt, cropId
          , machineId, updateAt, __v, ...rest }) => {
          return rest;
        });
        const cr = result[0].cropId
        setCropId(cr)
        console.log(cr, "1111111111111111111")


        let m = (newArr[0].ProductID).slice(0, -2)
        setCheckid(m)
        console.log(m, 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk');
        setC(newArr.length)
        setModels(newArr)
        let newvalue = []

        for (var i = 0; i < (newArr.length); i++) {
          console.log(newArr.length, 'leeeeeennnnnneeeeennnn', m);
          newvalue.push({
            CapacityError: false,
            Model: false,
            productError: false,
            priceError: false,
            statusError: false,
            gstError: false,
            pdfError: false
          })
          //   setModelErrors([...modelErrors,{
          //     CapacityError: false,
          //     Model: false,
          //     productError: false,
          //     priceError: false,
          //     statusError: false,
          //     gstError:false,
          // }])
        }
        setModelErrors(newvalue)


      })
      .catch(function (error) {
        console.log(error);
      });


  }
  useEffect(() => {
    if (machine) {
      loadData()
    }
  }, [])

  let modddel = [{}]
  const [noOfRows, setNoOfRows] = useState(1);
  const [allCrops, setAllCrops] = useState([])
  const [formData, setFormData] = useState({
    productName: machine?.Product_name,
    machineName: machine?.Machine_name,
  });



  const selectRef1 = useRef();
  const selectRef2 = useRef();
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
    $(selectRef2.current).niceSelect();
    $(selectRef3.current).niceSelect();
    $(selectRef1.current).on("change", (e) => {
      console.log(e.target.value, "eeeee");

      // setFormData(_formData => {
      //     return { ..._.cloneDeep(_formData), crop: ($(selectRef1.current)).val() }
      // });
      let _formData = formData;
      _formData[e.target.name] = e.target.value;
      setFormData({ ..._formData });
      console.log(formData, "fo");
    })
  }, [formData, allCrops]);

  // useEffect(() => {
  //   $(selectRef2.current).niceSelect();
  //   $(selectRef2.current).on("change", (e) => {
  //     console.log(e.target.value, "eeeee");

  //     // setFormData(_formData => {
  //     //     return { ..._.cloneDeep(_formData), crop: ($(selectRef1.current)).val() }
  //     // });
  //     let _modelFormData = modelFormData;
  //     _modelFormData[e.target.name] = e.target.value;
  //     setModelFormData({ ..._modelFormData });

  //   })
  // }, [modelFormData]);


  $("#test").on("input", function () {
    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "")
    }
  })

  const handleChange = ({ nativeEvent }) => {
    console.log(formData, "fffffffssssss");
    let _formData = formData;
    _formData[nativeEvent.target.name] = nativeEvent.target.value;
    setFormData({ ..._formData });
    // if (_formData.crop != undefined && _formData.productName !== "") {
    //   console.log("innnnnnnnnnnnn");
    //   console.log(models.length, "llllll");
    //   const count = models.length == 0 ? 1 : models.length + 1
    //   const pro = _formData.crop.slice(0, 2) + _formData.productName.slice(0, 1) + -count

    //   setProductIdData(pro)
    //   console.log(pro, "prooooooooooo");

    // }
  }

  const getAllCrops = async () => {

    const { data } = await axios.get("/product/getAllCrops")
    console.log(data.data, "dd");
    setAllCrops(data.data)
  }
  useEffect(() => {
    getAllCrops()
  }, [])
  console.log(allCrops, "CCCCCCCCCCCCCC");
  const getCropName = (cropId) => {
    if (allCrops.length > 0) {
      console.log(cropId, "cvc");
      console.log(allCrops, "aa");
      const crop = allCrops?.find((element) => {
        return element._id === cropId
      })
      return crop.crop

    }

  };
  const checkProductId = () => {
    for (var i = 0; i < models.length; i++) {
      if (allCrops.length > 0) {
        const count = i + 1
        console.log(count, "cccc");
        let lastProduct
        // if (CROPID) {
        // const cropname = getCropName(CROPID)
        //  const pro = cropname.slice(0, 2) + formData?.productName.slice(0, 1) + -count
        const pro = checkid + -count
        let newvalue = [...models]
        newvalue[i].ProductID = pro
        setModels(newvalue)
      }
    }


  }


  useEffect(() => {
    checkProductId()
  }, [pro])

  const checkProductIdForTemp = () => {
    console.log("inside chek temp form");
    if (allCrops.length > 0) {
      console.log(models, "om");
      let count = models?.length == 0 ? 1 : models?.length + 1
      if (temperoryModel.length != 0) {
        count += temperoryModel.length
      }
      console.log(count, "cccc");
      let lastProduct
      const cropname = getCropName(CROPID)
      console.log(cropname, 5555555555555);
      const pro = cropname.slice(0, 2) + formData?.productName.slice(0, 1) + -count
      console.log(pro, "teeeeeeeeeeeeeeeen");
      setProductIdData(pro)




    }

  }
  // useEffect(() => {
  //   checkProductIdForTemp()
  // }, [temperoryModel])

  console.log(allCrops, "all");
  console.log(formData, "ff");
  const [formErrors, setFormErrors] = useState({
    cropError: false,
    ProductNameError: false,
    MachineNameError: false,
  })


  const Change = (e, index) => {
    let _modelFormData = [...models];
    console.log(e.target.name, e.target.value, 'vvvvv');
    _modelFormData[index][e.target.name] = e.target.value;
    setModels(_modelFormData);
    console.log(models, 'models');
  };

  const fileUpload = async (e, index) => {
    console.log(index, 'ethuuuuuuuuuuuuuuuuuu');
    let file = e.target.files[0]

    let data = new FormData()
    data.append('file', file)
    let result = await axios.post("/upload", data)
    if (result) {
      console.log(result.data.data.url, 'resultttttttttttttttttttttttttttttttttttttttttttttttttttttttttt');
      let _modelvalues = [...models];
      _modelvalues[index].pdfFile = result.data.data.url
      setModels(_modelvalues)
    }


  }

  const addforms = () => {
    setModels([...models, {
      Capacity: "",
      Model: "",
      ProductID: "",
      Price: 0,
      Status: "",
      Gst: 0,
      pdfFile: ""
    }])
    let a = models.length

    setModelErrors([...modelErrors, {
      CapacityError: false,
      Model: false,
      productError: false,
      priceError: false,
      statusError: false,
      gstError: false,
      pdfError: false

    }])
    console.log("called");
    setPro(a)

    //  checkProductId();
  }
  const removeform = (index) => {
    let newvalue = [...models]
    newvalue.splice(index, 1)
    setModels(newvalue)
    // checkProductId();
    let newvalues = [...modelErrors]
    newvalues.splice(index, 1)
    setModelErrors([...newvalues])
    let a = models.length
    setPro(a)
  }

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   console.log("inside");
  //   let { Capacity, Model, Price, Status } = modelFormData
  //   let ProductID = productIdData
  //   let isError = false;
  //   let modelErrors = {
  //     CapacityError: false,
  //     Model: false,

  //     priceError: false,
  //     statusError: false,

  //   }
  //   if (Capacity == "") {
  //     modelErrors.CapacityError = `Please enter a valid capacity`;
  //     isError = true;
  //   }


  //   if (Price == "") {
  //     modelErrors.priceError = `Please enter price`;
  //     isError = true;
  //   }
  //   if (Status == "") {
  //     modelErrors.statusError = `Please select a status`;
  //     isError = true;
  //   }
  //   setModelErrors(modelErrors)
  //   if (!isError) {
  //     let data = {
  //       Price,
  //       Capacity,
  //       ProductID,
  //       Price,
  //       Status,
  //       Model
  //     }
  //     console.log(data, "dd");

  //     // SetTempModel([...temperoryModel, { Capacity, Price, ProductID, Status, Model }])

  //     // const defaultModelFormData = {
  //     //   Capacity: "",
  //     //   Model: "",
  //     //   productId: "",
  //     //   Price: "",
  //     //   Status: ""
  //     // }
  //     // setModelFormData({
  //     //   Capacity: "",
  //     //   Model: "",
  //     //   productId: "",
  //     //   Price: "",
  //     //   Status: ""
  //     // })
  //     // checkProductIdForTemp()

  //   }
  // }

  const handleSubmit = async () => {



    let { Capacity, Model, Price, Status } = models
    let {
      productName,
      machineName, } = formData
    let isError = false;
    let formErrors = {

      ProductNameError: false,
      MachineNameError: false,

    }

    if (productName == "") {
      formErrors.ProductNameError = `Please enter  product Name`;
      isError = true;
    }
    if (machineName == "") {
      formErrors.MachineNameError = `Please enter  Machine Name`;
      isError = true;
    }
    setFormErrors(formErrors)
    let modelError = [...modelErrors]
    if (models.length > 0) {
      for (var i = 0; i < models.length; i++) {
        console.log(i, 'iiii', models, modelError, modelErrors);

        if (!models[i].Capacity) {
          modelError[i].CapacityError = "please enter valid capacity"
          isError = true;
        } else {
          modelError[i].CapacityError = ""
        }
        if (models[i].Status === "") {
          modelError[i].statusError = "please select status"
          isError = true;
        } else {
          modelError[i].statusError = ""
        }

        if (!models[i].Price) {
          modelError[i].priceError = "please enter valid Price"
          isError = true;
        } else {
          modelError[i].priceError = ""
        }
        if (!models[i].Gst) {
          modelError[i].gstError = "please enter Gst"
          isError = true;
        } else {
          modelError[i].gstError = ""
        }
        if (models[i].pdfFile == "") {
          modelError[i].pdfError = "please select pdf"
          isError = true;
        } else {
          modelError[i].pdfError = ""
        }
      }
      setModelErrors(modelError)
    }
    console.log(modelErrors, 'err')
    if (!isError) {
      if (models.length == 0) {
        alert("please add a model")
      }
      let data = {
        Product_name: productName,
        Machine_name: machineName,
        productDetail: models
      }

      console.log(data, "ddda");

      const result = await axios.put(`/product/productUpdateForASelectMachine?machineId=${machine._id}`, data)
      if (result) {
        console.log(result);
        alert("Product updated succesfully")
        const updateMachineNamesInMachineList = await axios.get("UCIDB/UpdateInDB");
        window.location.reload()
      }
      else {

        alert("Check the product credentials you have entered and try again")
      }




    } else {

    }


  }
  const deleteModel = (index) => {
    console.log(index, "iii");
    console.log(models[index]);
    const editedModel = models.filter((model, key) => key != index)

    setModels([...editedModel])

  }
  let deletepdf = async (productId, index) => {
    let result = await axios.patch(`/product/deletepdf?id=${productId}`)
    if (result.data) {
      let _modelvalues = [...models];
      _modelvalues[index].pdfFile = ""
      setModels(_modelvalues)
    }
  }


  console.log(modelFormData, "modd");
  console.log(models, "mmm");
  console.log(modddel, "mddmdd");
  console.log(products, "prrrrrrrrr");
  console.log(CROPID, "CROOOOOOOOOOOOOOOOO");
  console.log(temperoryModel, "tt");
  const deleteSingleProduct = async (id) => {
    console.log(id, "iiidddddsssss");
    const approve = window.confirm("Are you sure to delete this product?")
    if (approve) {
      const result = await axios.delete(`/product/deleteOneProduct?id=${id}`)
      if (result.data) {
        alert("Product Deleted succesfully")
        loadData()
      }
      else {
        alert("Deletion unsuccesfull")
      }

    }


  }
  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Edit Product</h3>
                    </div>
                    <div class="hrStyle pb-4">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        {allCrops.length > 0 && (
                          <div class="row  d-flex align-items-center mb-3 ">
                            <div class="col-md-12">
                              <Form.Label>Crop</Form.Label>
                              <Form.Control
                                value={getCropName(machine?.cropId)}
                              ></Form.Control>
                            </div>

                            <div className="col-md-12 py-3">
                              <Form.Label>Add Product Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Product Name"
                                name="productName"
                                value={machine.Product_name}
                                onChange={handleChange}
                              />
                            </div>
                            <div class="col-md-12">
                              <Form.Label>Machine Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Machine Name"
                                value={machine.Machine_name}
                                name="machineName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {/* <div className="MainHdng mt-5">
                                                <h3>Add Attributes</h3>
                                            </div> */}
                        <div class="hrStyle pb-4">
                          <hr />
                        </div>
                        <div className="border p-4  radius20">
                          <div className=" table-responsive">
                            <div className="widhtCustm">
                              <div class="row g-4  d-flex align-items-center ">
                                <div class="col">
                                  <div className="varintRow d-none d-lg-block">
                                    <div class=" mb-3">
                                      <div className="row ">
                                        <div className="col-sm-2">
                                          <Form.Label>Capacity</Form.Label>
                                        </div>
                                        <div className="col-sm-1">
                                          <Form.Label>Model</Form.Label>
                                        </div>
                                        <div className="col-sm-1">
                                          <Form.Label className="">Product ID</Form.Label>
                                        </div>
                                        <div className="col-sm-2 ml-2 ps-3">
                                          <Form.Label className="ms-2 ps-3">Price</Form.Label>
                                        </div>
                                        <div className="col-sm-2">
                                          <Form.Label>Status</Form.Label>
                                        </div>
                                        <div className="col-sm-2">
                                          <Form.Label>GST (%)</Form.Label>
                                        </div>
                                        <div className="col-sm-2">
                                          <Form.Label>file</Form.Label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {models?.length > 0 ? (
                                      <>
                                        {models?.map((singleModel, index) => {
                                          return (
                                            <div className="varintRow">
                                              <div class=" mb-3">
                                                <div className="row g-4 ">
                                                  <div className="col-lg-2">
                                                    <Form.Control
                                                      type="text"
                                                      name="Capacity"
                                                      placeholder="Capacity"
                                                      onChange={(e) => { Change(e, index) }}
                                                      value={singleModel.Capacity}
                                                    />
                                                    {modelErrors[index]?.CapacityError && (
                                                      <p className="text-danger mt-2">
                                                        * {modelErrors[index]?.CapacityError}
                                                      </p>
                                                    )}
                                                  </div>
                                                  <div className="col-lg-1">
                                                    <Form.Control
                                                      type="text"
                                                      name="Model"
                                                      placeholder="Model"
                                                      onChange={(e) => { Change(e, index) }}
                                                      value={singleModel.Model}
                                                    />
                                                    {/* {modelErrors[index].Model && (
                                                                                            <p className="text-danger mt-2">
                                                                                                * {modelErrors[index].Model}
                                                                                            </p>
                                                                                        )} */}
                                                  </div>
                                                  <div className="col-lg-1">
                                                    <Form.Control
                                                      name="productId"
                                                      placeholder="Product ID"
                                                      value={singleModel.ProductID}
                                                    />
                                                    {/* {modelErrors[index]?.productError && (
                                                                                            <p className="text-danger mt-2">
                                                                                                * {modelErrors[index]?.productError}
                                                                                            </p>
                                                                                        )} */}
                                                  </div>

                                                  <div className="col-lg-2">
                                                    <Form.Control
                                                      id="test"
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="Price"
                                                      placeholder="Price"
                                                      onChange={(e) => { Change(e, index) }}
                                                      value={singleModel.Price}
                                                    />
                                                    {modelErrors[index]?.priceError && (
                                                      <p className="text-danger mt-2">
                                                        * {modelErrors[index]?.priceError}
                                                      </p>
                                                    )}
                                                  </div>
                                                  <div className="col-lg-2">
                                                    <select
                                                      className="nice-select"
                                                      name="Status"
                                                      onChange={(e) => { Change(e, index) }}
                                                      value={singleModel.Status}
                                                    >
                                                      <option>Status</option>

                                                      <option value="ACTIVE">ACTIVE</option>
                                                      <option value="INACTIVE">
                                                        INACTIVE
                                                      </option>
                                                    </select>
                                                    {modelErrors[index]?.statusError && (
                                                      <p className="text-danger mt-2">
                                                        * {modelErrors[index]?.statusError}
                                                      </p>
                                                    )}
                                                  </div>
                                                  <div className="col-lg-1 col-md-1">
                                                    <Form.Control
                                                      id="test"
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="Gst"
                                                      placeholder="Gst"
                                                      onChange={(e) => { Change(e, index) }}
                                                      value={singleModel.Gst}
                                                    />
                                                    {modelErrors[index]?.gstError && (
                                                      <p className="text-danger mt-2">
                                                        * {modelErrors[index]?.gstError}
                                                      </p>
                                                    )}
                                                  </div>
                                                  {singleModel.pdfFile ? <div className="col-lg-2 col-md-1 ps-3 ">

                                                    <a href={`${singleModel.pdfFile}`} target="_blank" rel="noreferrer" className='btnCommon1'>
                                                      View
                                                    </a>
                                                    <a className="ps-3" onClick={() => { deletepdf(singleModel.ProductID, index) }}>
                                                      <img
                                                        src={deleteIcon}
                                                        alt=""
                                                        height="18"
                                                      />

                                                    </a>

                                                    {modelErrors[index]?.pdfError && (
                                                      <p className="text-danger mt-2">
                                                        * {modelErrors[index]?.pdfError}
                                                      </p>
                                                    )}                </div> : <div className="col-lg-2 col-md-1">
                                                    {/* <Form.Control
                                                                                            id="test"
                                                                                            type="file"
                                                                                             name="file"
                                                                                            placeholder="file"
                                                                                            onChange={(e)=>{Change(e,index)}}
                                                                                            
                                                                                        /> */}
                                                    <div className="button-wrap ">
                                                      {/* <label class ="new-button" for="upload"> Upload CV</label> */}

                                                      <input type="file"
                                                        id="avatar" name="avatar" onChange={(e) => { fileUpload(e, index) }} ></input>
                                                    </div>

                                                    {/* {modelErrors[index].gstError && (
                                                                                            <p className="text-danger mt-2">
                                                                                                * {modelErrors[index].gstError}
                                                                                            </p>
                                                                                        )} */}
                                                    {modelErrors[index]?.pdfError && (
                                                      <p className="text-danger mt-2">
                                                        * {modelErrors[index]?.pdfError}
                                                      </p>
                                                    )}
                                                  </div>}



                                                  <div className="col-sm-1 md-6">



                                                    {index < c - 1 ?
                                                      <div
                                                        className="text-danger "
                                                        style={{ marginTop: "20" }}
                                                      >
                                                        {/* <a
                                                                            onClick={() =>
                                                                              deleteSingleProduct(
                                                                                singleModel._id
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={deleteIcon}
                                                                              alt=""
                                                                              height="25"
                                                                              color="red"
                                                                            />
                                                                          
                                                                          </a> */}
                                                      </div>
                                                      : (index === c - 1) ? <div className="addBtn adbtnProduct ps-3">
                                                        <Row>
                                                          {/* <Col md={6}>
                                                                                       <div
                                                                          className="text-danger mt-2"
                                                                          style={{ marginTop: "30" }}
                                                                        >
                                                                         <a
                                                                            onClick={() =>
                                                                              deleteSingleProduct(
                                                                                singleModel._id
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={deleteIcon}
                                                                              alt=""
                                                                              height="25"
                                                                              color="red"
                                                                            />
                                                                          
                                                                          </a>
                                                                        </div>
                                                                                            </Col> */}
                                                          <Col md={6}>     <button
                                                            type="button"
                                                            class="btnCommon me-3"
                                                            onClick={() => { addforms() }}
                                                          >
                                                            <span>
                                                              <img
                                                                src={plus}
                                                                alt=""
                                                                height="12"
                                                              />
                                                            </span>
                                                          </button></Col>
                                                        </Row>
                                                      </div> : <div className="addBtn adbtnProduct ps-3"> <button
                                                        type="button"
                                                        class="btnCommon me-3"
                                                        onClick={() => { removeform(index) }}
                                                      >
                                                        <span>
                                                          -
                                                        </span>
                                                      </button></div>}




                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {/* {temperoryModel?.length > 0 ? (
                                                          <>
                                                            {temperoryModel.map((model, index) => {
                                                              return (
                                                                <div className="varintRow">
                                                                  <div class=" mb-3">
                                                                    <div className="row g-4">
                                                                      <div className="col-sm-2">
                                                                        <Form.Control
                                                                          value={model.Capacity}
                                                                          {...index}
                                                                          name="Capacity"
                                                                          onChange={(e) => {
                                                                            setModels(
                                                                              models.map(
                                                                                (pro, ProIndex) => {
                                                                                  if (ProIndex == index) {
                                                                                    pro.Capacity =
                                                                                      e.target.value;
                                                                                  }
                                                                                  return pro;
                                                                                }
                                                                              )
                                                                            );
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div className="col-sm-2">
                                                                        <Form.Control
                                                                          type="text"
                                                                          placeholder="Model"
                                                                          onChange={(e) => {
                                                                            setModels(
                                                                              models.map(
                                                                                (pro, ProIndex) => {
                                                                                  if (ProIndex == index) {
                                                                                    console.log(
                                                                                      pro,
                                                                                      "ppprrooo"
                                                                                    );
                                                                                    pro.Model =
                                                                                      e.target.value;
                                                                                  }
                                                                                  return pro;
                                                                                }
                                                                              )
                                                                            );
                                                                          }}
                                                                          value={model.Model}
                                                                        />
                                                                      </div>
                                                                      <div className="col-sm-2">
                                                                        <Form.Control
                                                                          value={model.ProductId}
                                                                          name="productId"
                                                                          placeholder="Product ID"
                                                                        />
                                                                      </div>

                                                                      <div className="col-sm-2">
                                                                        <Form.Control
                                                                          value={model.Price}
                                                                          name="Price"
                                                                          placeholder="Price"
                                                                          onChange={(e) => {
                                                                            setModels(
                                                                              models.map(
                                                                                (pro, ProIndex) => {
                                                                                  if (ProIndex == index) {
                                                                                    console.log(
                                                                                      pro,
                                                                                      "ppprrooo"
                                                                                    );
                                                                                    pro.Price =
                                                                                      e.target.value;
                                                                                  }
                                                                                  return pro;
                                                                                }
                                                                              )
                                                                            );
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div className="col-sm-2">
                                                                        <Form.Control
                                                                          placeholder="Status"
                                                                          name="Status"
                                                                          value={model.Status}
                                                                          onChange={(e) => {
                                                                            setModels(
                                                                              models.map(
                                                                                (pro, ProIndex) => {
                                                                                  if (ProIndex == index) {
                                                                                    console.log(
                                                                                      pro,
                                                                                      "ppprrooo"
                                                                                    );
                                                                                    pro.Status =
                                                                                      e.target.value;
                                                                                  }
                                                                                  return pro;
                                                                                }
                                                                              )
                                                                            );
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div className="col-sm-2">
                                                                        <div className="addBtn adbtnProduct pt-1">
                                                                          <button
                                                                            type="button"
                                                                            class="btnCommon btnDark deletBtn"
                                                                            onClick={() =>
                                                                              deleteModel(index)
                                                                            }
                                                                          >
                                                                            <span>-</span>
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              );
                                                            })}
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )} */}
                                    {/* <div className="varintRow">
                                                          <div class=" mb-3">
                                                            <div className="row g-3">
                                                              <div className="col-sm-2">
                                                                <Form.Control
                                                                  type="text"
                                                                  name="Capacity"
                                                                  placeholder="Capacity"
                                                                  onChange={Change}
                                                                  value={modelFormData.Capacity}
                                                                />
                                                                {modelErrors.CapacityError && (
                                                                  <p className="text-danger mt-2">
                                                                    * {modelErrors.CapacityError}
                                                                  </p>
                                                                )}
                                                              </div>
                                                              <div className="col-sm-2">
                                                                <Form.Control
                                                                  type="text"
                                                                  name="Model"
                                                                  placeholder="Model"
                                                                  onChange={Change}
                                                                  value={modelFormData.Model}
                                                                />
                                                                {modelErrors.Model && (
                                                                  <p className="text-danger mt-2">
                                                                    * {modelErrors.Model}
                                                                  </p>
                                                                )}
                                                              </div>
                                                              <div className="col-sm-2">
                                                                <Form.Control
                                                                  name="ProductID"
                                                                  placeholder="Product ID"
                                                                  onChange={Change}
                                                                  value={productIdData}
                                                                />
                                                                {modelErrors.productError && (
                                                                  <p className="text-danger mt-2">
                                                                    * {modelErrors.productError}
                                                                  </p>
                                                                )}
                                                              </div>

                                                              <div className="col-sm-2">
                                                                <Form.Control
                                                                  id="test"
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="Price"
                                                                  placeholder="Price"
                                                                  onChange={Change}
                                                                  value={modelFormData.Price}
                                                                />
                                                                {modelErrors.priceError && (
                                                                  <p className="text-danger mt-2">
                                                                    * {modelErrors.priceError}
                                                                  </p>
                                                                )}
                                                              </div>
                                                              <div className="col-sm-2">
                                                                <select
                                                                  className="nice-select"
                                                                  name="Status"
                                                                  onChange={Change}
                                                                  style={{ width: "100%" }}
                                                                  value={modelFormData.Status}
                                                                >
                                                                  <option>Status</option>

                                                                  <option value="ACTIVE">ACTIVE</option>
                                                                  <option value="INACTIVE">
                                                                    INACTIVE
                                                                  </option>
                                                                </select>
                                                                {modelErrors.statusError && (
                                                                  <p className="text-danger mt-2">
                                                                    * {modelErrors.statusError}
                                                                  </p>
                                                                )}
                                                              </div>
                                                              <div className="col-sm-2">
                                                                <div className="addBtn adbtnProduct ">
                                                                  <button
                                                                    type="button"
                                                                    class="btnCommon me-3"
                                                                    onClick={(e) => onSubmit(e)}
                                                                  >
                                                                    <span>
                                                                      <img
                                                                        src={plus}
                                                                        alt=""
                                                                        height="12"
                                                                      />
                                                                    </span>
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3  mb-4">
                            <div class="col">
                              <button
                                class="btnCommon"
                                type="button"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>


                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default EditProduct;
