import { Link, useNavigate } from "react-router-dom";
import logout from "../../assets/images/logout.png";

import logo from "../../assets/images/logo.png";
import { Button, Modal, Dropdown, Offcanvas, Accordion } from "react-bootstrap";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import $ from "jquery";

function SideBar() {
  const agentId = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const navigate = useNavigate();
 
  useEffect(() => {
    if (!agentId) {
      // navigate("/agent/login");
    }
  }, []);
  const sideBarToggleClose = (event) => {
    $("#root").toggleClass("introMob");
    $("#root").removeClass("intro");
  };
  return (
    <div className="leftsidemenu" onClick={sideBarToggleClose}>
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start" style={{backgroundColor:'#ffffff'}}>
          <Link to="/agent/dashboard" className="d-block auth-logo">
          <img src="/admin/images/logo21.png" alt="logo" style={{marginLeft:'-17px'}}/>
          </Link>
        </div>
        <div className="sideBrMenu">
          <div className="NavMenuRow">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="singleLink">
                  <Link to="/agent/dashboard">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-speedometer2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                        <path
                          fill-rule="evenodd"
                          d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
                        />
                      </svg>
                    </span>
                    Dashboard
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="singleLink">
                <Accordion.Header>
                  <Link to="/agent/customerdetail">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-person-lines-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                      </svg>
                    </span>
                    Create Estimate
                  </Link>
                </Accordion.Header>
                {/* <Accordion.Body>
                  <ul>
                    <li>
                      <Link to="/agent/productlist">Product List</Link>
                    </li>
                    <li>
                      <Link to="/agent/customerdetail">Customer Detail</Link>
                    </li>
                    <li>
                      <Link to="/agent/createestimate">Create Estimate</Link>
                    </li>
              
                  </ul>
                </Accordion.Body> */}
                <Accordion.Item eventKey="7">
                <Accordion.Header className="singleLink">
                  <Link to="/agent/productlist">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-file-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </span>
                   List Estimate
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="singleLink">
                  <Link to="/agent/Quatation">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-file-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </span>
                    Quotation
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="singleLink">
                  <Link to="/agent/purchase-invoice">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-file-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </span>
                    Purchase Order
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header className="singleLink">
                  <Link to="/agent/Gallery">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-file-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </span>
                    Gallery
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header className="singleLink">
                  <Link to="/agent/product-report">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-file-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </span>
                   Report
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="logoutSideBar">
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
              localStorage.removeItem("BnJwtToken");
              navigate("/agent/login");
            }}
          >
            <span>
              <img src={logout} alt="" height="18" />
            </span>
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
}
export default SideBar;
 