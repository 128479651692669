import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Button, Row, Col, Container, Form, label } from "react-bootstrap";
import { Modal, Table } from "react-bootstrap";
import axios from "../../API/axios";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import * as Yup from "yup";
import { useFormik } from "formik";
import $ from "jquery";
import Swal from "sweetalert2";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import downloadIcon from "../../assets/images/icons/downloadIcon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import userImg from "../../assets/images/user1.jpg";
import fileicon from "../../assets/images/icons/file-icon.png";
import pdficon from "../../assets/images/icons/pdficon.png";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function AdminEditAgent() {
  const [modalShow, setModalShow] = useState(false);
  const [modalimg, setModalimg] = useState();
  const [agentDoc, setAgentDoc] = useState("");
  const [agentpan, setAgentpan] = useState("");
  const [companypan, setCompanypan] = useState("")
  const States = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra & Nagar Haveli and Daman & Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const navigate = useNavigate();
  const { state } = useLocation();
  const agent = state.agent;
  console.log(agent, "aa");

  const handlemodalShow = (data) => {
    console.log("modal", data);
    setModalimg(data);
    setModalShow(true);
  };
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const [noOfRows, setNoOfRows] = useState(1);
  const editAgentAction = (agent) => {
    console.log(agent, "agent");
    // navigate("/admin/EditAgent", {
    //   state: { agent },
    // });
  };
  const initialValues = {
    _id: agent._id,
    role: agent.role,
    firstName: agent.firstName,
    phone: agent.phone,
    email: agent.email,
    lastName: agent.lastName,
    Company_Name: agent.Company_Name,
    GST_Number: agent.GST_Number,
    PAN_Company: agent.PAN_Company,
    PAN_Agent: agent.PAN_Agent,
    city: agent.Address.city,
    state: agent.Address.state,
    mainAddressText: agent.Address.mainAddressText,
    pincode: agent.Address.pincode,
    status: agent.status,
    Agreement: agent.DocumentFile,
    PAN_Agent_File: agent.PAN_Agent_File,
    PAN_Company_File: agent.PAN_Company_File,
  };

  const [disabled, setDisabled] = useState(false);
  const numberReg = /^\d+$/;
  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    phone: Yup.string()
      .required("This field is required")
      .matches(numberReg, "Enter Valid Phone Number"),
    email: Yup.string()
      .required("This field is required")
      .email("Please Enter Valid Email"),

    // Company_Name: Yup.string().required("This field is required"),

    // GST_Number: Yup.string()
    //   .required("This field is required"),
    // PAN_Company: Yup.string().required("This field is required"),
    // PAN_Agent: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    state: Yup.string().required("This field is required"),
    mainAddressText: Yup.string().required("This field is required"),
    pincode: Yup.string()
      .required("This field is required")
      .matches(numberReg, "Enter Valid Pin number"),
  });
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: SignUpSchema,
      onSubmit: (values) => {
        console.log(values, "vva");
        signUpHandler(values);
      },
    });

  console.log(errors, "");

  const signUpHandler = async (values) => {
    console.log(agent.DocumentFile, "vv");
    try {

      const body = {
        _id: agent._id,
        role: "agent",
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
        DocumentFile: agentDoc,
        PAN_Agent_File: agentpan,
        PAN_Company_File: companypan,
        password: values.password,
        confirmPassword: values.confirmPassword,
        Company_Name: values.Company_Name,
        GST_Number: values.GST_Number,
        PAN_Company: values.PAN_Company,
        PAN_Agent: values.PAN_Agent,
        Address: {
          city: values.city,
          state: values.state,
          mainAddressText: values.mainAddressText,
          pincode: values.pincode,
        },
        status: values.status,
      };
      
      console.log(agent.DocumentFile, "bb");
      await axios
        .put(`/agent/updateAgentById`, { agent: body })
        .then((res) => {
          console.log(res.data);

          Swal.fire("", `${res.data.message}`, "success");

          navigate("/admin/ApprovedAgent");

          // navigate("/agent/login");
        })
        .catch((error) => {
          console.log(error, "error");
          Swal.fire("", `${error.response.data.message}`, "error");
        });
    } catch (error) {}
  };

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("SamplePDF.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };
  function pdfCheck( link ) {
    console.log(link, "link")
    const isPdf = link?.endsWith('.pdf');
    console.log(isPdf, "/.pdf>BBBBBBBBBBBBBBBBBBBBB")
    return (
      <div>
        {isPdf ? (
          <img
                                        src={
                                          pdficon
                                        }
                                        height="150px"
                                        width="180px"
                                        alt=""
                                        className="rounded"
                                      />
        ) : (
          <img
                                        src={
                                          link?link:fileicon
                                        }
                                        height="150px"
                                        width="180px"
                                        alt=""
                                        className="rounded"
                                      />
        )}
      </div>
    );
  }
  const request = new Request(`URL_TO_API`,
  {
    method: "GET",
    // headers: {
    //   Authorization: `Bearer ${accessToken}`,
    // },
    mode: "cors",
    cache: "default",
  }
);

fetch(request)
  .then((response) => response.blob())
  .then((blob) => {              
    const file = window.URL.createObjectURL(blob);
    const iframe = document.querySelector("iframe");
    if (iframe?.src) iframe.src = file;
  })
  .catch((err) => {
    // process error
    console.log(err);
  });

  const uploadFile = async (file, field) => {
    console.log("file", file);
    let DocUpload = new FormData();
    console.log("file", file);
    DocUpload.append("file", file[0]);
    const res = await axios.post("/upload", DocUpload);
    console.log(res.data.data.url);
   setAgentDoc(res.data.data.url);
    
  };
  const uploadFilepanagent = async (file, field) => {
    console.log("file", file);
    let DocUpload = new FormData();
    console.log("file", file);
    DocUpload.append("file", file[0]);
    const res = await axios.post("/upload", DocUpload);
    console.log(res.data.data.url);
    setAgentpan(res.data.data.url);
    
  };
  const uploadFilepancmpny = async (file, field) => {
    console.log("file", file);
    let DocUpload = new FormData();
    console.log("file", file);
    DocUpload.append("file", file[0]);
    const res = await axios.post("/upload", DocUpload);
    console.log(res.data.data.url);
    setCompanypan(res.data.data.url);
    
  };

  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng mb-4">
                      <h3> Edit Agent</h3>
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row">
                          <div className="col">
                            <div class=" mb-3">
                              <div className="row g-2">
                                <div className="col-lg col-md-6">
                                  <Form.Label>
                                    Agent/Dealer First Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Agent Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="firstName"
                                  />
                                  {errors.firstName && touched.firstName ? (
                                    <p className="text-danger">
                                      {errors.firstName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-lg col-md-6">
                                  <Form.Label>
                                    Agent/Dealer Last Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Agent Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="lastName"
                                  />
                                  {errors.lastName && touched.lastName ? (
                                    <p className="text-danger">
                                      {errors.lastName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-lg col-md-6">
                                  <Form.Label>Email*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="email"
                                  />
                                  {errors.email && touched.email ? (
                                    <p className="text-danger">
                                      {errors.email}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-lg col-md-6">
                                  <Form.Label>Phone</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter phone number"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="phone"
                                  />
                                  {errors.phone && touched.phone ? (
                                    <p className="text-danger">
                                      {errors.phone}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row my-3 g-2">
                                <div className="col-lg col-md-6">
                                  <Form.Label>Company Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter company name"
                                    value={values.Company_Name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="Company_Name"
                                  />
                                </div>
                                <div className="col-lg col-md-6">
                                  <Form.Label>GST No.</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter GST number"
                                    value={values.GST_Number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="GST_Number"
                                  />
                                  {errors.GST_Number && touched.GST_Number ? (
                                    <p className="text-danger">
                                      {errors.GST_Number}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-lg col-md-6">
                                  <Form.Label>PAN of Company</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter PAN of company"
                                    value={values.PAN_Company}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="PAN_Company"
                                  />
                                  {values.PAN_Agent ? (
                                    <></>
                                  ) : (
                                    <>
                                      {errors.PAN_Company &&
                                      touched.PAN_Company ? (
                                        <p className="text-danger">
                                          {errors.PAN_Company}
                                        </p>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                                <div className="col-lg col-md-6">
                                  <Form.Label>PAN of Agent/Dealer</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter PAN of Agent"
                                    value={values.PAN_Agent}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="PAN_Agent"
                                  />
                                  {values.PAN_Company ? (
                                    <></>
                                  ) : (
                                    <>
                                      {errors.PAN_Agent && touched.PAN_Agent ? (
                                        <p className="text-danger">
                                          {errors.PAN_Agent}
                                        </p>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="row g-2">
                                <div className="col-lg col-md-6">
                                  <label for="firstNameFld" class="form-label">
                                    District*
                                  </label>
                                  <input
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="city"
                                    type="text"
                                    class="form-control"
                                    placeholder="District"
                                  />
                                  {errors.city && touched.city ? (
                                    <p className="text-danger">{errors.city}</p>
                                  ) : null}
                                </div>
                                <div className="col-lg col-md-6">
                                  <label for="firstNameFld" class="form-label">
                                    State*
                                  </label>
                                  <select
                                    className="nice-select"
                                    name="state"
                                    onChange={handleChange}
                                    // style={{ width: "350px" }}
                                    value={values.state}
                                  >
                                    <option>Select State</option>
                                    {States.map((state) => {
                                      return <option>{state}</option>;
                                    })}
                                  </select>
                                  {errors.state && touched.state ? (
                                    <p className="text-danger">
                                      {errors.state}
                                    </p>
                                  ) : null}
                                </div>

                                <div className="col-lg col-md-6">
                                  <label for="firstNameFld" class="form-label">
                                    Pincode*
                                  </label>
                                  <input
                                    value={values.pincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="pincode"
                                    type="text"
                                    class="form-control"
                                    placeholder="Pin"
                                  />
                                  {errors.pincode && touched.pincode ? (
                                    <p className="text-danger">
                                      {errors.pincode}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-lg col-md-6">
                                  <label for="firstNameFld" class="form-label">
                                    Agent's Address*
                                  </label>
                                  <input
                                    value={values.mainAddressText}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="mainAddressText"
                                    type="text"
                                    class="form-control"
                                    placeholder="Pin"
                                  />
                                  {errors.mainAddressText &&
                                  touched.mainAddressText ? (
                                    <p className="text-danger">
                                      {errors.mainAddressText}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row my-3 g-2">
                                <div className="col-lg-2 col-md-6">
                                  <label for="firstNameFld" class="form-label">
                                    Status
                                  </label>
                                  <select
                                    className="nice-select"
                                    name="status"
                                    onChange={handleChange}
                                    style={{ width: "200px" }}
                                    value={values.status}
                                  >
                                    <option value="">SELECT</option>
                                    <option value="ACTIVE">ACTIVE</option>
                                    <option value="SUSPENDED">SUSPEND</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row g-3 py-3">
                          <div className="col-md-4">
                            <div className="border bg-light px-3 py-2 text-center  ">
                              <label for="firstNameFld" class="form-label">
                                Agreement
                              </label>
                              <div className="documntData ">
                                <div className="documtnInfo">
                                  <div>
                                    <span></span>
                                    <a>
                                      {pdfCheck(agentDoc ? agentDoc : values.Agreement)}
                                     
                                      {/* <DocViewer
                                        // config={{
                                        //   header: {
                                        //     disableHeader: false,
                                        //     disableFileName: false,
                                        //     retainURLParams: false,
                                        //   },
                                        // }}
                                        documents={docs}
                                      /> */}
                                      {/* <iframe src={
                                          values.Agreement
                                            ? values.Agreement
                                            : fileicon
                                        }  height="150px"
                                        width="180px"
                                        alt=""
                                        className="rounded" /> */}
                                    </a>
                                  </div>
                                </div>
                                
                              </div>
                              <div class="tbl-actn text-center mt-2">
                                <ul>
                                <li class="edit-btn">
                                         <div className="button-wrap">
                                         <input
                                                  type="file"
                                                  id="avatar"
                                                  name="avatar"
                                                  onChange={(e) =>
                                  uploadFile(e.target.files, "document")
                                }
                                                ></input>
                                         </div>
                                        </li>
                                {values?.Agreement?<>
                                <li class="edit-btn">
                                    <div
                                      class=""
                                      onClick={() => {
                                        editAgentAction(agent);
                                      }}
                                    >
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handlemodalShow(values.Agreement);
                                        }}
                                      >
                                        <img
                                          src={viewIcon}
                                          alt=""
                                          height="18"
                                        />
                                        <span>
                                          <img
                                            src={editWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                  </li></>:<></>}
                                  <li class="delete-btn">
                                    <div class="">
                                    {values?.Agreement?<>
                                      <a
                                        href={values.Agreement}
                                        target="_blank"
                                      >
                                        <img
                                          src={downloadIcon}
                                          alt=""
                                          height="18"
                                          // onClick={onButtonClick}
                                        />
                                        <span>
                                          <img
                                            src={deleteWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a></>:
                                      <a
                                        href="javascript:void(0)"
                                      >
                                        <img
                                          src={downloadIcon}
                                          alt=""
                                          height="18"
                                          // onClick={onButtonClick}
                                        />
                                        <span>
                                          <img
                                            src={deleteWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a>
                                      }
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="border bg-light px-3 py-2  text-center  ">
                              <label for="firstNameFld" class="form-label">
                                PAN-Company
                              </label>
                              <div className="documntData ">
                                <div className="documtnInfo">
                                  <div>
                                    <span></span>
                                    <a>
                                    {pdfCheck(companypan ? companypan :  values.PAN_Company_File)}
                                      {/* <img
                                        src={
                                          values.PAN_Company_File
                                            ? values.PAN_Company_File
                                            : fileicon
                                        }
                                        height="150px"
                                        width="180px"
                                        alt=""
                                        className="rounded"
                                      /> */}
                                    </a>
                                  </div>
                                </div>
                               
                              </div>
                              <div class="tbl-actn text-center mt-2">
                                <ul>
                                <li class="edit-btn">
                                         <div className="button-wrap">
                                         <input
                                                  type="file"
                                                  id="avatar"
                                                  name="avatar"
                                                  onChange={(e) =>
                                                    uploadFilepancmpny(e.target.files, "document")
                                }
                                                ></input>
                                         </div>
                                        </li>
                                 {values?.PAN_Company_File ?<><li class="edit-btn">
                                    <div
                                      class=""
                                      onClick={() => {
                                        editAgentAction(agent);
                                      }}
                                    >
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handlemodalShow(
                                            values.PAN_Company_File
                                          );
                                        }}
                                      >
                                        <img
                                          src={viewIcon}
                                          alt=""
                                          height="18"
                                        />
                                        <span>
                                          <img
                                            src={editWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                  </li></>:<></>}
                                  <li class="delete-btn">
                                    <div class="">
                                    {values?.PAN_Company_File ? <>
                                      <a
                                        href={values.PAN_Company_File}
                                        target="_blank"
                                      >
                                        <img
                                          src={downloadIcon}
                                          alt=""
                                          height="18"
                                          // onClick={onButtonClick}
                                        />
                                        <span>
                                          <img
                                            src={deleteWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a></>:
                                      <a
                                        href="javascript:void(0)"
                                        
                                      >
                                        <img
                                          src={downloadIcon}
                                          alt=""
                                          height="18"
                                          // onClick={onButtonClick}
                                        />
                                        <span>
                                          <img
                                            src={deleteWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a>}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="border bg-light px-3 py-2 text-center   ">
                              <label for="firstNameFld" class="form-label">
                                PAN-Agent
                              </label>
                              <div className="documntData ">
                                <div className="documtnInfo">
                                  <div>
                                    <span></span>
                                    <a>
                                    {pdfCheck(agentpan ? agentpan : values.PAN_Agent_File)}
                                      {/* <img
                                        src={
                                          values.PAN_Agent_File
                                            ? values.PAN_Agent_File
                                            : fileicon
                                        }
                                        height="150px"
                                        width="180px"
                                        alt=""
                                        className="rounded"
                                      /> */}
                                    </a>
                                  </div>
                                </div>
                              
                              </div>
                              <div class="tbl-actn text-center mt-2">
                                <ul>
                                <li class="edit-btn">
                                         <div className="button-wrap">
                                         <input
                                                  type="file"
                                                  id="avatar"
                                                  name="avatar"
                                                  onChange={(e) =>
                                                    uploadFilepanagent(e.target.files, "document")
                                }
                                                ></input>
                                         </div>
                                        </li>
                                  {values?.PAN_Agent_File ?<><li class="edit-btn">
                                    <div
                                      class=""
                                      onClick={() => {
                                        editAgentAction(agent);
                                      }}
                                    >
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handlemodalShow(
                                            values.PAN_Agent_File
                                          );
                                        }}
                                      >
                                        <img
                                          src={viewIcon}
                                          alt=""
                                          height="18"
                                        />
                                        <span>
                                          <img
                                            src={editWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                  </li></>:<></>}
                                  <li class="delete-btn">
                                    <div class="">
                                    {values?.PAN_Agent_File ?<>
                                      <a
                                        href={values?.PAN_Agent_File}
                                        target="_blank"
                                      >
                                        <img
                                          src={downloadIcon}
                                          alt=""
                                          height="18"
                                          // onClick={onButtonClick}
                                        />
                                        <span>
                                          <img
                                            src={deleteWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a></>
                                      :
                                      <>
                                      <a
                                        href="javascript:void(0)"
                                        // target="_blank"
                                      >
                                        <img
                                          src={downloadIcon}
                                          alt=""
                                          height="18"
                                          // onClick={onButtonClick}
                                        />
                                        <span>
                                          <img
                                            src={deleteWhite}
                                            alt=""
                                            height="18"
                                          />
                                        </span>
                                      </a></>
                                      }
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <button
                              class="btnCommon  mt-3 "
                              type="button"
                              disabled={disabled}
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                          {/* </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalimg ? modalimg : fileicon}
            alt=""
            height="500"
            style={{ marginLeft: "100" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AdminEditAgent;
