import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import $ from "jquery";
import plus from "../../assets/images/plus.svg";

import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function Quoation() {
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk">
                    <div className="col">
                      <div className="MainHdng">
                        <h4>Quotation</h4>
                      </div>
                    </div>

                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef3} className="wide">
                            <option value="Featured">Sort By</option>
                            <option value="10">Agent Name</option>
                            <option value="25">Company Name</option>
                            <option value="50">Suspend</option>
                            <option value="100">Approve</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table">
                    <Table classname="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </th>
                          <th>Product Name</th>
                          <th>Qty.</th>
                          <th>Unit</th>
                          <th>Rate/unit (Rs.)</th>
                          <th>Amount (RS.)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="whitebgRow">
                          <td>1.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>2.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>3.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>4.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>5.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>6.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>7.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>8.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>9.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                        <tr className="whitebgRow">
                          <td>10.</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <div>OnR-1 Retail packing (raw Onion)</div>
                          </td>
                          <td className="qnty">
                            <Form.Control type="number" placeholder="" />
                          </td>
                          <td className="status">
                            <div className="pdrctCat">4</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon">12500.00</div>
                          </td>
                          <td className="status">
                            <div className="btnCommon btnDark">50000.00</div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="pgntnOuter text-center pt-3">
                    <ul class="pagination">
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">‹</span>
                          <span class="visually-hidden">Previous</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">2</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          3
                        </a>
                      </li>
                      <li class="page-item active">
                        <span class="page-link">
                          4<span class="visually-hidden">(current)</span>
                        </span>
                      </li>

                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">…</span>
                          <span class="visually-hidden">More</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          22
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link"
                          role="button"
                          tabindex="0"
                          href="#"
                        >
                          <span aria-hidden="true">›</span>
                          <span class="visually-hidden">Next</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="addFormStyle text-center mt-3">
                    <button class="btnCommon" type="submit">
                      Submit Quotation
                    </button>
                    <button class="btnCommon btnDark ms-2" type="submit">
                      Convert Quotation to PI
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Quoation;
