import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import AdSidebar from "./Sidebar";
import AdNavBar from "./NavBar";
import $ from "jquery";
import Swal from "sweetalert2";
import Tooltip from "react-simple-tooltip";
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import Pagination from "./pagination";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import axios from "../../API/axios";
import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
import Moment from "react-moment";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function ProductList() {
  const navigate = useNavigate();
  const selectRef1 = useRef();
  // useEffect(() => {
  //   $(selectRef1.current).niceSelect();
  //   $(selectRef1.current).on("change", (e) => {
  //     console.log(e.target.value, "eeeee");
  //  setSort(e.target.value)

  //     // let _formData = formData;
  //     // _formData[e.target.name] = e.target.value;
  //     // setFormData({ ..._formData });
  //     console.log(sort, "fo");
  // });
  // }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(10);
  const [allMachines, setAllMachines] = useState();
  const [pageShow, setPageShow] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(Number);
  const getAllMachines = async () => {
    const result = await axios.get(
      `/product/getAllMachines?page=${currentPage}&limit=10`
    );
    console.log(result.data, "tooooooooooooooooo");
    setTotalCount(result.data.totalCount);
    setAllMachines(result.data.data);
  };
  useEffect(() => {
    getAllMachines();
  }, []);
  useEffect(() => {
    getAllMachines();
  }, [currentPage]);
  const getCropName = (cropId) => {
    if (allCrops.length > 0) {
      console.log(cropId, "cvc");
      console.log(allCrops, "aa");
      const crop = allCrops?.find((element) => {
        return element._id === cropId;
      });
      return crop.crop;
    }
  };

  const [allCrops, setAllCrops] = useState();
  const getAllCrops = async () => {
    const { data } = await axios.get("/product/getAllCrops");
    console.log(data.data, "dd");
    setAllCrops(data.data);
  };
  useEffect(() => {
    getAllCrops();
  }, []);
  console.log(allMachines, "pp");

  const getCategory = (cropId) => {
    console.log(cropId, "cvc");
    console.log(allCrops, "aa");
    const crop = allCrops?.find((element) => {
      return element._id === cropId;
    });
    console.log(crop, "cr");
    return crop.crop;
  };

  const navigateEdit = (machine) => {
    navigate("/admin/editProduct", {
      state: { machine },
    });
  };
  const DeleteMachine = async (id) => {
    console.log(id, "iiiii");
    const result = window.confirm(
      "Do you want to delete every product under this machine"
    );
    console.log(result, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    if (result) {
      const response = await axios.delete(`/product/deleteOneMachine?id=${id}`);
      if (response.data) {
        getAllMachines();
        // alert("Machine is deleted succesfully")
        Swal.fire("Machine is deleted succesfully", "", "success");
      }
    }
  };
  const searchHandler = () => {
    if (!search) {
      getAllMachines();
      return;
    }
    try {
      axios
        .get(
          `/search?search=${search}&collection=Machine&multiFieldSearch=${
            "Machine_name" || "Product_name"
          }&sortVal=1`
        )
        .then((res) => {
          console.log(res.data.result);
          setAllMachines(res.data.result);
          setPageShow(false);
        });
    } catch (error) {
      console.log(error);
      setAllMachines([]);
      setPageShow(true);
    }
  };
  const sortdata = async () => {
    const result = await axios.get(
      `/product/getAllMachinesAlphabet?number=${sort}`
    );
    console.log(result.data, "tooooooooooooooooo");
    setTotalCount(result.data.totalCount);
    setAllMachines(result.data.data);
  };

  useEffect(() => {
    searchHandler();
  }, [search]);
  useEffect(() => {
    sortdata();
  }, [sort]);

  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <AdSidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <AdNavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>Product List</h4>
                      </div>
                    </div>

                    <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-auto">
                      <div className="form-group sortit">
                        <select
                          value={sort}
                          className="wide"
                          onChange={(e) => {
                            setSort(e.target.value);
                          }}
                        >
                          <option value="Featured">Sort By</option>
                          <option value={1}>ASC-DSC</option>
                          <option value={-1}>DSC-ASC</option>
                        </select>
                      </div>
                    </div>
                    {/*<div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef2} className="wide">
                            <option value="10">Active</option>
                            <option value="25">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>*/}
                    <div className="col-md-auto">
                      <div className="addPrdctBtn">
                        <Link to="/admin/addproduct" className="btnCommon">
                          <span className="me-2">
                            <img src={plus} alt="" height="12" />
                          </span>
                          Add Product
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive">
                    <Table classname="table">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Machine ID</th>
                          <th>Machine Name</th>
                          <th>Crop Name</th>
                          <th>Last Updated</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allMachines?.length > 0 ? (
                          <>
                            {allMachines?.map((machine) => {
                              return (
                                <tr className="whitebgRow">
                                  {/* <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                    />
                                  </td> */}
                                  <td>
                                    <div>{machine.Product_name} </div>
                                  </td>
                                  <td
                                  //  style={{
                                  //   maxWidth: "0",
                                  //   overflow: "hidden",
                                  //   textOverflow: "ellipsis",
                                  //   whiteSpace: "nowrap",
                                  // }}
                                  >
                                    <Tooltip
                                      // customCss={`
                                      //        width:80px

                                      //           `}
                                      content={machine.ProductIDs.map(
                                        (item) => {
                                          return (
                                            <>
                                            <div style={{width:"60px"}}>
                                              {item}
                                              {","}
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    >
                                      {/* <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip}
                                    >
                                      {machine.Product_name}
                                    </OverlayTrigger> */}
                                      {machine.ProductIDs[0]}{" "}
                                      {machine.ProductIDs.length > 1 ? (
                                        <>{","+machine.ProductIDs[1]}...</>
                                      ) : (
                                        <></>
                                      )}
                                    </Tooltip>{" "}
                                  </td>
                                  <td>
                                    <div className="pdrctCat">
                                      {machine.Machine_name}
                                    </div>
                                  </td>
                                  <td className="status">
                                    <div className="btnCommon">
                                      {allCrops && getCropName(machine.cropId)}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="prodctTitle">
                                      <Moment format="DD-MMM-YYYY">
                                        {machine.createdAt}
                                      </Moment>
                                      {/* {moment(machine.createdAt).format(
                                        "Do MMM  YYYY"
                                      )} */}
                                    </div>
                                  </td>

                                  <td className="actions">
                                    <div class="tbl-actn">
                                      <ul>
                                        <li class="edit-btn">
                                          <div class="">
                                            <a
                                              onClick={() => {
                                                navigateEdit(machine);
                                              }}
                                            >
                                              <img
                                                src={editIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={editWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                        {/* <li class="delete-btn">
                                          <div class="">
                                            <a
                                              onClick={() => {
                                                DeleteMachine(machine._id);
                                              }}
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={deleteWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li> */}
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {pageShow ? (
                    <div class="pgntnOuter text-center pt-3 ">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={TotalCount}
                        pageSize={limit}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default ProductList;
