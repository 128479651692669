import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Form, label } from "react-bootstrap";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import $, { isEmptyObject } from "jquery";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../API/axios";
import Loader from "../../assets/images/icons/load.gif"
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function Createestimate() {
  const selectRef1 = useRef();

  let initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",

    Company_Name: "",
    GST_Number: "",
    PAN_Company: "",
    PAN_Agent: "",
    city: "",
    state: "",
    mainAddressText: "",
    pincode: "",
  };
  const numberReg = /^\d+$/;
  const CustomerSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    phone: Yup.string()
      .required("This field is required")
      .matches(numberReg, "Enter Valid Phone Number"),
    // email: Yup.string()
    //   .required("This field is required")
    //   .email("Please Enter Valid Email"),
    // password: Yup.string().required("This field is required"),
    // confirmPassword: Yup.string()
    //   .required("This field is required")
    //   .oneOf([Yup.ref("password")], "Password must match"),
    // Company_Name: Yup.string().required("This field is required"),
    // GST_Number: Yup.string()
    //   .required("This field is required")
    //   .matches(numberReg, "Enter Valid GST"),
    // PAN_Company: Yup.string().required("This field is required"),
    // PAN_Agent: Yup.string().required("This field is required"),
    // city: Yup.string().required("This field is required"),
    // state: Yup.string().required("This field is required"),
    mainAddressText: Yup.string().required("This field is required"),
    // pincode: Yup.string()
    //   .required("This field is required")
    // .matches(numberReg, "Enter Valid Pin number"),
  });
  const navigate = useNavigate();
  const [loader, setloader] = useState(false)
  const [customerId, setCustomerId] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);
  const [otpErr, setOtpErr] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [otpPhoneText, setOtpPhoneText] = useState('Generate OTP')
  const agentId = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CustomerSchema,
      onSubmit: (values, kk) => {
        if (otpStatus) {
          createCustomer(values);
        }
      },
    });
  const createCustomer = (values) => {
    // setOtpStatus(false)
    // setOtpVerify(false);
    if (!otpValue) {
      return setOtpErr("Please Enter Your OTP");
    }

    const body = {
      agentId: agentId.id,
      otp: otpValue,
      Customer: {
        role: "agent",
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email?values.email:"n/a",
        Company_Name: values.Company_Name ? values.Company_Name : "n/a",
        GST_Number: values.GST_Number ? values.GST_Number : "0",
        PAN_Company: values.PAN_Company ? values.PAN_Company : "0",
        PAN_Agent: values.PAN_Agent ? values.PAN_Agent : "0",
        Address: {
          city: values.city ? values.city : "city",
          state: values.state ? values.state : "state",
          mainAddressText: values.mainAddressText,
          pincode: values.pincode ? values.pincode : "1",
        },
      },
    };
    axios
      .post(`/customer/addCustomer`, body)
      .then((res) => {
        console.log(res);

        Swal.fire("", `${res.data.message}!`, "success").then(() => {
          console.log("data" ,res?.data?.data );
          navigate("/agent/createestimate", { state: res?.data?.data });
        });

        setCustomerId(res?.data?.data);
        // navigate("/agent/createestimate", { state: res?.data?.data });
      })
      .catch((error) => {
        // setOtpStatus(false);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${error?.response?.data?.message}`,
          });
        }
      });
  };
  const sendOtp = (e) => {
    setDisabled(true);
    handleSubmit(e);
    setOtpStatus(false);

    e.preventDefault();
    if (!isEmptyObject(errors)) {
      setDisabled(false)
      return;
    }
    if (!values.email && !values.phone) {
      setDisabled(false)
      return;
    }
    if (agentId) {
      setloader(true)
      axios
        .get(
          `/customer/otpRecieve?phone=${values.phone}&email=${values.email}&agentid=${agentId.id}`
        )
        .then((res) => {
          setloader(false)
          Swal.fire("", `${res.data.message}!`, "success");
          setOtpStatus(true);
          setTimeout(() => {
            setDisabled(false); // Enable the button after 1 minute
            setOtpPhoneText('Regenerate OTP')
          }, 60000); // 1 minute = 60,000 milliseconds
        })
        .catch((error) => {
          console.log(error);
          setloader(false)
          setDisabled(false)
          if (error?.response?.data?.message) {
            setloader(false)
            Swal.fire({
              icon: "error",
              title: `${error?.response?.data?.error}`,
              text: `${error?.response?.data?.message}`,
            });
          }

          setOtpStatus(false);
        });
    } else {
      alert("please Login");
      navigate("/agent/login");
    }
  };
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const [noOfRows, setNoOfRows] = useState(1);
  useEffect(() => {
    localStorage.setItem("estProducts", JSON.stringify({ products: [] }));
  }, []);
  return (
    <>{loader ?<>
      <div style={{display:"flex",justifyContent:"center",alignItems:"center" , width:"100vw",height:"100vh", backgroundColor:"#fff"}}><img src={Loader} style={{width:"15vw",height:"30vh"}}/></div>
      </> :
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-3">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-md-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng mb-4">
                      <h3>Add Customer</h3>
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row">
                          <div className="col">
                            <div class=" mb-3">
                              <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                  <Form.Label>
                                    First Name <span className="red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="firstName"
                                    type="text"
                                    placeholder="First Name"
                                  />
                                  {errors.firstName && touched.firstName ? (
                                    <p className="text-danger">
                                      {errors.firstName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                  <Form.Label>
                                    Last Name <span className="red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="lastName"
                                    type="text"
                                    placeholder="last Name"
                                  />
                                  {errors.lastName && touched.lastName ? (
                                    <p className="text-danger">
                                      {errors.lastName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                  <Form.Label>Company Name</Form.Label>
                                  <Form.Control
                                    value={values.Company_Name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="Company_Name"
                                    type="text"
                                    placeholder="Company Name"
                                  />
                                  {errors.Company_Name &&
                                  touched.Company_Name ? (
                                    <p className="text-danger">
                                      {errors.Company_Name}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                  <Form.Label>GST No.</Form.Label>
                                  <Form.Control
                                    value={values.GST_Number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="GST_Number"
                                    type="text"
                                    placeholder="GST No."
                                  />
                                  {errors.GST_Number && touched.GST_Number ? (
                                    <p className="text-danger">
                                      {errors.GST_Number}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-md-6 col-xs-12">
                                  <Form.Label>PAN of Company</Form.Label>
                                  <Form.Control
                                    value={values.PAN_Company}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="PAN_Company"
                                    type="text"
                                    placeholder="PAN of Company"
                                  />
                                  {errors.PAN_Company && touched.PAN_Company ? (
                                    <p className="text-danger">
                                      {errors.PAN_Company}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-6 col-xs-12">
                                  <Form.Label>PAN of Customer</Form.Label>
                                  <Form.Control
                                    value={values.PAN_Agent}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="PAN_Agent"
                                    type="text"
                                    placeholder="PAN of Customer"
                                  />
                                  {errors.PAN_Agent && touched.PAN_Agent ? (
                                    <p className="text-danger">
                                      {errors.PAN_Agent}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-md-6 col-xs-12">
                                  <Form.Label>
                                    Contact/Mobile No.
                                    <span className="red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="phone"
                                    type="number"
                                    placeholder="Contact No."
                                  />
                                  {errors.phone && touched.phone ? (
                                    <p className="text-danger">
                                      {errors.phone}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-6 col-xs-12">
                                  <Form.Label>
                                    Email ID
                                    {/* <span className="red">*</span> */}
                                  </Form.Label>
                                  <Form.Control
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="email"
                                    type="text"
                                    placeholder="Email ID"
                                  />
                                  {errors.email && touched.email ? (
                                    <p className="text-danger">
                                      {errors.email}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="col">
                                  <Form.Label>
                                    City <span className="red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="city"
                                    type="text"
                                    placeholder="City"
                                  />
                                  {errors.city && touched.city ? (
                                    <p className="text-danger">{errors.city}</p>
                                  ) : null}
                                </div>
                                <div className="col">
                                  <Form.Label>
                                    State <span className="red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    value={values.state}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="state"
                                    type="text"
                                    placeholder="State"
                                  />
                                  {errors.state && touched.state ? (
                                    <p className="text-danger">
                                      {errors.state}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col">
                                  <Form.Label>
                                    Pin Code <span className="red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    value={values.pincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="pincode"
                                    type="number"
                                    placeholder="Pin Code"
                                  />
                                  {errors.pincode && touched.pincode ? (
                                    <p className="text-danger">
                                      {errors.pincode}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                              <Row className="mt-3">
                                <Col>
                                  <Form.Label>
                                    Address <span className="red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    value={values.mainAddressText}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="mainAddressText"
                                    className="form-control"
                                    type="text"
                                  />
                                  {errors.mainAddressText &&
                                  touched.mainAddressText ? (
                                    <p className="text-danger">
                                      {errors.mainAddressText}
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <div className="col">
                                  <Form.Label>
                                    Generate OTP <span className="red">*</span>
                                  </Form.Label>
                                  <button
                                    class="btn btn-success  mb-2 ms-2"
                                    type="submit"
                                    disabled={disabled}
                                    onClick={sendOtp}
                                  >
                                    {otpPhoneText}
                                  </button>
                                  <Form.Control
                                    onChange={(e) => {
                                      setOtpValue(e.target.value);
                                    }}
                                    value={otpValue}
                                    name="otp"
                                    type="password"
                                    placeholder="Enter Your OTP."
                                  />
                                  {otpErr && (
                                    <p className="text-danger">{otpErr}</p>
                                  )}
                                </div>
                              </Row>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          {otpValue&&otpStatus? (
                            <button
                              class="btnCommon  mt-3 "
                              onClick={(e) => {
                                setOtpErr("");
                                handleSubmit(e);
                                if (!otpValue) {
                                  return setOtpErr("Please Enter Your OTP");
                                }
                              }}
                              type="submit"
                            >
                              Submit
                            </button>
                          ) : (
                            <button
                              class="btnCommon  mt-3  btnDesabled "
                              disabled
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   navigate("/agent/createestimate", {
                              //     state: `63d3c2e4c6cc1629c411744c`,
                              //   });
                              // }}
                              type="submit"
                            >
                              Submit
                            </button>
                          )}

                          {/* <button
                            className={
                              customerId ? "btnCommon" : "btnCommon btnDesabled"
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              if (customerId) {
                                navigate("/agent/createestimate",{state:customerId});
                              }
                            }}
                          >
                            Submit
                          </button> */}
                        </div>
                        {/* <div className="row mt-3">
                          <div class="col">
                            <Form.Label>Select Crop</Form.Label>
                            <select ref={selectRef2} className="wide">
                              <option value="Featured">Select Crop </option>
                              <option value="5">Onion</option>
                              <option value="10">Orange Family</option>
                              <option value="20">Amla</option>
                              <option value="25">Guava</option>
                            </select>
                          </div>
                          <div class="col">
                            <Form.Label>Search Product</Form.Label>
                            <select ref={selectRef3} className="wide">
                              <option value="Featured">List Product </option>
                              <option value="5">Onion</option>
                              <option value="10">Orange Family</option>
                              <option value="20">Amla</option>
                              <option value="25">Guava</option>
                            </select>
                          </div>
                        </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>}
    </>
  );
}
export default Createestimate;