import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Sidebar from "./SideBar";
import NavBar from "./NavBar";
import moment from "moment";
import reload from "../../assets/images/icons/refresh.png"
import axios from "../../API/axios";
import Pagination from "../../container/pagination/pagination";
function Gallery() {
  const [gallery, setGallery] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(10);
  const [limit, setLimit] = useState(12)
  const [search, setSearch] = useState("");
  const getGallery = async (limit = 12) => {
    setLimit(limit)
    try {
      const { data } = await axios.get(
        `/admin/getGallary?page=${currentPage}&limit=${limit}&search=${search}`
      );
      setTotalCount(data?.totalCount)
      setGallery(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getreload = async () => {
    try {
      const { data } = await axios.get(
        `/admin/getGallary?page=${currentPage}&limit=10`
      );
      setTotalCount(data?.totalCount)
      setGallery(data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  const getVideo = (video) => {
    const url = new URL(video);
    const videoId = url.searchParams.get('v');
    console.log(videoId, ">>>>>>>>>>>>>>")
    return videoId
  }

  function getYouTubeVideoId(url) {
    const pattern = /^https?:\/\/(?:www\.)?(?:youtu\.be\/([\w-]+)(?:\?t=\d+)?|youtube\.com\/watch\?v=([\w-]+)(?:&t=\d+(s)?)?)$/;
    const matches = url.match(pattern);
    return matches ? matches[1] || matches[2] : null;
  }

  useEffect(() => {
    getGallery();
  }, [currentPage]);
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-3">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-sm-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3 mb-2">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>Gallery</h4>
                      </div>
                    </div>

                    <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div class="col-md-auto" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <div class="col-md-auto m-1">
                        <div className="addPrdctBtn">
                          <button
                            class="btnCommon m-0"
                            onClick={(e) => {
                              e.preventDefault();
                              getGallery(e);
                            }}
                            type="submit"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      <div class="col-md-auto mt-1">
                        <div className="addPrdctBtn">
                          <button
                            class="btnCommon m-0"


                            onClick={(e) => {
                              getreload()
                            }}
                          >
                            <img src={reload} width={20} style={{ color: "#fff" }} />
                          </button>
                        </div>
                      </div></div>

                  </div>

                  {/* <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                  <h2>Gallery</h2>
                  <div className="col-md-auto" style={{width:"30%"}}>
                  <div  style={{display:"flex",flexDirection:"row", marginLeft:"5px"}}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"  
                        onChange={(e)=>setSearch(e.target.value)}
                      />
                       <div className="addPrdctBtn" style={{marginLeft:"10px"}}>
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            getGallery(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                      </div>
                    
                  </div>
                  </div> */}

                  <div>
                    {gallery?.length > 0 ? (
                      <Row xs={1} md={4} className="g-4">
                        {gallery?.map((item, idx) => (
                          <Col className="col-md-4 col-sm-6">
                            <Card >
                              <Card.Title className="text-center">{item.Title}</Card.Title>
                              <Card.Body>
                                <div >
                                  <iframe src={`https://www.youtube.com/embed/` + getYouTubeVideoId(item.Content)} width={"100%"} />
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>) : <>Data not available</>}
                  </div>
                  {limit < TotalCount &&
                    <button className="btnCommon m-4 rounded-pill" onClick={(e) => {
                      console.log('limit', limit, TotalCount)
                      getGallery(limit + 6)
                    }}>Load More</button>
                  }
                  {/* <div class="pgntnOuter text-center pt-3 ">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={TotalCount}
                      pageSize={limit}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Gallery;
