import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
  Spinner,
} from "react-bootstrap";
import logo from "../../assets/images/logo-sm.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../API/axios";
import jwtDecode from "jwt-decode";
import Loader from "../loader";
function Login() {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    phone: getCookie("BnPhone") ? getCookie("BnPhone") : "",
    password: getCookie("BnPswd") ? getCookie("BnPswd") : "",
  });
  console.log(' getCookie("BnPhone")', getCookie("BnPhone"), initialValues);
  const phoneno = /^\d{10}$/;
  const LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .required("Please enter Phone Number")
      .matches(phoneno, "Please Enter Valid Phone Number"),
    // .min(10, "please Enter Valid Phone Number")
    // .max(12, "please Enter Valid Phone Number"),
    password: Yup.string().required("Please enter Password"),
  });
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values) => {
        loginHandler(values);
      },
    });
  const [loginErr, setLoginErr] = useState("");
  const [hidePassword, setHidePassword] = useState("password");
  const [rmMe, setRmMe] = useState(false);
  const navigate = useNavigate();
  const loginHandler = async (values) => {
    if (rmMe) {
      document.cookie = `BnPhone=${values?.phone}`;
      document.cookie = `BnPswd=${values?.password}`;
    }
    try {
      setLoginErr("");
      setLoading(true);
      const res = await axios.post("/login", values);
      console.log(res);
      console.log(res.data.Authorization);
      const token = res.data.Authorization;
      if (res.data.message == "login success") {
        // const user = jwtDecode(token);

        localStorage.setItem("BnJwtToken", token);
        navigate("/agent/dashboard");
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (error?.response?.data?.message) {
        setLoginErr(error?.response?.data?.message);
      } else {
        alert("check your InterNet");
      }
    } finally {
      setLoading(false);
    }
  };
  const rememberMe = (e) => {
    setRmMe((prev) => !prev);
  };
  const checkRememberMe = () => {
    const phone = getCookie("BnPhone");
    const pswd = getCookie("BnPswd");

    if (phone && pswd && phone != "null" && pswd != "null") {
      setRmMe(true);
    } else {
      setRmMe(false);
    }
  };
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
useEffect(() => {
    const agentId = localStorage.getItem("BnJwtToken")
      ? jwtDecode(localStorage.getItem("BnJwtToken"))
      : "";
    console.log(agentId);
    // if (agentId) {
    //   navigate("/agent/dashboard");
    // }
  }, [localStorage]);
  useEffect(() => {
    checkRememberMe();
  }, []);
  return (
    <section>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/agent/login" className="d-block auth-logo">
                        <h2>
                          <img
                            style={{ mixBlendMode: "multiply" }}
                            src="/admin/images/logo21.png"
                            alt="logo"
                            width={"100%"}
                          />
                        </h2>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back!</h5>
                        <p className="text-muted mt-2">
                          Sign in to continue to Black Nut
                        </p>
                      </div>

                      <Form
                        className="formStyle mt-4 pt-2"
                        onSubmit={handleSubmit}
                      >
                        <div className="mb-4">
                          <label for="firstNameFld" class="form-label">
                            Phone*
                          </label>
                          <input
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="phone"
                            type="text"
                            id="BnPhone"
                            class="form-control"
                            placeholder="Enter Phone Number"
                          />
                          {errors.phone && touched.phone ? (
                            <p style={{ color: "red" }}>{errors.phone}</p>
                          ) : null}
                        </div>
                        <div className="mb-4">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Password*</label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                <Link
                                  to="/agent/forgot-password"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="mb-4">
                            <div
                              className="input-group "
                              id="show_hide_password"
                            >
                              <input
                                name="password"
                                type={hidePassword}
                                className="form-control"
                                required
                                id="BnPswd"
                                placeholder="Enter Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                className="input-group-addon mt-2 "
                                style={{
                                  position: "absolute",
                                  zIndex: "100",
                                  right: "0",
                                  marginRight: "5px",
                                }}
                              >
                                <span></span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className="text-center"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    hidePassword == "password"
                                      ? setHidePassword("text")
                                      : setHidePassword("password");
                                  }}
                                >
                                  <i
                                    // className="fa fa-eye-slash"
                                    className={
                                      hidePassword == "text"
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </div>

                            {errors.password && touched.password ? (
                              <p style={{ color: "red" }}>{errors.password}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                                checked={rmMe}
                                onChange={(e) => {
                                  rememberMe(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          {loginErr && (
                            <p className="text-danger">{loginErr}</p>
                          )}
                        </div>
                        <div className="mb-3">
                          <button
                            disabled={loading}
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? <Loader /> : " Log In"}
                          </button>
                        </div>
                        <div className="mt-5 text-center">
                          <p className="text-muted mb-0">
                            Don't have an account ?
                            <Link
                              to="/agent/register"
                              className="text-primary fw-semibold mx-1"
                            >
                              {" "}
                              Sign Up{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        {" "}
                        {new Date().getFullYear()} © Black Nut
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <div className="col-xxl-9 col-lg-8 col-md-7">
              <div className="auth-bg pt-md-5 p-4 d-flex">
                <div className="bg-overlay bg-primary"></div>

                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-7">
                    <div className="p-0 p-sm-4 px-xl-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}
export default Login;
