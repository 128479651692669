import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
const IdleTimer =()=> {
    const navigate=useNavigate()
    const handleOnIdle = event => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        localStorage.clear()
        navigate('/agent/login')
        // window.location.reload("/agent/login");
      }
      const handleOnActive = event => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
      }
      const handleOnAction = event => {
        // console.log('user did something', event)
      }
      const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 60000 * 1 * 60,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
      })
      return (
        <div>
        </div>
      )
}
export default IdleTimer;