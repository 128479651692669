import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import notification from "../../assets/images/notification.svg";
import UserIcon from "../../assets/images/icons/userprofile-icon.jfif";
import navBarIcon from "../../assets/images/barIcon.svg";
import SetingUser from "../../assets/images/settingIcons/userIcon.svg";
import Edit from "../../assets/images/settingIcons/editIcon.svg";
import ChangePassword from "../../assets/images/settingIcons/changePaswrd.svg";
import SetingLogout from "../../assets/images/settingIcons/logout.svg";
import {
  Button,
  Modal,
  Dropdown,
  DropdownButton,
  Offcanvas,
  Accordion,
} from "react-bootstrap";
import $ from "jquery";
import IdleTimer from "./idle";
import jwtDecode from "jwt-decode";
function NavBar() {
  const agentId = localStorage.getItem("BnJwtToken")
    ? jwtDecode(localStorage.getItem("BnJwtToken"))
    : "";
  const handleClick = (event) => {
    event.currentTarget.classList.toggle("close");
  };
  const showonclick = (event) => {
    $("#root").toggleClass("intro");
    $("#root").removeClass("introMob");
  };
  const [show, setShow] = React.useState();
  const navigate = useNavigate();
  console.log(agentId);
  useEffect(() => {
    if (!agentId) {
      navigate("/agent/login");
    }
  }, [localStorage]);
  return (
    <>
      {localStorage ? <IdleTimer /> : <></>}
      <div className="navbar-custom align-items-center row  d-flex justify-content-between">
        <div className="row d-flex align-items-center">
          <div className="col">
            <div className="navBarClose">
              <a href="javascript:void(0);" onClick={() => showonclick()}>
                <img src={navBarIcon} alt="" height="18" className="" />
              </a>
            </div>
          </div>
          <div className="col-auto">
            <div className="navRightSideBar d-flex align-items-center">
              <div className="notification-list">
                <Dropdown>
                  {/* <Dropdown.Toggle
                    className="notificatnCol"
                    id="dropdown-basic"
                  >
                    <span>
                      <img src={notification} alt="" height="22" />
                    </span>
                    <i className="notificationCryle"></i>
                  </Dropdown.Toggle> */}
                  {/* <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu> */}
                </Dropdown>
              </div>
              <div className="acntUsrBlk">
                <Dropdown>
                  <Dropdown.Toggle
                    className="notificatnCol"
                    id="dropdown-basic"
                  >
                    <div className="userContnt d-flex">
                      <div className="usrImg">
                        <span>
                          <img
                            src={UserIcon}
                            alt=""
                            height="auto"
                            width="33px"
                            className="rounded-circle"
                          />
                        </span>
                      </div>
                      <div>
                        <p>Welcome</p>
                        <h5>{`${agentId?.firstName} ${agentId?.lastName}`}</h5>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/agent/changepassword">
                      <span>
                        <img src={ChangePassword} alt="" height="13" />
                      </span>{" "}
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/agent/Login"
                      onClick={(e) => {
                        e.preventDefault();
                        localStorage.removeItem("BnJwtToken");
                        navigate("/agent/login");
                      }}
                    >
                      <span>
                        <img src={SetingLogout} alt="" height="13" />
                      </span>{" "}
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NavBar;
