import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Container, Form } from "react-bootstrap";
import Swal from "sweetalert2";

import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../API/axios";
import { useState, useEffect } from "react";
import { States } from "../../helper/util";
import Loader from "../loader";
import Step1 from "../RegisterForm/Step1";
import Step2 from "../RegisterForm/Step2";
import Step3 from "../RegisterForm/Step3";
import Step4 from "../RegisterForm/Step4";
import Step5 from "../RegisterForm/Step5";

function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    Company_Name: "",
    GST_Number: "",
    PAN_Company: "",
    PAN_C_File: "",
    PAN_Agent: "",
    PAN_A_File: "",
    city: "",
    state: "",
    mainAddressText: "",
    pincode: "",
  });

  const [agentDoc, setAgentDoc] = useState("");
  const [companyDoc, setCompanyDoc] = useState("");
  const [uploadDoc, setUploadDoc] = useState("");
  const [otpSend, setOtpSend] = useState(false);
  const [pan, setPan] = useState("");
  const [agent, setAgent] = useState("agent");
  const [loader, setloader] = useState(false);
  const [agentPan, setAgentPan] = useState("");
  const [psnfile, setpsnfile] = useState(false);
  const [cmpnypan, setcmpnypan] = useState(false);
  const [uploadErr, setUploadErr] = useState("");
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [doc, setDoc] = useState("");

  const [agentRole, setAgentRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const [hidePassword, setHidePassword] = useState({
    pass: "password",
    Cpass: "password",
  });
  const [url, setUrl] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
  const numberReg = /^\d+$/;
  const alphanum = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;

  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    phone: Yup.string()
      .required("Please enter phone number")
      .matches(numberReg, "Enter valid phone number")
      .min(10, "Enter valid phone number")
      .max(10, "Enter valid phone number"),
    email: Yup.string()
      .required("Please enter email")
      .email("Please enter valid email"),
    password: Yup.string().required("Please enter password"),
    confirmPassword: Yup.string()
      .required("Please enter confirm password")
      .oneOf([Yup.ref("password")], "Confirm password din't matched"),
    // Company_Name: Yup.string().required("This field is required"),
    [agent == "dealer" ? "GST_Number" : ""]: Yup.string()
      .matches(alphanum, "Enter valid GST")
      .max(15, "Enter valid GST")
      .min(15, "Enter valid GST")
      .required("Please enter GST"),
    // GST_Number: Yup.string()
    //   .matches(alphanum, "Enter Valid GST")
    //   .max(15, "Enter Valid GST")
    //   .min(15, "Enter valid GST"),
    [pan || (!agentPan && !pan) || !agentPan || (agentPan && pan)
      ? "PAN_Company"
      : "PAN_Agent"]: Yup.string()
      .required("Please enter company or Agent PAN ")
      .matches(alphanum, "Please enter valid PAN")
      .max(10, "Please enter valid PAN")
      .min(10, "Please enter valid PAN"),
    [agentPan && pan ? "PAN_Agent" : ""]: Yup.string()
      .matches(alphanum, "Please enter valid PAN")
      .max(10, "Please enter valid PAN")
      .min(10, "Please enter valid PAN"),

    // [[cmpnypan,psnfile].some(e=>e===true)
    //   ? "PAN_C_File"
    //   : "PAN_A_File"]: Yup.string(),

    // [agentPan  && ("PAN_A_File" : "")]: Yup.string().required("This field is required"),
    // [pan && ("PAN_C_File" : null)]: Yup.string().required("This field is required"),

    city: Yup.string().required("Please enter city"),
    state: Yup.string().required("Please enter state"),
    mainAddressText: Yup.string().required("Please enter address"),
    pincode: Yup.string()
      .required("Please enter pin code")
      .matches(numberReg, "Enter Valid Pin number"),
  });

  useEffect(() => {
    let a = window.location.href.split("/agent");
    setUrl(a);
  }, []);

  const uploadFile = async (file, field) => {
    let DocUpload = new FormData();
    DocUpload.append("file", file[0]);
    const res = await axios.post("/upload", DocUpload);
    if (field === "agent") setAgentDoc(res.data.data.url);
    if (field === "company") setCompanyDoc(res.data.data.url);
    if (field === "document") setUploadDoc(res.data.data.url);
  };

  const varifyOTP = async (e) => {
    // e.preventDefault();

    setOtpErr("");
    setUploadErr("");
    if (!otp) {
      return setOtpErr("Please enter your OTP");
    }
    if (!uploadDoc) {
      return setDoc("Please choose a file!");
    }
    const body = {
      otp: otp,
      phone: phoneNumber,
      file: uploadDoc,
    };

    try {
      setLoading(true);
      const { data } = await axios.put(`/agent/getAgrFileToVerifyUpdate`, body);
      // alert(data.Message);
      Swal.fire("", `${data.message}`, "success");
      navigate("/agent/login");
    } catch (error) {
      Swal.fire("", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const sendOtpButton = async (val) => {
    setLoading(true);
    try {
      setOtpSend(false);
      const { data } = await axios.get(
        `/agent/getOtpForUpdateDocument?phone=${val}`
      );

      setOtpSend(true);
      console.log('data', data)
 
        Swal.fire("", `${data?.message}`, "success");
    } catch (error) {
      setOtpSend(false);
      Swal.fire("", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const otpFormik = useFormik({
    initialValues: { phone: "" },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required("Please enter phone number")
        .matches(numberReg, "Enter valid phone number")
        .min(10, "Enter valid phone number")
        .max(10, "Enter valid phone number"),
    }),
    onSubmit: (values) => {
      setPhoneNumber(values.phone);
      otpSend ? varifyOTP() : sendOtpButton(values.phone);
    },
  });
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: SignUpSchema,
      onSubmit: (values) => {
        setAgentRole(values.agent);
        setInitialValues((prev) => ({ ...prev, values }));
        signUpHandler(values);
      },
    });

  const signUpHandler = async () => {
    setLoading(true);
    try {
      if (agentDoc !== "" || companyDoc !== "") {
        const body = {
          role: values.agent,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          Company_Name: values.Company_Name,
          PAN_A_File: agentDoc,
          PAN_C_File: companyDoc,
          GST_Number: values.GST_Number,
          PAN_Company: values.PAN_Company,
          PAN_Agent: values.PAN_Agent,
          Address: {
            city: values.city,
            state: values.state.split("-")[1],
            mainAddressText: values.mainAddressText,
            pincode: values.pincode,
            stateCode: values.state.split("-")[0],
          },
        };
        axios
          .post(`/signup/Agent`, body)
          .then((res) => {
            Swal.fire("", `${res.data.message}`, "success");
            navigate("/agent/register?uploadDocument");
          })
          .catch((error) => {
            Swal.fire("", `${error.response.data.message}`, "error");
          });
      } else {
        Swal.fire("", `Please Upload Documents`, "error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const nextStep = (val) => {
    navigate(`/agent/register?signUp&step${val}`);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = (val) => {
    navigate(`/agent/register?signUp&step${val}`);
  };

  const handleDownloadClick = () => {
    // Create an anchor element
    const a = document.createElement("a");
    a.style.display = "none";

    // Set the anchor's href to the PDF URL and specify the filename
    a.href = `https://blcknut.blr1.cdn.digitaloceanspaces.com/agreement/Sales%20Agent%20Agreement.doc.pdf`;
    a.download = "Dealer_or_Sales_Agent_Application_Form.pdf";

    // Trigger a click event on the anchor element to initiate the download
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the anchor element
    document.body.removeChild(a);
  };

  useEffect(() => {
    let a = window.location.href.split("/agent");
    setUrl(a);
  }, []);

  useEffect(() => {
    console.log('location.search.split("&")', location.search.split("&")[1]);
    if (location.search.split("&")[1] !== undefined) {
      navigate("/agent/register?signUp");
    }
  }, []);

  return (
    <div className="auth-page">
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content heightLogin d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/agent/dashboard" className="d-block auth-logo">
                      <img
                        style={{ mixBlendMode: "multiply" }}
                        src="/admin/images/logo21.png"
                        alt="logo"
                        width={"100%"}
                      />
                    </Link>
                  </div>

                  <div className="auth-content my-auto">
                    {location?.search === "" && (
                      <div className="text-center">
                        <h5 className="mb-0">Agent Registration</h5>

                        <div className="d-flex gap-2 flex-column mt-4 align-items-center justify-content-center ">
                          <p>
                            If you have already submitted your details and want
                            to upload a downloaded document for admin approval.
                          </p>
                          <button className="btn btn-primary w-50 waves-effect waves-light">
                            <Link
                              to="/agent/register?uploadDocument"
                              style={{ color: "#fff" }}
                            >
                              Upload document
                            </Link>
                          </button>
                          or
                          <button className="btn btn-light  w-50 waves-effect waves-light">
                            <Link to="/agent/register?signUp">Sign Up</Link>
                          </button>
                        </div>
                      </div>
                    )}

                    <Form
                      className="formStyle mt-4 pt-2"
                      onSubmit={(e) => {
                        e.preventDefault();
                        signUpHandler();
                      }}
                    >
                      {location?.search === "?signUp" && (
                        <Step1
                          agent={agent}
                          setAgent={setAgent}
                          setAgentRole={setAgentRole}
                          values={values}
                          handleBlur={handleBlur}
                          nextStep={nextStep}
                          prevStep={prevStep}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                        />
                      )}

                      {location?.search === "?signUp&step2" && (
                        <Step2
                          setAgent={setAgent}
                          setAgentRole={setAgentRole}
                          values={values}
                          handleBlur={handleBlur}
                          nextStep={nextStep}
                          prevStep={prevStep}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          setPan={setPan}
                          uploadFile={uploadFile}
                          setcmpnypan={setcmpnypan}
                          cmpnypan={cmpnypan}
                          psnfile={psnfile}
                          setAgentPan={setAgentPan}
                          setpsnfile={setpsnfile}
                        />
                      )}
                      {location?.search === "?signUp&step3" && (
                        <Step3
                          setAgent={setAgent}
                          setAgentRole={setAgentRole}
                          values={values}
                          handleBlur={handleBlur}
                          nextStep={nextStep}
                          prevStep={prevStep}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                        />
                      )}
                      {location?.search === "?signUp&step4" && (
                        <Step4
                          setAgent={setAgent}
                          setAgentRole={setAgentRole}
                          values={values}
                          handleBlur={handleBlur}
                          nextStep={nextStep}
                          prevStep={prevStep}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          hidePassword={hidePassword}
                          setHidePassword={setHidePassword}
                        />
                      )}

                      {location?.search === "?signUp&step5" && (
                        <Step5
                          values={values}
                          prevStep={prevStep}
                          loading={loading}
                          handleSubmit={handleSubmit}
                        />
                      )}
                      {location?.search === "?signUp&step5" && (
                        <div className="d-flex justify-content-between align-items-center mt-5 ">
                          <button
                            // disabled={loading || (!emailotpverify && !mobileotpverify)}
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? <Loader /> : "Sign Up"}
                          </button>
                        </div>
                      )}
                    </Form>
                  </div>

                  {location?.search === "?uploadDocument" && (
                    <div className="auth-content my-auto">
                      <div className="text-center mb-5">
                        <button
                          onClick={handleDownloadClick}
                          className="btn btn-light w-100 waves-effect waves-light"
                        >
                          Download Agreement
                        </button>
                      </div>
                      <div className="text-center ">
                        <p className="mb-0">
                          Upload your signed Agreement for Verification
                        </p>
                      </div>
                      <Form className="formStyle mt-4 pt-2">
                        <div className="mb-4">
                          <label for="firstNameFld" class="form-label">
                            Phone*
                          </label>
                          <input
                            value={otpFormik.values.phone}
                            // onChange={(e) => setPhoneNumber(e.target.value)}
                            onChange={otpFormik.handleChange}
                            onBlur={otpFormik.handleBlur}
                            name="phone"
                            type="number"
                            class="form-control"
                            placeholder="Enter Phone"
                          />
                          {otpFormik.errors.phone && otpFormik.touched.phone ? (
                            <p className="text-danger">
                              {otpFormik.errors.phone}
                            </p>
                          ) : null}
                        </div>

                        {otpSend && (
                          <div className="mb-4">
                            <label class="form-label">Enter OTP*</label>
                            <input
                              type={"text"}
                              placeholder="Enter Your OTP"
                              class="form-control"
                              name="otp"
                              onChange={(e) => setOtp(e.target.value)}
                            />
                            {otpErr && <p className="text-danger">{otpErr}</p>}
                          </div>
                        )}

                        {otpSend && (
                          <div className="mb-4">
                            <label class="form-label">
                              Upload Agreement File*
                            </label>
                            <input
                              type={"file"}
                              name="file"
                              onChange={(e) =>
                                uploadFile(e.target.files, "document")
                              }
                            />
                            {doc && <p className="text-danger">{doc}</p>}
                          </div>
                        )}

                        <div className="mb-3">
                          <button
                            disabled={loading}
                            // onClick={otpSend ? varifyOTP : sendOtpButton}
                            onClick={otpFormik.handleSubmit}
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? (
                              <Loader />
                            ) : otpSend ? (
                              "Verify & Upload"
                            ) : (
                              "Send OTP"
                            )}
                          </button>
                        </div>
                      </Form>
                    </div>
                  )}
                  <div className=" text-center">
                    <p className="text-muted mb-0">
                      Already have an account ?
                      <Link
                        to="/agent/login"
                        className="text-primary fw-semibold mx-2"
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      {" "}
                      {new Date().getFullYear()} © Black Nut
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <div className="col-xxl-9 col-lg-8 col-md-7">
            <div className="auth-bg pt-md-5 p-4 d-flex">
              <div className="bg-overlay agentRegister bg-primary"></div>

              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0"></div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
