import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
  Accordion,
} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import upload from "../../assets/images/uploadIcon.svg";
import noimage from "../../assets/images/noImage.jpg";
import plus from "../../assets/images/plus.svg";
import axios from "../../API/axios";
import Swal from "sweetalert2";
window.jQuery = window.$ = $;
require("jquery-nice-select");
const InitialFormData = {
  crop: undefined,
  productName: "",
  machineName: "",
};
const initialModelFormData = {
  Capacity: "",
  Model: "",
  productId: "",
  Price: "",
  Status: "",
};

function AdminAddProduct() {
  const navigate = useNavigate();
  let modddel = [{}];
  const [noOfRows, setNoOfRows] = useState(1);
  const [allCrops, setAllCrops] = useState();
  const [formData, setFormData] = useState(InitialFormData);

  const [models, setModels] = useState([
    {
      Capacity: "",
      Model: "",
      ProductID: "",
      Price: 0,
      Status: "",
      Gst: 0,
      pdfFile: "",
    },
  ]);
  const [modelFormData, setModelFormData] = useState((x) => {
    return { ...initialModelFormData };
  });
  const [productIdData, setProductIdData] = useState();
  const [proId, setProId] = useState();

  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
    $(selectRef1.current).on("change", (e) => {
      console.log(e.target.value, "eeeee");

      // setFormData(_formData => {
      //     return { ..._.cloneDeep(_formData), crop: ($(selectRef1.current)).val() }
      // });
      let _formData = formData;
      _formData[e.target.name] = e.target.value;
      setFormData({ ..._formData });
      console.log(formData, "fo");
    });
  }, [formData, allCrops]);

  $("#test").on("input", function () {
    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "");
    }
  });
  const navigateEdit = (machine) => {
    console.log(machine, "maaaaaaaaaaaacccccccccccccccccggggggggggggggggggg");
    navigate("/admin/editProduct", {
      state: { machine },
    });
  };
  const handleChange = ({ nativeEvent }) => {
    console.log(formData, "fffffffssssss");
    let _formData = formData;
    _formData[nativeEvent.target.name] = nativeEvent.target.value;
    console.log("nativeEvent.target.name", nativeEvent.target.name);
    setFormData({ ..._formData });
    setFormErrors((prev) => ({
      ...prev,
      [`${
        nativeEvent.target.name.charAt(0).toUpperCase() +
        nativeEvent.target.name.slice(1)
      }Error`]: false,
    }));

    // if (_formData.crop != undefined && _formData.productName !== "") {
    //     console.log("innnnnnnnnnnnn");
    //     console.log(models.length, "llllll");
    //     const count = models.length == 0 ? 1 : models.length + 1;
    //     const pro =
    //         _formData.crop.slice(0, 2) + _formData.productName.slice(0, 1) + -count;

    //     setProductIdData(pro);
    //     console.log(pro, "prooooooooooo");
  };
  const [pro, setPro] = useState();

  const checkProductId = async () => {
    try {
      let { crop, productName, machineName } = formData;
      let data = {
        crop: crop,
        productname: productName,
      };
      let currentdata = await axios.post("/product/findmachinecrop", data);
      console.log(currentdata.data.data);

      if (currentdata.data.data.length > 0) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btnCommon ",
            cancelButton: "btnCommon ",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: "Product already exist",
            // text: "You won't be able to revert this!",
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Edit product",
            cancelButtonText: "Continue to add product",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              navigateEdit(currentdata.data.data[0]);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // checkOutHandler();
            }
          });
      } else {
        let proId =
          formData?.crop.slice(0, 2) + formData?.productName.slice(0, 1) + -1;
        let data = {
          productId: proId,
        };
        let productidexist = await axios.post(
          "/product/getProductsproductId",
          data
        );
        console.log(
          productidexist,
          "curreeeennnnrrrnnrnnrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr"
        );
        if (productidexist.data.data.length > 0) {
          let proId1 =
            formData?.crop.slice(0, 2) + formData?.productName.slice(0, 2) + -1;
          let data = {
            productId: proId1,
          };
          let productidexist1 = await axios.post(
            "/product/getProductsproductId",
            data
          );
          if (productidexist1.data.data.length > 0) {
            for (var i = 0; i < models.length; i++) {
              if (formData.crop != undefined && formData.productName !== "") {
                const count = i + 1;
                console.log(count, "cccc");
                let proId2 =
                  formData?.crop.slice(0, 2) +
                  formData?.productName.slice(0, 1) +
                  formData?.machineName.slice(0, 1) +
                  -count;

                let newvalue = [...models];
                newvalue[i].ProductID = proId2;
                setModels(newvalue);
              }
            }
          } else {
            for (var i = 0; i < models.length; i++) {
              if (formData.crop != undefined && formData.productName !== "") {
                const count = i + 1;
                console.log(count, "cccc");
                let proId1 =
                  formData?.crop.slice(0, 2) +
                  formData?.productName.slice(0, 2) +
                  -count;

                let newvalue = [...models];
                newvalue[i].ProductID = proId1;
                setModels(newvalue);
              }
            }
          }
        } else {
          for (var i = 0; i < models.length; i++) {
            if (formData.crop != undefined && formData.productName !== "") {
              const count = i + 1;
              console.log(count, "cccc");
              let pro =
                formData?.crop.slice(0, 2) +
                formData?.productName.slice(0, 1) +
                -count;

              let newvalue = [...models];
              newvalue[i].ProductID = pro;
              setModels(newvalue);
            }
          }
        }
      }
    } catch (err) {
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    checkProductId();
  }, [formData, pro]);

  const getAllCrops = async () => {
    const { data } = await axios.get("/product/getAllCrops");
    console.log(data.data, "dd");
    setAllCrops(data.data);
  };
  useEffect(() => {
    getAllCrops();
  }, []);
  console.log(allCrops, "all");
  console.log(formData, "ff");
  const [formErrors, setFormErrors] = useState({
    cropError: false,
    ProductNameError: false,
    MachineNameError: false,
  });
  const [pdfFile,setPdfFile]=useState(null)
  const [modelErrors, setModelErrors] = useState([
    {
      CapacityError: false,
      Model: false,
      productError: false,
      priceError: false,
      statusError: false,
      gstError: false,
      pdfError: false,
    },
  ]);

  const Change = (e, index) => {
    let _modelFormData = [...models];
    console.log(e.target.name, e.target.value, "vvvvv");
    _modelFormData[index][e.target.name] = e.target.value;
    setModels(_modelFormData);

    console.log(models, "models");
  };
  const fileUpload = async (e, index) => {
    console.log(index, "ethuuuuuuuuuuuuuuuuuu");
    let file = e.target.files[0];
    if (file) {
      setModelErrors([{ ...modelErrors[0], pdfError: false }]);
      setPdfFile(file)
    }
    let data = new FormData();
    data.append("file", file);
    let result = await axios.post("/upload", data);
    if (result) {
      console.log(
        result.data.data.url,
        "resultttttttttttttttttttttttttttttttttttttttttttttttttttttttttt"
      );
      let _modelvalues = [...models];
      _modelvalues[index].pdfFile = result.data.data.url;
      setModels(_modelvalues);
    }
  };
  const addforms = () => {
    setModels([
      ...models,
      {
        Capacity: "",
        Model: "",
        ProductID: "",
        Price: 0,
        Status: "",
        Gst: 0,
        pdfFile: "",
      },
    ]);
    let a = models.length;

    setModelErrors([
      ...modelErrors,
      {
        CapacityError: false,
        Model: false,
        productError: false,
        priceError: false,
        statusError: false,
        gstError: false,
        pdfError: false,
      },
    ]);

    setPro(a);

    //  checkProductId();
  };
  const removeform = (index) => {
    let newvalue = [...models];
    newvalue.splice(index, 1);
    setModels(newvalue);
    // checkProductId();
    let newvalues = [...modelErrors];
    newvalues.splice(index, 1);
    setModelErrors([...newvalues]);
    let a = models.length;
    setPro(a);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("inside");
    let { Capacity, Model, Price, Status } = modelFormData;
    let ProductId = productIdData;
    let isError = false;
    let modelErrors = {
      CapacityError: false,
      Model: false,

      priceError: false,
      statusError: false,
    };
    if (Capacity == "") {
      modelErrors.CapacityError = `Please enter a valid capacity`;
      isError = true;
    }

    if (Price == "") {
      modelErrors.priceError = `Please enter price`;
      isError = true;
    }
    if (Status == "") {
      modelErrors.statusError = `Please select a status`;
      isError = true;
    }
    setModelErrors(modelErrors);
    if (!isError) {
      let data = {
        Price,
        Capacity,
        ProductId,
        Price,
        Status,
        Model,
      };
      console.log(data, "dd");
      setModels([...models, { Capacity, Price, ProductId, Status, Model }]);
      const defaultModelFormData = {
        Capacity: "",
        Model: "",
        productId: "",
        Price: "",
        Status: "",
      };
      setModelFormData({
        Capacity: "",
        Model: "",
        productId: "",
        Price: "",
        Status: "",
      });
    }
  };

  const handleSubmit = async () => {
    let { crop, productName, machineName } = formData;
    let isError = false;
    let formErrors = {
      cropError: false,
      ProductNameError: false,
      MachineNameError: false,
    };
    if (crop === "") {
      formErrors.cropError = `Please select a crop`;
      isError = true;
    }
    if (productName === "") {
      formErrors.ProductNameError = `Please enter  product Name`;
      isError = true;
    }
    if (machineName === "") {
      formErrors.MachineNameError = `Please enter  Machine Name`;
      isError = true;
    }
    setFormErrors(formErrors);
    let modelError = [...modelErrors];
    if (models.length > 0) {
      for (var i = 0; i < models.length; i++) {
        if (models[i].Capacity === "") {
          modelError[i].CapacityError = "please enter valid capacity";
          isError = true;
        } else {
          modelError[i].CapacityError = "";
        }
        if (models[i].Status === "") {
          modelError[i].statusError = "please select status";
          isError = true;
        } else {
          modelError[i].statusError = "";
        }
        if (models[i].ProductID === "") {
          modelError[i].productError = "please select product ";
          isError = true;
        } else {
          modelError[i].productError = "";
        }
        if (!models[i].Price) {
          console.log(models[i].Price, "ppppppppppprrrrrrrrrrrrr");
          modelError[i].priceError = "please enter valid Price";
          isError = true;
        } else {
          modelError[i].priceError = "";
        }
        if (!models[i].Gst) {
          modelError[i].gstError = "please enter Gst";
          isError = true;
        } else {
          modelError[i].gstError = "";
        }
        if (pdfFile == null) {
          modelError[i].pdfError = "please Select file";
          isError = true;
        } else {
          modelError[i].pdfError = "";
        }
      }
      setModelErrors(modelError);
    }

    console.log(
      modelErrors,
      "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
    );

    if (!isError) {
      if (models.length == 0) {
        // alert("please add a model");
        Swal.fire("", `please add a model`, "error");
      }
      let data = {
        crop: crop,
        products: [
          {
            Product_name: productName,
            Machine_name: machineName,
            productDetail: models,
          },
        ],
      };

      console.log(data, "ddda");

      const result = await axios.post("/product/createProduct", data);
      if (result.data) {
        setModels([
          {
            Capacity: "",
            Model: "",
            ProductID: "",
            Price: Number,
            Status: "",
          },
        ]);
        // alert("product created succesfully")
        Swal.fire("", `product created succesfully`, "success");
        navigate("/admin/productlist");
        const updateMachineNamesInMachineList = await axios.get(
          "UCIDB/UpdateInDB"
        );
      } else {
        // alert("Check the product credentials you have entered and try again");
        Swal.fire(
          "",
          `Check the product credentials you have entered and try again`,
          "error"
        );
      }
    } else {
      console.log("error dd");
      console.log(modelErrors, "lll");
    }
  };
  const deleteModel = (index) => {
    console.log(index, "iii");
    console.log(models[index]);
    const editedModel = models.filter((model, key) => key != index);

    setModels([...editedModel]);
  };
  console.log(modelFormData, "modd");
  console.log(models, "mmm");
  console.log(modddel, "mddmdd");
  return (
    <>
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow p-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Add Product</h3>
                    </div>
                    <div class="hrStyle pb-4">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        {!allCrops || !allCrops.length ? (
                          <div class="row  d-flex align-items-center mb-3 ">
                            <div className="d-flex">
                              <p className="mx-auto">
                                (Please Add crops First)
                              </p>
                            </div>
                            <div class="col mt-4">
                              <div className="col"></div>
                              <button
                                class="btnCommon"
                                type="button"
                                onClick={() =>
                                  (window.location.href = "/admin/list-crop")
                                }
                              >
                                Add new crops
                              </button>
                            </div>
                            <div className="col-md-12 py-3">
                              <Form.Label>Add Product Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Product Name"
                                name="productName"
                                onChange={handleChange}
                              />
                              {formErrors.ProductNameError && (
                                <p className="text-danger mt-2">
                                  * {formErrors.ProductNameError}
                                </p>
                              )}
                            </div>
                            <div class="col-md-12">
                              <Form.Label>Machine Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Machine Name"
                                name="machineName"
                                onChange={handleChange}
                              />
                              {formErrors.MachineNameError && (
                                <p className="text-danger mt-2">
                                  * {formErrors.MachineNameError}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div class="row   d-flex align-items-center mb-3 ">
                            <div class="col-md-4 productlist">
                              <Form.Label>Select Crop</Form.Label>
                              <select
                                ref={selectRef1}
                                className="wide"
                                disabled={!allCrops}
                                name="crop"
                              >
                                <option value="">Choose Crop </option>
                                {allCrops &&
                                  (() => {
                                    window.setTimeout(() => {
                                      $(selectRef1.current).niceSelect(
                                        "update"
                                      );
                                    }, 0);
                                    return allCrops.map((crop) => (
                                      <option value={crop.crop}>
                                        {crop.crop}
                                      </option>
                                    ));
                                  })()}
                              </select>
                            </div>
                            <div class="col mt-4">
                              <div className="col"></div>
                              <button
                                class="btnCommon"
                                type="button"
                                onClick={() =>
                                  (window.location.href = "/admin/list-crop")
                                }
                              >
                                Add new crops
                              </button>
                            </div>
                            <div className="col-md-12 py-3">
                              <Form.Label>Add Product Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Product Name"
                                name="productName"
                                onChange={handleChange}
                              />
                              {formErrors.ProductNameError && (
                                <p className="text-danger mt-2">
                                  * {formErrors.ProductNameError}
                                </p>
                              )}
                            </div>
                            <div class="col-md-12">
                              <Form.Label>Machine Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Machine Name"
                                name="machineName"
                                onChange={handleChange}
                              />
                              {formErrors.MachineNameError && (
                                <p className="text-danger mt-2">
                                  * {formErrors.MachineNameError}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        {/* <div className="MainHdng mt-5">
                                                <h3>Add Attributes</h3>
                                            </div> */}
                        <div class="hrStyle pb-4">
                          <hr />
                        </div>
                        <div class="row  d-flex align-items-center ">
                          <div class="col">
                            <div className="varintRow d-none d-lg-block">
                              <div class=" mb-3">
                                <div className="row">
                                  <div className="col-sm-2">
                                    <Form.Label>Capacity</Form.Label>
                                  </div>
                                  <div className="col-sm-1">
                                    <Form.Label>Model</Form.Label>
                                  </div>
                                  <div className="col-sm-1">
                                    <Form.Label className="">
                                      Product ID
                                    </Form.Label>
                                  </div>
                                  <div className="col-sm-2 ml-2 ps-3">
                                    <Form.Label className="ms-2 ps-3">
                                      Price
                                    </Form.Label>
                                  </div>
                                  <div className="col-sm-2">
                                    <Form.Label>Status</Form.Label>
                                  </div>
                                  <div className="col-sm-2">
                                    <Form.Label>GST (%)</Form.Label>
                                  </div>
                                  <div className="col-sm-2">
                                    <Form.Label>PDF File</Form.Label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              {models.length > 0 ? (
                                <>
                                  {models.map((singleModel, index) => {
                                    return (
                                      // <div className="varintRow">
                                      //     <div class=" mb-3">
                                      //         <div className="row">
                                      //             <div className="col-lg-2">
                                      //                 <Form.Control
                                      //                     value={singleModel.Capacity}
                                      //                     {...index}
                                      //                 />
                                      //             </div>
                                      //             <div className="col-lg-2">
                                      //                 <Form.Control
                                      //                     value={singleModel.Model}
                                      //                     name="model"
                                      //                     placeholder="Model"
                                      //                 />
                                      //             </div>
                                      //             <div className="col-lg-2">
                                      //                 <Form.Control
                                      //                     value={singleModel.ProductId}
                                      //                     name="productId"
                                      //                     placeholder="Product ID"
                                      //                 />
                                      //             </div>

                                      //             <div className="col-lg-2">
                                      //                 <Form.Control
                                      //                     value={singleModel.Price}
                                      //                     name="price"
                                      //                     placeholder="Price"
                                      //                 />
                                      //             </div>
                                      //             <div className="col-lg-2">
                                      //                 <Form.Control
                                      //                     placeholder="Price"
                                      //                     value={singleModel.Status}
                                      //                 />
                                      //             </div>
                                      //             <div className="col-lg-2">
                                      //                 <div className="addBtn adbtnProduct pt-1">
                                      //                     <button
                                      //                         type="button"
                                      //                         class="btnCommon btnDark deletBtn"
                                      //                         onClick={() =>
                                      //                             deleteModel(index)
                                      //                         }
                                      //                     >
                                      //                         <span>-</span>
                                      //                     </button>
                                      //                 </div>
                                      //             </div>
                                      //         </div>
                                      //     </div>
                                      // </div>

                                      <div className="varintRow">
                                        <div class=" mb-3">
                                          <div className="row g-3">
                                            <div className="col-lg-2">
                                              <Form.Control
                                                type="text"
                                                name="Capacity"
                                                placeholder="Capacity"
                                                onChange={(e) => {
                                                  Change(e, index);
                                                }}
                                                value={singleModel.Capacity}
                                              />
                                              {modelErrors[index]
                                                .CapacityError && (
                                                <p className="text-danger mt-2">
                                                  *{" "}
                                                  {
                                                    modelErrors[index]
                                                      .CapacityError
                                                  }
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-1">
                                              <Form.Control
                                                type="text"
                                                name="Model"
                                                placeholder="Model"
                                                onChange={(e) => {
                                                  Change(e, index);
                                                }}
                                                value={singleModel.Model}
                                              />
                                              {modelErrors[index].Model && (
                                                <p className="text-danger mt-2">
                                                  * {modelErrors[index].Model}
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-1">
                                              <Form.Control
                                                name="productId"
                                                placeholder="Product ID"
                                                value={singleModel.ProductID}
                                              />
                                              {modelErrors[index]
                                                .productError && (
                                                <p className="text-danger mt-2">
                                                  *{" "}
                                                  {
                                                    modelErrors[index]
                                                      .productError
                                                  }
                                                </p>
                                              )}
                                            </div>

                                            <div className="col-lg-2">
                                              <Form.Control
                                                id="test"
                                                type="number"
                                                pattern="[0-9]*"
                                                name="Price"
                                                placeholder="Price"
                                                onChange={(e) => {
                                                  Change(e, index);
                                                }}
                                                value={singleModel.Price}
                                              />
                                              {modelErrors[index]
                                                .priceError && (
                                                <p className="text-danger mt-2">
                                                  *{" "}
                                                  {
                                                    modelErrors[index]
                                                      .priceError
                                                  }
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-2">
                                              <select
                                                className="nice-select"
                                                name="Status"
                                                onChange={(e) => {
                                                  Change(e, index);
                                                }}
                                                value={singleModel.Status}
                                              >
                                                <option>Status</option>

                                                <option value="ACTIVE">
                                                  ACTIVE
                                                </option>
                                                <option value="INACTIVE">
                                                  INACTIVE
                                                </option>
                                              </select>
                                              {modelErrors[index]
                                                .statusError && (
                                                <p className="text-danger mt-2">
                                                  *{" "}
                                                  {
                                                    modelErrors[index]
                                                      .statusError
                                                  }
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-1 col-md-1">
                                              <Form.Control
                                                id="test"
                                                type="number"
                                                pattern="[0-9]*"
                                                name="Gst"
                                                placeholder="Gst"
                                                onChange={(e) => {
                                                  Change(e, index);
                                                }}
                                                value={singleModel.Gst}
                                              />
                                              {modelErrors[index].gstError && (
                                                <p className="text-danger mt-2">
                                                  *{" "}
                                                  {modelErrors[index].gstError}
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-2 col-md-1">
                                              {/* <Form.Control
                                                                                            id="test"
                                                                                            type="file"
                                                                                             name="file"
                                                                                            placeholder="file"
                                                                                            onChange={(e)=>{Change(e,index)}}
                                                                                            
                                                                                        /> */}
                                              <div className="button-wrap">
                                                {/* <label class ="new-button" for="upload"> Upload CV</label> */}

                                                <input
                                                  type="file"
                                                  id="avatar"
                                                  name="avatar"
                                                  onChange={(e) => {
                                                    fileUpload(e, index);
                                                  }}
                                                ></input>
                                              </div>

                                              {/* {modelErrors[index].gstError && (
                                                                                            <p className="text-danger mt-2">
                                                                                                * {modelErrors[index].gstError}
                                                                                            </p>
                                                                                        )} */}
                                              {modelErrors[index].pdfError && (
                                                <p className="text-danger mt-2">
                                                  *{" "}
                                                  {modelErrors[index].pdfError}
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-1 ps-5">
                                              <div className="addBtn adbtnProduct ">
                                                <button
                                                  type="button"
                                                  class="btnCommon me-3"
                                                  onClick={() => {
                                                    index === 0
                                                      ? addforms()
                                                      : removeform(index);
                                                  }}
                                                >
                                                  <span>
                                                    {index === 0 ? (
                                                      <img
                                                        src={plus}
                                                        alt=""
                                                        height="12"
                                                      />
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div class="col">
                            <button
                              class="btnCommon"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AdminAddProduct;
