import React from "react";

const Step1 = ({
  setAgent,
  handleChange,
  values,
  handleBlur,
  nextStep,
  prevStep,
  touched,
  errors,
  agent,
}) => {
  const handleNext = () => {
    console.log("errors", errors);
    const fieldsToCheck = ["firstName", "lastName", "email", "phone"];

    // Check if all of the specified fields don't exist in the errors object
    if (fieldsToCheck.every((field) => !errors[field])) {
      nextStep(2);
    } else {
      return false;
    }
  };
  console.log("agent", agent);
  return (
    <div>
      <div className="d-flex  mb-3">
        <div
          for="html"
          className="border p-2"
          style={{
            width: "100px",
            display: "flex",
            gap: "8px",
            color: agent === "agent" && "#fff",
            background: agent === "agent" ? " rgb(49 49 49)" : "#FFF",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <input
            defaultChecked
            type="radio"
            required
            id="html"
            name="agent"
            value="agent"
            onChange={(e) => {
              setAgent(e.target.value);
              handleChange(e);
            }}
          />
          <label for="html" style={{ cursor: "pointer" }}>
            Agent
          </label>
        </div>

        <div
          for="html"
          className="border p-2"
          style={{
            width: "100px",
            display: "flex",
            gap: "8px",
            color: agent === "dealer" && "#fff",
            background: agent === "dealer" ? " rgb(49 49 49)" : "#FFF",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <input
            required
            type="radio"
            id="css"
            name="agent"
            value="dealer"
            onChange={(e) => {
              setAgent(e.target.value);
              handleChange(e);
            }}
          />
          <label for="css" style={{ cursor: "pointer" }}>
            Dealer
          </label>
        </div>
      </div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          First Name*
        </label>
        <input
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          name="firstName"
          type="text"
          class="form-control"
          placeholder="First Name"
          required
        />
        {errors.firstName && touched.firstName ? (
          <p className="text-danger">{errors.firstName}</p>
        ) : null}
      </div>
      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Last Name*
        </label>
        <input
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          name="lastName"
          type="text"
          class="form-control"
          placeholder="Last Name"
          required
        />
        {errors.lastName && touched.lastName ? (
          <p className="text-danger">{errors.lastName}</p>
        ) : null}
      </div>

      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Phone*
        </label>
        <input
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          name="phone"
          type="number"
          class="form-control"
          placeholder="Phone"
          required
        />
        {errors.phone && touched.phone ? (
          <p className="text-danger">{errors.phone}</p>
        ) : null}
      </div>

      <div className="mb-4">
        <label for="firstNameFld" class="form-label">
          Email*
        </label>
        <input
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          name="email"
          type="email"
          class="form-control"
          placeholder="Email"
          required
        />
        {errors.email && touched.email ? (
          <p className="text-danger">{errors.email}</p>
        ) : null}
      </div>

      <div>
        <button
          disabled={values?.firstName === ""}
          onClick={handleNext}
          className="btn btn-primary w-100 waves-effect waves-light"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step1;
