import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
  Spinner,
} from "react-bootstrap";
import logo from "../../assets/images/logo-sm.png";
import { useState } from "react";
import axios from "../../API/axios";
import Swal from "sweetalert2";
import Loader from "../loader";
function ForgotPassword() {
  const initialValues = { email: "" };
  // const phoneno = /^\d{10}$/;
  const ForgotSchema = Yup.object().shape({
    // phone: Yup.string()
    //   .required("This field is required")
    //   .matches(phoneno, "Please Enter Valid Phone Number"),
    // .min(10, "please Enter Valid Phone Number")
    // .max(12, "please Enter Valid Phone Number"),
    email: Yup.string()
      .required("Please enter Email")
      .email("Please Enter Valid Email Id"),
  });
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ForgotSchema,
      onSubmit: (values) => {
        forgotHandler(values);
      },
    });
  const [phoneNumber, setPhoneNumber] = useState();
  const [error, setError] = useState("");
  const [otpSend, setOtpSend] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const forgotHandler = async (values) => {
    try {
      setError("");
      setOtpSend(false);
      setLoading(true);
      const { data } = await axios.post("/login/forgotPasswordAdmin", values);

      setPhoneNumber(data?.data?.phone);

      if (data?.status === 200) {
        Swal.fire(
          "",
          `OTP is sent on your mail, please create new password after verifying OTP.`,
          "success"
        );
      }
      // alert(data?.message);

      // alert(`New Password send to ${values.email}`)
      setOtpSend(true);
      // navigate("/")
    } catch (error) {
      setOtpSend(false);
      if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else {
        Swal.fire("", `check your internet Connection`, "success");
        // alert("check your internet Connection");
      }
    } finally {
      setLoading(false);
    }
  };
  const varifyOTP = async (e) => {
    e.preventDefault();
    setOtpErr("");
    if (!otp) {
      return setOtpErr("Please Enter Your OTP");
    }
    const body = {
      otp: otp,
      phone: phoneNumber,
    };
    try {
      setLoading(true);
      const { data } = await axios.post(
        `/login/verifyForgotPasswordAdmin`,
        body
      );
      console.log(data);
      if (data?.status === 201) {
        Swal.fire("", `New Password Sent on mail Id`, "success");
        navigate("/");
      }
    } catch (error) {
      // alert(error?.response?.data?.message);
      Swal.fire("", `${error?.response?.data?.message}`, "success");
      // navigate("/");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="auth-page">
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        style={{ mixBlendMode: "multiply" }}
                        src="/admin/images/logo21.png"
                        alt="logo"
                        width={"100%"}
                      />
                    </Link>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h5 className="mb-0">Forgot Password</h5>
                      <p className="text-muted mt-2">
                        Forgot Password with Black Nut
                      </p>
                    </div>
                    <Form
                      className="formStyle mt-4 pt-2"
                      onSubmit={otpSend ? varifyOTP : handleSubmit}
                    >
                      <div className="mb-4">
                        <label for="firstNameFld" class="form-label">
                          Email*
                        </label>
                        <input
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          type="email"
                          class="form-control"
                          placeholder="Enter email"
                        />
                        {errors.email && touched.email ? (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        ) : null}
                      </div>

                      {otpSend && (
                        <div className="mb-4">
                          <label class="form-label">Enter OTP*</label>
                          <input
                            type={"text"}
                            placeholder="Enter Your OTP"
                            class="form-control"
                            name="otp"
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          {otpErr && <p className="text-danger">{otpErr}</p>}
                        </div>
                      )}

                      {error && <p className="text-danger">{error}</p>}

                      <div className="mb-3">
                        <button
                          disabled={loading}
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          {loading ? (
                            <Loader/>
                          ) : otpSend ? (
                            "Verify"
                          ) : (
                            "Send OTP"
                          )}
                        </button>
                      </div>
                    </Form>

                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0">
                        Remember It ?{" "}
                        <Link to="/" className="text-primary fw-semibold">
                          {" "}
                          Sign In{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      {" "}
                      {new Date().getFullYear()} © Black Nut
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <div className="col-xxl-9 col-lg-8 col-md-7">
            <div className="auth-bg pt-md-5 p-4 d-flex">
              <div className="bg-overlay bg-primary"></div>

              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0"></div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}
export default ForgotPassword;
