import React, { useEffect, useState } from "react";
import Loader from "../loader";
import Swal from "sweetalert2";
import axios from "../../API/axios";
import { useDebouncedCallback } from "use-debounce";

const Step5 = ({ prevStep, loading, handleSubmit, values }) => {
  const [countdown, setCountdown] = useState(60);
  const [countdownEmail, setCountdownEmail] = useState(60);
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpEmailLoading, setOtpEmailLoading] = useState(false);
  const [otpMobileLoading, setOtpMobileLoading] = useState(false);
  const [emailOtpGenerated, setEmailOtpGenerated] = useState(false);
  const [emailotpverify, setEmailotpverify] = useState(false);
  const [mobileotpverify, setMobileotpverify] = useState(false);

  useEffect(() => {
    let timer = null;
    if (otpGenerated && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000); // Update countdown every second
    } else {
      setOtpGenerated(false);
      setCountdown(60);
    }

    return () => {
      clearInterval(timer);
    };
  }, [countdown, otpGenerated]);

  useEffect(() => {
    let emailTimer = null;

    if (emailOtpGenerated && countdownEmail > 0) {
      emailTimer = setInterval(() => {
        setCountdownEmail((prevCountdown) => prevCountdown - 1);
      }, 1000); // Update countdown every second
    } else {
      setEmailOtpGenerated(false);
      setCountdownEmail(60);
    }

    return () => {
      clearInterval(emailTimer);
    };
  }, [countdownEmail, emailOtpGenerated]);

  const otpEmail = async () => {
    try {
      setOtpEmailLoading(true);
      const { data } = await axios.get(
        `/customer/defaultEmailOtpRecieve?email=${values.email}`
      );

      if (data.status == 200) {
        setEmailOtpGenerated(true);

        Swal.fire("", `${data.message}`, "success");
        // 1 minute = 60,000 milliseconds
      } else {
        Swal.fire("", `${data.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOtpEmailLoading(false);
    }
  };

  const otpPhone = async () => {
    try {
      setOtpMobileLoading(true);
      const { data } = await axios.get(
        `/customer/defaultPhoneOtpRecieve?phone=${values.phone}`
      );

      console.log(data, "ddata>>>>>>>>>>>>");
      if (data.status == 200) {
        Swal.fire("", `${data.message}`, "success");
        setOtpGenerated(true); // OTP has been generated
      } else {
        Swal.fire("", `${data.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOtpMobileLoading(false);
    }
  };

  const otpVerifyMobile = async (e) => {
    try {
      const { data } = await axios.get(
        `/customer/otpVerification?mobile=${values.phone}&otp=${e.target.value}`
      );
      if (data.status == 200) {
        setMobileotpverify(true);
      } else {
        setMobileotpverify(false);
      }
    } catch (error) {}
  };

  const otpVerifyEmail = async (e) => {
    console.log(e, "eeeeeeeeeeeeeeeeeeeeeee");
    try {
      const { data } = await axios.get(
        `/customer/otpVerification?email=${values.email}&otp=${e.target.value}`
      );
      console.log(data, "ddata>>>>>>>>>>>>");
      if (data.status == 200) {
        setEmailotpverify(true);
      } else {
        setEmailotpverify(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      setOtpGenerated(false);
      setEmailOtpGenerated(false);
    };
  }, []);


  const handleEmailOtp = useDebouncedCallback((term) => {
    if (term) {
      otpVerifyEmail(term);
    }
  }, 300);
  const handlePhoneOtp = useDebouncedCallback((term) => {
    if (term) {
      otpVerifyMobile(term);
    }
  }, 300);

  return (
    <div>
      <div className="mb-2">
        <label for="firstNameFld" class="form-label">
          OTP for Phone
        </label>

        <div className="d-flex justify-content-between align-items-center gap-1">
          <input
            // value={otpMobile}
            onChange={(e) => handlePhoneOtp(e)}
            // onBlur={handleBlur}
            name="Otp_phone"
            type="number"
            className="form-control w-60"
            placeholder="Enter OTP"
            disabled={mobileotpverify}
          />
          <button
            style={{ width: "30%" }}
            onClick={otpPhone}
            className="btn btn-success w-20 "
            type="button"
            disabled={otpGenerated || mobileotpverify}
            // disabled={values.phone.length>9 ? false : true }
          >
            {otpMobileLoading ? <Loader /> : otpGenerated ? "RESEND" : "SEND"}
          </button>
        </div>
      </div>

      {otpGenerated && countdown > 0 && !mobileotpverify && (
        <div>
          {values?.phone && (
            <p className="text-sm p-0 m-0">
              OTP sent to {values?.phone} successfully.
            </p>
          )}
          <p className="">Resend OTP in {countdown} seconds</p>
        </div>
      )}
      {mobileotpverify && (
        <p className="" style={{ color: "green" }}>
          Verified
        </p>
      )}
      <div className="mb-2">
        <label for="firstNameFld" class="form-label">
          OTP for Email
        </label>
        <div className="d-flex justify-content-between align-items-center gap-1">
          <input
            onChange={(e) => handleEmailOtp(e)}
            name="Otp_email"
            type="number"
            className="form-control "
            placeholder="Enter OTP"
            disabled={emailotpverify}
          />
          <button
            style={{ width: "30%" }}
            onClick={otpEmail}
            className="btn btn-success w-20"
            type="button"
            disabled={emailOtpGenerated || emailotpverify}
          >
            {otpEmailLoading ? (
              <Loader />
            ) : emailOtpGenerated ? (
              "RESEND"
            ) : (
              "SEND"
            )}
          </button>
        </div>
      </div>

      {emailOtpGenerated && countdownEmail > 0 && !emailotpverify && (
        <div>
          {values?.email && (
            <p className="text-sm p-0 m-0">
              OTP sent to {values?.email} successfully.
            </p>
          )}
          <p>Resend OTP in {countdownEmail} seconds</p>
        </div>
      )}
      {emailotpverify && (
        <p className="" style={{ color: "green" }}>
          Verified
        </p>
      )}
     
    </div>
  );
};

export default Step5;
