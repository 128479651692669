import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import moment from 'moment'
import notification from "../../assets/images/notification.svg";
import UserIcon from "../../assets/images/icons/userprofile-icon.jfif";
import navBarIcon from "../../assets/images/barIcon.svg";
import SetingUser from "../../assets/images/settingIcons/userIcon.svg";
import Edit from "../../assets/images/settingIcons/editIcon.svg";
import ChangePassword from "../../assets/images/settingIcons/changePaswrd.svg";
import SetingLogout from "../../assets/images/settingIcons/logout.svg";
import {
  Button,
  Modal,
  Dropdown,
  DropdownButton,
  Offcanvas,
  Accordion,
} from "react-bootstrap";
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import $ from "jquery";
import jwtDecode from "jwt-decode";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import axios from "../../API/axios";
import Swal from "sweetalert2";
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
function AdNavBar() {
  const [arrnoti, setarrnoti] = useState([])
  const jwt = localStorage.getItem("BnAdJwtToken")
    ? jwtDecode(localStorage.getItem("BnAdJwtToken"))
    : "";
  const handleClick = (event) => {
    event.currentTarget.classList.toggle("close");
  };
  const showonclick = (event) => {
    $("#root").toggleClass("intro");
  };
  const [show, setShow] = React.useState();
  const [allNotification, setAllNotification] = useState([]);
  const navigate = useNavigate();
  console.log(jwt);
  const getNotification = () => {
    axios
      .get(`/notification`)
      .then((res) => {
        console.log(res.data.data);
        setAllNotification(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
    getNotification();
  }, []);
  const getfullNoti=(noti)=>{
   let arr1 = noti.slice(0, 50);
   let arr2 = noti.slice(50);
   console.log(arr1)
   console.log(arr2)
  //  setarrnoti(arr2);
   return<><div>
    {arr1}<br/>
    {arr2}</div>
   </>
  }
  return (
    <div className="navbar-custom align-items-center row  d-flex justify-content-between">
      <div className="row d-flex align-items-center">
        <div className="col">
          <div className="navBarClose" style={{color:"black"}}>
            <a href="javascript:void(0);" onClick={() => showonclick()}>
              <img src={navBarIcon} alt="" height="18" className="" />
            </a>
          </div>
        </div>
        <div className="col-auto">
          <div className="navRightSideBar d-flex align-items-center">
            <div className="notification-list">
              <Dropdown>
                <Dropdown.Toggle className="notificatnCol" id="dropdown-basic">
                  {allNotification.filter((n) => !n.read).length ? (
                    <>
                      <span>
                        <img src={notification} alt="" height="22" />
                      </span>
                      <i className="notificationCryle"></i>
                    </>
                  ) : (
                    <>
                      <img src={notification} alt="" height="22" />
                      <i className="notificationCryle"></i>
                    </>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: "500px",
                    overflow: "scroll",
                  }}
                >
                  {allNotification.map((n) => {
                    return (
                      <>
                        <Dropdown.Item
                          onClick={async (e) => {
                            e.preventDefault();
                            if (!n.read) {
                              try {
                                await axios.patch(
                                  `/notification/readTheNotification?id=${n._id}`
                                );
                                getNotification();
                                // navigate(`${n.navigation}`);
                                // Swal.fire({
                                //   title: ``,
                                //   icon: "",
                                //   html: `${n.notification}`,

                                //   // showCloseButton: true,
                                //   // showCancelButton: true,
                                //   // focusConfirm: false,
                                //   // confirmButtonText:
                                //   //   '<i class="fa fa-thumbs-up"></i> Great!',
                                //   // confirmButtonAriaLabel: 'Thumbs up, great!',
                                //   // cancelButtonText:
                                //   //   '<i class="fa fa-thumbs-down"></i>',
                                //   // cancelButtonAriaLabel: 'Thumbs down'
                                // });
                              } catch (error) {}
                            } else {
                              // navigate(`${n.navigation}`)
                              // Swal.fire({
                              //   title: ``,
                              //   icon: "",
                              //   html: `${n.notification}`,

                              //   // showCloseButton: true,
                              //   // showCancelButton: true,
                              //   // focusConfirm: false,
                              //   // confirmButtonText:
                              //   //   '<i class="fa fa-thumbs-up"></i> Great!',
                              //   // confirmButtonAriaLabel: 'Thumbs up, great!',
                              //   // cancelButtonText:
                              //   //   '<i class="fa fa-thumbs-down"></i>',
                              //   // cancelButtonAriaLabel: 'Thumbs down'
                              // });
                            }
                          }}
                        >
                          <div style={{cursor:"default"}}>
                            {n.read ? (
                              <small
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={n.notification}
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: 400 }}
                              ><Link to={`/${n.navigation}`} >
                                {getfullNoti(n.notification)}</Link>
                              </small>
                            ) 
                            : 
                            (
                              <small
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={n.notification}
                                className="d-inline-block font-weight-bold text-truncate"
                                style={{ maxWidth: 400, fontWeight: "bolder" }}
                              ><Link to={`/${n.navigation}`}>
                                {getfullNoti(n.notification)}</Link>
                              </small>
                            )
                            }

                            <div
                              className="text-right"
                              style={{
                                textAlign: "right",
                              }}
                            >
                             <small>{moment(n.createdAt).fromNow()}</small>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="acntUsrBlk">
              <Dropdown>
                <Dropdown.Toggle className="notificatnCol" id="dropdown-basic">
                  <div className="userContnt d-flex">
                    <div className="usrImg">
                      <span>
                        <img
                          src={UserIcon}
                          alt=""
                          height="auto"
                          width="33px"
                          className="rounded-circle"
                        />
                      </span>
                    </div>
                    <div>
                      <p>Welcome</p>
                      <h5>{jwt?.firstName}</h5>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item href="/admin/myprofile">
                    <span>
                      <img src={SetingUser} alt="" height="13" />
                    </span>{" "}
                    View Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/editprofile">
                    <span>
                      <img src={Edit} alt="" height="13" />
                    </span>{" "}
                    Edit Profile
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    href="/admin/reset-password"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/admin/change-password");
                    }}
                  >
                    <span>
                      <img src={ChangePassword} alt="" height="13" />
                    </span>{" "}
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.removeItem("BnAdJwtToken");
                      navigate("/");
                    }}
                  >
                    <span>
                      <img src={SetingLogout} alt="" height="13" />
                    </span>{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdNavBar;