import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import AdSidebar from "./Sidebar";
import AdNavBar from "./NavBar";
import $ from "jquery";
import reload from "../../assets/images/icons/refresh.png"
import ReactPaginate from "react-paginate";
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import * as moment from "moment";
import axios from "../../API/axios";
import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
import Moment from "react-moment";
import Pagination from "./pagination"
window.jQuery = window.$ = $;
require("jquery-nice-select");
function ListEstimate() {
  const navigate = useNavigate();
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(10); 
  const [allEstimates, setAllEstimates] = useState();
  const [pageShow , setPageShow] = useState(true)
  const [search, setSearch] = useState("")
  const getAllEstimates=async()=>{

        const {data}= await axios.get(`/estimate?page=${currentPage}&limit=10`)
        console.log(data.data,"ess");
        console.log(data.totalCount,"ttttttt")
        setTotalCount(data.totalCount)
        setAllEstimates(data.data)
    }
    useEffect(()=>{ 
        getAllEstimates()
    },[]) 

    useEffect(()=>{ 
        getAllEstimates()
    },[currentPage]) 

  const navigateEdit = (Estimate) => {
    navigate("/admin/estimateDetail", { state: { Estimate } });
  };
  const [allAgents, setAllAgents] = useState();
  const getAllAproved = async () => {
    const { data } = await axios.get("/agent/All");
    console.log(data.data, "dd");
    setAllAgents(data.data);
  };
  useEffect(() => {
    getAllAproved();
  }, []);
  console.log(allAgents, "all");
  const getAgentName = (agentId) => {
    console.log(agentId, "cvc");
    console.log(allAgents, "aa");
    const agent = allAgents?.find((element) => {
      return element._id === agentId;
    });
    console.log(agent, "cr");
    return agent?.fullName;
  };
  const getAgentId = (Esnumber) => {
    const zeroPad = (num, places) => String(num).padStart(places, "0");

    const EstimateNumber = zeroPad(Esnumber, 4); // "05"
    return EstimateNumber;
  };

  const getTotalCost = (id) => {
    console.log(id, "ii");

    const estimate = allEstimates.find((estimate) => estimate._id == id);
    const products = estimate.Products;
    let totalCost = 0;
    const total = products.forEach(
      (product) => (totalCost += (product.ProductEstimatedPrice*product.Gst)/100 + product.ProductEstimatedPrice)
    );
    return totalCost;
  };

  const searchQuotation = async (e) => {
    console.log(e, "zzzzzzzzzzz");
    console.log(e.target.value == "", "vaaallllllll");
   



        try {
            const { data } = await axios.get(
                `/search?search=${search}&collection=Estimate&typeOfEstimate=Estimate&multiFieldSearch=agentName,customerName,customerPhone,EstimateId`
            );


            console.log(data, "DAAAAAAAAAA");
            setAllEstimates(data.result);
            setPageShow(false)
        } catch (error) {
            console.log(error);
            setAllEstimates([]);
            setPageShow(true)
        }
   

};
  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <AdSidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <AdNavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                <div className="row align-items-center justify-content-center pb-20 hdngRowBlk g-3">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>List Estimates</h4>
                      </div>
                    </div>

                    <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"  onChange={(e)=>setSearch(e.target.value)}
                      />
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            searchQuotation(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                         
                          
                          onClick={(e) => {
                            getAllEstimates() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef3} className="wide">
                            <option value="Featured">Sort By</option>
                            <option value="10">ASC-DSC</option>
                            <option value="25">DSC-ASC</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/*<div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef2} className="wide">
                            <option value="Featured">Status</option>
                            <option value="10">Active</option>
                            <option value="25">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive text-center">
                    <Table classname="table">
                      <thead>
                        <tr>
                          {/* <th>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </th> */}
                          <th>Date</th>
                          <th>Estimate No.</th>
                          <th>Agent Name</th>
                          <th>Customer Name</th>
                          <th> Customer Number</th>
                          <th> Total Cost </th>
                          <th align="center" width="100">
                            View
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allEstimates?.length > 0 ? (
                          <>
                            {allEstimates.map((estimate) => {
                              return (
                                <tr className="whitebgRow">
                                  {/* <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                    />
                                  </td> */}
                                  <td>
                                    <div className="pdrctCat">
                                        <Moment format="DD-MMM-YYYY">
                                            {estimate.createdAt}
                                        </Moment>
                                      
                                    </div>
                                  </td>
                                  <td>
                                    <div className="prodctTitle">
                                      {" "}
                                      {estimate?.EstimateId}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="prodctTitle">
                                      <Link to="/">
                                        {allAgents &&
                                          getAgentName(estimate?.agentId)}
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                  <div className="pdrctCat ">
                                      {estimate.customerName}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="SubCat ">
                                    {estimate.customerPhone}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="SubCat">
                                      {" "}
                                      {getTotalCost(estimate?._id)}
                                    </div>
                                  </td>
                                  <td className="actions">
                                    <div class="tbl-actn  text-center">
                                      <ul>
                                        <li class="delete-btn">
                                          <div class="">
                                            <a
                                              onClick={() => {
                                                navigateEdit(estimate);
                                              }}
                                            >
                                              <img
                                                src={viewIcon}
                                                alt=""
                                                height="18"
                                              />
                                              <span>
                                                <img
                                                  src={viewWhite}
                                                  alt=""
                                                  height="18"
                                                />
                                              </span>
                                            </a>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <>Please Create Some Estimate To View</>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {pageShow ?
                  <div class="pgntnOuter text-center pt-3 ">
                     <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={TotalCount}
                                            pageSize={limit}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                  </div>:<></>}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default ListEstimate;
