import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import reload from "../../assets/images/icons/refresh.png"
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import viewIcon from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import userImg from "../../assets/images/user1.jpg";

import product1 from "../../assets/images/productImg1.jpg";
import product2 from "../../assets/images/productImg2.jpg";
import product3 from "../../assets/images/productImg3.jpg";
import product4 from "../../assets/images/productImg4.jpg";
import product5 from "../../assets/images/productImg5.jpg";
import axios from "../../API/axios";
import Pagination from "./pagination"
window.jQuery = window.$ = $;
require("jquery-nice-select");
function Reports() {
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const handleDelete = (index, e) => {
    e.target.parentNode.parentNode.parentNode.deleteRow(index);
  };
    const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState(10); 
  const [data, setData] = useState([]);
  const [startDate, setstartDate] = useState()
  const [endDate, setendDate] = useState()
  const [search, setSearch] = useState("")
  const temp = [
    {
      productId: "OnF-1",
      disc: "Retail Packing (Raw Onion)",
      est: 321,
      quot: 87,
      po: 67,
      amt: 32123.0,
    },
    {
      productId: "BaF-1",
      disc: "BrinjalRetail",
      est: 121,
      quot: 77,
      po: 61,
      amt: 3232123.0,
    },
    {
      productId: "CanF-1",
      disc: "CarrotRetail",
      est: 231,
      quot: 117,
      po: 34,
      amt: 452123.0,
    },
    {
      productId: "OnF-1",
      disc: "Retail Packing (Raw Onion)",
      est: 321,
      quot: 87,
      po: 67,
      amt: 502123.0,
    },
  ];
  const getData = async (e) => {
    if (endDate !== undefined && endDate !== ""){
    const { data } = await axios.get(`/estimate/getProductReport?page=${currentPage}&limit=10&startDate=${startDate}&endDate=${endDate}&search=${search}`)
    setTotalCount(data.totalCount)
    setData(data.data);
    console.log(">>>>>>>>>>>>>>",endDate)
    }else{
      const { data } = await axios.get(`/estimate/getProductReport?page=${currentPage}&limit=10&search=${search}`)
    setTotalCount(data.totalCount)
    setData(data.data);
    console.log(">>>>>>>>>>>>>>",endDate)
    }
  }
  const getrefresh=async()=>{
    const { data } = await axios.get(`/estimate/getProductReport?page=${currentPage}&limit=10`)
    setTotalCount(data.totalCount)
    setData(data.data);
  }
   useEffect(async () => {
    getData();
  }, [currentPage]);
  // useEffect(async () => {
   
  //   const { data } = await axios.get(`/estimate/getProductReport?page=${currentPage}&limit=10`)
  //   setTotalCount(data.totalCount)
  //   setData(data.data);
  //   console.log("lllllllllllllllllllll",endDate)
  // }, [currentPage]);

  return (
    <>
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull bg-white mt-3 ms-md-3 p-4">
                  <div className="row align-items-center justify-content-center g-3 pb-20 hdngRowBlk">
                    <div className="col-md">
                      <div className="MainHdng">
                        <h4>Products Reports</h4>
                      </div>
                    </div>

                    {/* <div className="col-md-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div> */}
                    <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group text-center">Start Date</div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <input className="form-control" type={"date"} onChange={(e)=>setstartDate(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group text-center">End Date</div>
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef1} className="wide">
                            <option value="Featured">Monthly</option>
                            <option value="1">Janaury</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    
                    <div className="col-md-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <input className="form-control" type={"date"} onChange={(e)=>setendDate(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"  onChange={(e)=>setSearch(e.target.value)}
                        />
                      </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            getData(e);
                          }}
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button
                          class="btnCommon m-0"
                         
                         
                          onClick={(e) => {
                            getrefresh() 
                          }}
                        >
                          <img src={reload} width={20} style={{color:"#fff"}}/>
                        </button>
                      </div>
                    </div>
                    {/* <div class="col-md-auto">
                      <div className="addPrdctBtn">
                        <button class="btnCommon m-0" type="submit">
                          Apply
                        </button>
                      </div>
                    </div> */}
                    {/* <div class="col-auto">
                      <div className="addPrdctBtn">
                        <button class="btnCommon m-0" type="submit">
                          PDF
                        </button>
                      </div>
                    </div> */}
                    {/* <div class="col-auto">
                      <div className="addPrdctBtn">
                        <button class="btnCommon m-0 btnDark1" type="submit">
                          <i class="fa fa-file-excel" aria-hidden="true"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table table-responsive">
                  {data && data[0]?.ProductID!== "" ? 
                    <Table classname="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product ID</th>
                          <th>Name of Machine </th>
                          <th>No of Estimates</th>
                          <th>No of Quotations</th>
                          <th>No of PO</th>
                          <th>Amount of Purchase Invoices</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((p, index) => {
                          return (
                            <tr className="whitebgRow">
                              <td>{((currentPage-1)*10)+(index + 1)}.</td>
                              <td>
                                <div className="SubCat">{p.ProductID}</div>
                              </td>
                              <td>
                                <div>{p.MachineName}</div>
                              </td>
                              <td className="status">
                                <div className="pdrctCat btnNew">
                                  {p.Estimate}
                                </div>
                              </td>
                              <td className="status">
                                <div className="pdrctCat btnActive">
                                  {p.Quotation}
                                </div>
                              </td>
                              <td className="status">
                                <div className="pdrctCat">
                                  {p.PurchaseOrder}
                                </div>
                              </td>
                              <td className="status">
                                <div className="btnCommon btnDark">
                                  {p.ProductOrderPrice}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>: <div>Data Not available</div> }
                  </div>
                  <div class="pgntnOuter text-center pt-3 ">
                     <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={TotalCount}
                                            pageSize={limit}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default Reports;
